import { Grid, MenuItem, MenuList } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useEffect, useState } from 'react';
import { /*Game,*/ GameStats } from 'core/types';
import BoyAPI from 'services/boy-api';

import DateRangePicker from 'components/common/DateRangePicker';

//import firestore from 'services/firestore';
import PieChart from 'components/data/PieChart';
import theme from 'core/theme';
import { Bar } from 'react-chartjs-2';
import { Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  '@global': {
    '.MuiTab-root.Mui-selected': {
      backgroundColor: '#3c607b',
    },
  },
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
  item: {
    paddingBottom: 24,
    alignItems: 'stretch',
    display: 'flex',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      paddingRight: 24,
    },
  },
  labbel: {
    color: '#8995B4',
    padding: 0,
    fontSize: '1rem',
    fontFamily: 'Helvetica',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
  },
  text: {
    fontSize: '0.875rem',
    fontFamily: 'Helvetica',
    fontWeight: 400,
    lineHeight: 1.1,
    letterSpacing: '0.01071em',
  },
}));

const getFirstDate = () => {
  var prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 15);
  return prevDate;
};

const TextField = ({ label, data }: { label: string; data?: number }) => {
  const { classes } = useStyles();
  return (
    <div
      style={{
        paddingLeft: '16px',
        paddingBottom: '12px',
      }}>
      <p className={classes.labbel}>{label}</p>
      <p className={classes.text}>{data?.toFixed(2)}</p>
    </div>
  );
};

export const InAppStat = () => {
  const { classes } = useStyles();

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstDate(),
    new Date(),
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inAppGames, _setInAppGame] = useState<{ id: string; name: string }[]>([
    {
      id: 'flappy_octo',
      name: 'Flappy Octo',
    },
    {
      id: 'octo_hoop',
      name: 'Octo Hoop',
    },
    {
      id: 'octo_ninja',
      name: 'Octo Ninja',
    },
    {
      id: 'octo_jump',
      name: 'Octo Jump',
    },
    {
      id: 'octo_soccer',
      name: 'Octo Soccer',
    },
    {
      id: 'ape_academy',
      name: 'Degen Ape Academy',
    },
    {
      id: 'abc_swarmsurival',
      name: 'ABC Abracadabra',
    },
    {
      id: 'claynosaurz',
      name: 'Claynosaurz',
    },
    {
      id: 'hammerTheDog',
      name: 'War of Meme Coins',
    },
    {
      id: 'flappyOvols',
      name: 'Elixir - FlapLab Ovols',
    },
    {
      id: 'monkeyman',
      name: 'Monkey Munch',
    },
    {
      id: 'chimpers',
      name: 'Chimpers - Dojo Master',
    },
    {
      id: 'InfiniteRun',
      name: 'DeRun to y00tsopia',
    },
    {
      id: 'hidenbonk',
      name: 'Bonk',
    },
    {
      id: 'tmusk',
      name: 'Paris Musketeers',
    },
    {
      id : 'samo_drift',
      name : 'Skiddy Samo'
    },
  ]);
  const [selectedGame, setSelectedGame] = useState<string>('flappy_octo');
  const [firstTwoGames, setFirstTwoGames] = useState<boolean>(false);

  const [selectedStatsGame, setSelectedStatsGame] = useState<
    GameStats | undefined
  >();
  const [ready, setReady] = useState<boolean>(false);

  /*useEffect(() => {
    (async () => {
      const result: Game[] = [];
      const resultInAppGames = await firestore()
        .collection('game')
        .where('is_in_app', '==', true)
        .get();

      resultInAppGames.forEach((doc) => {
        result.push(doc.data() as Game);
      });
      setInAppGame(result);
    })();
  }, []);*/

  useEffect(() => {
    setReady(false);
    if (firstTwoGames) {
      BoyAPI.Statistic.getStatsInAppFirstTwoGames(selectedGame).then((data) => {
        setSelectedStatsGame(data);
        setReady(true);
      });
    } else {
      BoyAPI.Statistic.getStatsInApp(selectedGame.toLocaleLowerCase(), dateRange).then((data) => {
        setSelectedStatsGame(data);
        setReady(true);
      });
    }
  }, [selectedGame, dateRange, firstTwoGames]);

  const handleMenuClick = (idGame: string) => (_event) => {
    setSelectedGame(idGame);
  };

  return (
    <>
      <div
        style={{
          margin: '16px 0',
          textAlign: 'left',
          flexDirection: 'row-reverse',
        }}>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            if (!value[0] || !value[1]) {
              return;
            }
            setDateRange([value[0], value[1]]);
          }}
        />
        <FormControlLabel
          control={
            <Checkbox onClick={() => setFirstTwoGames((state) => !state)} />
          }
          style={{ marginLeft: '16px' }}
          label="First two games"
        />
      </div>
      <Paper elevation={3}>
        <Grid
          container
          className={classes.root}
          style={{ backgroundColor: '#091c2b' }}>
          <Grid item xs={12} md={2} style={{ textAlign: 'left' }}>
            <MenuList>
              {inAppGames.map((game) => {
                return (
                  <MenuItem
                    key={game.id}
                    onClick={handleMenuClick(game.id)}
                    style={{
                      background:
                        game.id === selectedGame ? '#3c607b' : undefined,
                    }}>
                    {game.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            style={{
              textAlign: 'left',
              paddingTop: '16px',
              paddingBottom: '16px',
              borderTop: '1px solid #cccccc5c',
              borderBottom: '1px solid #cccccc5c',
              borderLeft: '1px solid #cccccc5c',
            }}>
            <TextField
              label={'all_winrate'}
              data={selectedStatsGame?.all_winrate}
            />
            <TextField
              label={'win_lose_repartition'}
              data={selectedStatsGame?.win_lose_repartition}
            />
            <TextField
              label={'average_game_duration'}
              data={selectedStatsGame?.average_game_duration}
            />
            <TextField
              label={'total_in_game_time'}
              data={selectedStatsGame?.total_in_game_time}
            />
            <TextField
              label={'fastest_game'}
              data={selectedStatsGame?.fastest_game}
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            style={{
              textAlign: 'left',
              paddingTop: '16px',
              paddingBottom: '16px',
              borderTop: '1px solid #cccccc5c',
              borderBottom: '1px solid #cccccc5c',
            }}>
            <TextField
              label={'longest_game'}
              data={selectedStatsGame?.longest_game}
            />
            <TextField
              label={'average_score'}
              data={selectedStatsGame?.average_score}
            />
            <TextField label={'noob'} data={selectedStatsGame?.noob} />
            <TextField label={'pro'} data={selectedStatsGame?.pro} />
            <TextField
              label={'coins_balance'}
              data={selectedStatsGame?.coins_balance}
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            style={{
              textAlign: 'left',
              paddingTop: '16px',
              paddingBottom: '16px',
              borderTop: '1px solid #cccccc5c',
              borderBottom: '1px solid #cccccc5c',
              borderRight: '1px solid #cccccc5c',
            }}>
            <TextField
              label={'average_bet_per_game'}
              data={selectedStatsGame?.average_bet_per_game}
            />
            <TextField
              label={'average_coins_won_per_game'}
              data={selectedStatsGame?.average_coins_won_per_game}
            />
            <TextField
              label={'average_coins_lost_per_game'}
              data={selectedStatsGame?.average_coins_lost_per_game}
            />
            <TextField
              label={'nb_players'}
              data={selectedStatsGame?.nb_players}
            />
            <TextField label={'nb_match'} data={selectedStatsGame?.nb_match} />
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        style={{
          marginTop: 16,
          alignItems: 'center',
        }}>
        <Grid item xs={12} md={6} className={classes.item}>
          <Paper elevation={3} style={{ flex: 1 }}>
            <div
              style={{ flex: 1, backgroundColor: '#091c2b', padding: '16px' }}>
              <Typography
                color="textSecondary"
                style={{ textTransform: 'uppercase' }}>
                Repartition of Win/Losses coins
              </Typography>
              <PieChart
                loading={!ready}
                options={{
                  tooltips: {
                    callbacks: {
                      label(item: any, data: any) {
                        const sum = data.datasets[0].data.reduce(
                          (acc: number, cur: number) => acc + cur,
                          0,
                        );
                        const current = data.datasets[0].data[item.index];
                        return `${
                          data.labels[item.index]
                        }: ${current} (${Math.round((current * 100) / sum)}%)`;
                      },
                    },
                  },
                }}
                data={{
                  labels: ['total_coins_won', 'total_coins_lost'],
                  datasets: [
                    {
                      data: [
                        selectedStatsGame?.total_coins_won,
                        selectedStatsGame?.total_coins_lost,
                      ],
                      backgroundColor: [
                        theme.palette.secondary.main,
                        'rgba(75,192,192,1)',
                        '#FFCE56',
                      ],
                    },
                  ],
                }}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className={classes.item}>
          <Paper elevation={3} style={{ flex: 1 }}>
            <div
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                backgroundColor: '#091c2b',
                padding: '16px',
              }}>
              <Typography
                color="textSecondary"
                style={{ textTransform: 'uppercase' }}>
                Repartition of Win/Losses matchs relative to rate given
              </Typography>
              <Bar
                data={{
                  labels: [
                    'win_near_death_rate',
                    'lose_near_death_rate',
                    'win_greater_by_10_rate',
                    'win_greater_by_50_rate',
                  ],
                  datasets: [
                    {
                      label: 'pourcentage de matchs',
                      data: [
                        selectedStatsGame?.win_near_death_rate,
                        selectedStatsGame?.lose_near_death_rate,
                        selectedStatsGame?.win_greater_by_10_rate,
                        selectedStatsGame?.win_greater_by_50_rate,
                      ],
                      backgroundColor: 'rgba(75,192,192,0.8)',
                    },
                  ],
                }}
                options={{
                  tooltips: {
                    callbacks: {
                      label(item: any, data: any) {
                        const current = data.datasets[0].data[item.index];
                        return `${data.labels[item.index]}: ${Math.round(
                          current,
                        )}%`;
                      },
                    },
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default InAppStat;
