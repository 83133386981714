import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const BoostedMatchsWonByForfeitCard = ({
  matchHistoryList,
  isReady,
}: Props) => {
  const [calculIsReady, setCalulIsReady] = useState(false);
  const [boostedMatchsWonByForfeit, setBoostedMatchsWonByForfeit] = useState<
    string | false
  >(false);
  const [
    boostedMatchsWonByForfeitInRow,
    setBoostedMatchWonByForfeitInRow,
  ] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      let maxInARow = 0;
      let count = 0;
      let max = 0;
      matchHistoryList.forEach((match) => {
        if (
          match.is_boosted === true &&
          (match.result === 'VICTORY_BY_FORFEIT' ||
            match.opponent_result === 'FORFEIT')
        ) {
          count++;
          max++;
        } else {
          max = 0;
        }
        if (max > maxInARow) {
          maxInARow = max;
        }
      });
      setBoostedMatchsWonByForfeit(`${count} total,`);
      setBoostedMatchWonByForfeitInRow(`${maxInARow} in a row`);
      setCalulIsReady(true);
    })();
  }, [matchHistoryList]);
  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">
              Boosted match won by forfeit
            </Typography>
            <p>{boostedMatchsWonByForfeit}</p>
            <p>{boostedMatchsWonByForfeitInRow}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default BoostedMatchsWonByForfeitCard;
