import { Grid, Tabs, Tab } from '@mui/material';

import { useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import CategoryList from './Categories/List';
import ProductList from './Products/List';
import ShopList from './Shop/List';

const useStyles = makeStyles()((theme) => ({
  '@global': {
    '.MuiTab-root.Mui-selected': {
      backgroundColor: '#3c607b',
    },
  },
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
  item: {
    paddingBottom: 24,
    alignItems: 'stretch',
    display: 'flex',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      paddingRight: 24,
    },
  },
  tabbedLayout: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'flex',
    },
  },
  showRoot: {
    width: '100%',
  },
  showMain: {
    height: '100%',
  },
  showCard: {
    width: '100%',
  },
  showNoActions: {
    marginTop: 0,
  },
  showCardContent: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      flexWrap: 'wrap',
      flex: 1,
      height: '400px',
      padding: 0,
      paddingBottom: 16,
    },
    paddingBottom: 0,
  },
  identifierLogs: {
    padding: '0 16px 16px 16px',
  },
  layoutTab: {
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      borderRight: '1px solid #cccccc5c',
    },
  },
  dialogSpacing: {
    margin: '10px',
    minWidth: 300,
  },
}));

const ShopHome = () => {
  const { classes } = useStyles();
  const [openTab, setTab] = useState<number>(0);

  const selectedTab = () => {
    switch (openTab) {
      case 0: // claims
        return <>Claims !</>;
      case 1: // Shop
        return <ShopList />;
      case 2: // Categories
        return <CategoryList />;
      case 3: // Products
        return <ProductList />;
      default:
        return <>Bro</>;
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1} md={2} style={{ textAlign: 'left' }}>
        <Tabs
          value={openTab}
          onChange={(_, value) => setTab(value)}
          orientation="vertical">
          <Tab label="claims" />
          <Tab label="shop" />
          <Tab label="categories" />
          <Tab label="products" />
        </Tabs>
      </Grid>
      <Grid item xs={11} md={10}>
        {selectedTab()}
      </Grid>
    </Grid>
  );
};

export default ShopHome;
