export type CombatMode = 1 | 2;

export enum Permission {
  UnblockUser = 'unblock_user',
  ManageUser = 'manage_players',
  BanUnbanUser = 'ban_unban_user',
  ValidateLots = 'validate_lots',
  BanRefundLots = 'ban_refund_lots',
}

export type Bet = {
  value: number;
  is_free_premium: boolean;
  logos: {
    default: string;
    free_premium?: string;
  };
};

export type Labels = {
  fr: string;
  en: string;
  es: string;
};

export type TokenAvailable = {
  id : string,
  symbol : string,
}

export enum RewardUnit {
  'GOLDS' = 'GOLDS',
  'TOKENS' = 'TOKENS',
}

export type TournamentCancelReason =
  | 'INVALID_CONFIGURATION'
  | 'TOO_MUCH_CHEAT'
  | 'HEAVY_BUG_ON_GAME'
  | 'OTHER';

export const CancelReasonSelect: TournamentCancelReason[] = [
  'INVALID_CONFIGURATION',
  'TOO_MUCH_CHEAT',
  'HEAVY_BUG_ON_GAME',
  'OTHER',
];

export type TournamentSetting = {
  id: string;
  name: string;
  unit: string;
  recurrence: number;
  duration: number;
  reward_pool: number;
  reward_repartition: { [key: string]: string };
  start_time: string;
  start_timezone: string;
  games: string[];
};

export type Game = {
  id: string;
  ref: string;
  name: string;
  flags: string[];
  labels: { [key: string]: Labels };
  image_url: string;
  logo_url: string;
  game_over_icon: string;
  empty_background_img_url: string;
  icons: { [key: string]: string };
  is_in_app: boolean;
  is_disabled: boolean;
};

export type Player = {
  id: string;
  boy_id: string;
  nickname: string;
  nationality: string;
  timezone: string;
  is_admin?: boolean;
  is_moderator?: boolean;
  id_v1?: string;
  stats: {
    [key: string]: {
      [key: string]: number;
    };
  };
  update_date: any;
  permissions?: Permission[];
  wallet_ids: string[];
  wallets: PlayerWallets;
};

export type Paginated = {
  total: number;
  total_page: number;
  page: number;
  nb_items: number;
};

export type Identifiers = {
  [key: string]: {
    checked: boolean;
    value: string;
  };
};

export type Statistic = {
  id: string;
  date: string;
  matches: number;
  searches: number;
  subscriptions: number;
  litigations: number;
  reviews: number;
  bought_gems: number;
  bought_avatars: number;
  bought_premium_bets: number;
  collected_wheels: number;
  current_searches: number;
  current_matches: number;
  current_litigations: number;
  current_reviews: number;
};

export type MatchHistory = {
  id: string;
  start_match_timestamp: any;
  start_match_timestamp_locale: any;
  game_id: string;
  moderator_id: string;
  moderator_boy_id: string;
  amount: number;
  opponent_id: string;
  opponent_boy_id: string;
  result: string;
  date: string;
  wait_duration?: number;
  wait_duration_int?: number;
  is_after_litigation: boolean;
  is_boosted: boolean;
  opponent_result: string;
  potentialCheat: string;
  multiAccountUsed: boolean;
  player_ids_str: string;
  opponent_ids_str: string;
  night_hour: boolean;
  timezone: string;
  pauseDuration: number;
  recurringOpponent: boolean;
  player_ids: {
    [key: string]: {
      checked: boolean;
      value: string;
    };
  };
  opponent_ids: {
    [key: string]: {
      checked: boolean;
      value: string;
    };
  };
  sameIdentifiers: boolean;
  mode?: CombatMode;

  //Only in 2v2 mode :
  opponents?: {
    avatar_id: string;
    nickname: string;
    id: string;
    identifiers: Identifiers;
    language: string;
    nationality: string;
    note: number;
  }[];
  team?: {
    avatar_id: string;
    nickname: string;
    id: string;
    identifiers: Identifiers;
    language: string;
    nationality: string;
    note: number;
  }[];
};

export type Transaction = {
  id: string;
  match_id?: string;
  challenge_id?: string;
  date: any;
  amount: number;
  balance: number;
  reason: string;
  collect_date?: any;
  data?: { [key: string]: any };
};

export type Sale = {
  price: number;
  id: string;
  item_id: string;
  quantity: number;
  gem_value: number;
  date: any;
  type: string;
};

export type WaitingRoom = {
  id: string;
  player_id: string;
  player_boy_id: string;
  search_timestamp: any;
  state: string;
  update_date: any;
};

export type WaitingRoom2v2 = {
  from_invitation?: boolean;
};

export type LogIdentifier = {
  id: string;
  date: any;
  player_id: string;
  player_boy_id: string;
  type: string;
  value: string;
};

export type Identifier = {
  name: string;
  id: string;
};

export type Review = {
  admin_id: string;
  admin_boy_id: string;
  validation_date: any;
  id: string;
};

export type NotificationTask = {
  id: string;
  name: string;
  schedule_time: any;
  create_time: any;
  title: string;
  topic_condition: string;
  message: string;
  url: string;
  pop_in_app: boolean;
};

export type WheelCollected = {
  date: string;
  count: number;
};

type Report = {
  date: any;
  player_id: string;
  result: string;
  team: string;
};

export type TeamPlayer = {
  identifiers: {
    [key: string]: {
      checked: boolean;
      value: string;
    };
  };
  timezone: string;
  player_id: string;
  player_boy_id: string;
  platform_id: string;
  wait_duration: number;
  start_match_timestamp_locale: string;
  leader?: boolean;
  use_free_premium_bets?: boolean;
};

export type MatchProof2v2 =
  | {
      [key: string]: {
        proofs: [string];
        comment: string;
      };
    }
  | undefined;

export type MatchProof =
  | {
      proofs: [string];
      comment: string;
    }
  | undefined;

export type ResultEnum =
  | 'DEFEAT'
  | 'VICTORY'
  | 'DRAW'
  | 'VICTORY_BY_FORFEIT'
  | 'FORFEIT'
  | 'DEFEAT_AFTER_LITIGATION'
  | 'VICTORY_AFTER_LITIGATION'
  | 'LITIGATION'
  | 'DRAW_AFTER_LITIGATION'
  | 'DRAW_WITHOUT_PROOF'
  | 'DRAW_NOT_PLAYED'
  | 'DRAW_CHEATING'
  | 'DEFEAT_NOT_PLAYED'
  | 'DEFEAT_FALSE_ID'
  | 'DEFEAT_BAD_PARAMETERS'
  | 'DEFEAT_BAN';

export type PlayerReport = {
  proof_less?: boolean;
  player_id: string;
  proofs?: string[];
  comment?: string;
  reason?: string;
};
export type TeamReport = {
  date: any;
  result: ResultEnum;
  reports: { [key: string]: PlayerReport };
};

export type MultiplayerMode = 1 | 2;
export type Match = {
  id: string;
  game_id: string;
  bet_amount: string;
  state: string;
  mode: MultiplayerMode;
  start_match_timestamp: any;
  team1: [TeamPlayer];
  team2: [TeamPlayer];
  resolution: {
    resolution_date: any;
    team1_result: string;
    team2_result: string;
    resolver_id: string;
    resolver: string;
  };
  team_reports?: {
    team1?: TeamReport;
    team2?: TeamReport;
  };
  team1_report: string;
  team2_report: string;
  reports: Report[];
  has_proof: boolean;
  proofs_team1: MatchProof;
  proofs_team2: MatchProof;
  proofs_2v2_team1: MatchProof2v2;
  proofs_2v2_team2: MatchProof2v2;
  team1_searchid: string;
  team2_searchid: string;
};

export type Winner = {
  id: string;
  admin_id: string;
  player_id: string;
  group: string;
  categories: [string];
  lot_id: string;
  state: string;
};

export type PlayerState = {
  id: string;
  last_match: string;
  state: string;
  update_date: any;
};

export type PlayerRanking = {
  id: string;
  player_boy_id: string;
  total_matchs: number;
  victories: number;
  defeats: number;
  draws: number;
  victories_percent: number;
  defeats_percent: number;
  draws_percent: number;
};

export type ModoRanking = {
  id: string;
  player_boy_id: string;
  litigations: number;
  reviews: number;
  total: number;
  month: number;
  year: number;
};

export type WaitDuration = {
  id: string;
  game_id: string;
  level: number;
  date: any;
  avg_duration_player_1: number;
  avg_duration_player_2: number;
};

export type MatchDistributionData = {
  date_sid: string;
  match_id: string;
  start_match_timestamp: any;
  date_hour_utc: number;
  date_hour_fr: number;
  bet_amount: number;
  is_boosted: boolean;
  state: string;
  game_id: string;
  server: string;
  leader1_platform_id: string;
  leader2_platform_id: string;
  leader1_boy_id: string;
  leader2_boy_id: string;
  litigation: number;
};

export type NotificationStat = {
  id: string;
  date_scheduled: any;
  complete_message_id: string;
  message: string;
  message_id: string;
  player_boy_id: string;
  player_id: string;
  task_id: string;
  task_name: string;
  title: string;
  topic_condition: string;
  message_accepted: number;
  message_delivered: number;
  missing_registrations: number;
  unauthorized_registration: number;
  message_received_internal_error: number;
  mismatch_sender_id: number;
  quota_exceeded: number;
  invalid_registration: number;
  invalid_package_name: number;
  invalid_apns_credential: number;
  invalid_parameters: number;
  payload_too_large: number;
  authentication_error: number;
  invalid_ttl: number;
};

export type MessageCountByDay = {
  id: string;
  date: any;
  count: number;
};

export type MessageCountByPlayer = {
  id: string;
  boy_id: string;
  date: any;
  count: number;
};

export type SocialLinksCount = {
  id: string;
  date: string;
  state: string;
  count: number;
};

export type PlayersBlockedByDay = {
  id: string;
  count: number;
};

export type ReferralCountByDay = {
  id: string;
  count: number;
};

export type MatchModeCountByDayData = {
  id: string;
  date: any;
  mode: number;
  count: number;
};

export type Search2v2CountByDayData = {
  id: string;
  date: any;
  from_invitation: boolean;
  state: string;
  match_count: number;
  count: number;
};

export type BasicStatIdCount = {
  id: string;
  nickname: string;
  count: number;
};

export type MatchDistributionByGameData = {
  game_id: string;
  game_mode_id: string;
  count: number;
};

export type MatchDistributionByPlatformData = {
  platform_id: string;
  count: number;
};

export type MatchDistributionByGameAndPlatformData = {
  game_id: string;
  game_mode_id: string;
  platform_id: string;
  count: number;
};

export type GameStats = {
  all_winrate: number;
  win_lose_repartition: number;
  average_game_duration: number;
  total_in_game_time: number;
  fastest_game: number;
  longest_game: number;
  average_score: number;
  noob: number;
  pro: number;
  win_near_death_rate: number;
  lose_near_death_rate: number;
  win_greater_by_10_rate: number;
  win_greater_by_50_rate: number;
  total_coins_won: number;
  total_coins_lost: number;
  coins_balance: number;
  average_bet_per_game: number;
  average_coins_won_per_game: number;
  average_coins_lost_per_game: number;
  nb_players: number;
  nb_match: number;
};

export type Device = {
  uuid: string;
  last_used_at: string;
  rooted: string;
};

export type Flag = {
  at: string;
  kind: string;
  name: string;
};

export type SuspiciousBehavior = {
  game_id: string;
  logo_url: string;
  data: { [key: string]: object[] };
};

export type Shop = {
  id: string;
  flag: string;
  name: string;
  labels: { [key: string]: string };
  money_unit: string;
  is_global: boolean;
  is_active: boolean;
  created_at?: Date;
  last_updated_at?: Date;
};

export type ShopCategory = {
  id: string;
  ref: string;
  parent_id?: string;
  labels: { [key: string]: string };
  icons: { [key: string]: string };
  is_active: boolean;
  parents: string[];
};

export type Tournament = {
  id: string;
  game_id: string;
  start_at: Date;
  end_at: Date;
  reward_pool: number;
  reward_repartition: {
    [repartition: string]: string;
  };
  unit: string,
  state: string;
  generated_at: Date;
  generated_from_settings: string;
  last_updated_at: Date;
  last_updated_by: null;
};

export type Web3Transaction = { 
  id : string,
  player_id: string,
  player_nickname: string,
  wallet_public_key: string,
  amount: number,
  note : string,
  unit_symbol : string,
  purchased_at: string,
  encoded_transaction : string,
  kind : string,
  data : {
    lot_id : string,
    is_raffle : boolean,
    refund_txn_sig ?: string,
  },
  refunded : boolean,
  refunded_by: string | null,
  refunded_at: string | null,
  refunded_by_nickname: string | null,
  reclaimed : boolean,
  reclaimed_by: string | null,
  reclaimed_by_nickname: string | null,
  reclaimed_at : string | null,
  edited_by: string | null,
  edited_by_nickname: string | null,
  edited_at : string | null,

}

export type TournamentWinner = {
  rank: number;
  reward: number;
  score: number;
  player_id: string;
  collected: boolean;
  collected_at?: string;
  avg_score: number;
  nickname: string;
  unit_image_url?: string;
  unit_mint_address?: string;
  unit_symbol?: string;
};

export enum PlayerTransactionReason {
  AFFILIATE = 'AFFILIATE',
  BONUS = 'BONUS',
  GAIN = 'GAIN',
  INIT = 'INIT',
  MATCH = 'MATCH',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  PURCHASE = 'PURCHASE',
  PREMIUM_ADVANTAGE = 'PREMIUM_ADVANTAGE',
  REFUND = 'REFUND',
  SPECIAL_ADJUSTMENT = 'SPECIAL_ADJUSTMENT',
}

export type OrderStore = {
  [key: string]: boolean;
};

export type GoldsSums = {
  sum: number;
  gained: number;
  consumed: number;
};

export type UngroupedPlayerTransactionItem = {
  amount: number;
  at: Date;
  reason: PlayerTransactionReason;
  computed_in_stock: boolean;
  used_pub_bonus: boolean;
  game_id: string | null;
  product_id: string | null;
  admin_id: string | null;
  infos: { [key: string]: any };
};

export type UngroupedPlayerTransaction = {
  items: [UngroupedPlayerTransactionItem];
  total: number;
  total_page: number;
  page: number;
  nb_items: number;
  live_stock: number;
  visible_stock: number;
};

type shortTransaction = {
  at: Date;
  reason: PlayerTransactionReason;
  computed_in_stock: boolean;
  used_pub_bonus: boolean;
  infos: { [key: string]: any };
};

export type GroupedPlayerTransactionItem = {
  id: string;
  game_id: string;
  product_id: string | null;
  player_id: string | null;
  transactions: [shortTransaction];
};

export type GroupedPlayerTransaction = {
  items: [GroupedPlayerTransactionItem];
  total: number;
  total_page: number;
  page: number;
  nb_items: number;
  live_stock: number;
  visible_stock: number;
};

export enum PlayerWalletProvider {
  EXTERNAL = 'EXTERNAL',
  OCTO = 'OCTO',
}

export type PlayerWallets = {
  [key: string]: PlayerWalletProvider;
};
