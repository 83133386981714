import NumberCard from 'components/data/NumberCard';
  import { useState, useEffect } from 'react';
  import { MatchHistory } from 'core/types';
  
  type Props = {
    matchHistoryList : MatchHistory[]
  }

  const RecurringOpponentCard = ({matchHistoryList}:Props) => {
    const [recurringOpponent, setRecurringOpponent] = useState<number | false>(false);

    useEffect(() => {
      (async () => {

        let count = matchHistoryList.filter(ma => ma.recurringOpponent ).length

        setRecurringOpponent(count);

      })();
    }, []);
    return <NumberCard title={'Recurring alternating opponent'} number={recurringOpponent} />;
  };

  export default RecurringOpponentCard;
  