import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Transaction } from 'core/types';
import React, { useEffect, useState } from 'react';
import firestore from 'services/firestore';
import dateUtils from 'utils/date';
import Common, { Error, InnerViewProps, Notif } from './common';
import BoyAPI from 'services/boy-api';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
});

const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const columns = [
  { field: 'amount', headerName: 'Amount', width: 230 },
  { field: 'balance', headerName: 'Balance', width: 230 },
  { field: 'date', headerName: 'At', width: 230 },
  { field: 'reason', headerName: 'Reason', width: 230 },
  { field: 'match_id', headerName: 'Match', width: 230 },
  { field: 'admin_id', headerName: 'Admin ID', width: 230 },
  { field: 'data', headerName: 'Infos', width: 230 },
];

function Row({ row }: { row: Transaction }) {
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{row.amount}</TableCell>
        <TableCell component="th" scope="row">
          {row.balance}
        </TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.reason}</TableCell>
        <TableCell>{row.match_id || row.challenge_id}</TableCell>
        <TableCell>{row.data?.admin_id}</TableCell>
        <TableCell>
          {row.data &&
            Object.entries(row.data).map(([key, value]) => {
              if (key === 'admin_id') return '';
              return (
                <>
                  {key}:{' '}
                  {typeof value === 'object'
                    ? JSON.stringify(value)
                    : value.toString()}{' '}
                  <br />
                </>
              );
            })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LIMITE_FETCH = 1000;

const GemsTransactionsHistoryDataGrid = ({
  playerId,
  notify,
  onError,
}: InnerViewProps) => {
  const { classes } = useStyles();
  const [transactionList, setTransactionList] = useState<Transaction[]>([]);

  const [lastDocument, setLastDocument] = useState<any>();
  const [
    newTransactionModalOppenned,
    setNewTransactionModalOppenned,
  ] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentRow, setCurrentRow] = useState(25);
  const [endReach, setEndReach] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (
        lastDocument !== undefined &&
        (transactionList.length >= (currentPage + 1) * currentRow || endReach)
      ) {
        return;
      }
      setLoading(true);
      let query = firestore()
        .collection('transaction')
        .where('player_id', '==', playerId)
        .where('currency', '==', 'gems')
        .orderBy('date', 'desc');

      if (lastDocument !== undefined) {
        query = query.startAfter(lastDocument);
      }

      const result = await query.limit(LIMITE_FETCH).get();

      if (result.docs.length < LIMITE_FETCH) {
        setEndReach(true);
      }

      const rows: Transaction[] = [];

      result.forEach((doc) => {
        const data = doc.data() as Transaction;

        rows.push(data);
      });

      if (lastDocument === undefined) {
        setTransactionList(
          rows.map((item) => {
            return {
              ...item,
              date: dateUtils.localizedFormat(item.date.toDate(), 'Ppp'),
            };
          }),
        );
      } else {
        setTransactionList((value) => {
          return [
            ...value,
            ...rows.map((item) => {
              return {
                ...item,
                date: dateUtils.localizedFormat(item.date.toDate(), 'Ppp'),
              };
            }),
          ];
        });
      }
      setLastDocument(result.docs[result.docs.length - 1]);
      setLoading(false);
    })();
  }, [
    transactionList,
    currentPage,
    currentRow,
    playerId,
    endReach,
    lastDocument,
  ]);

  return (
    <>
      <Dialog
        open={newTransactionModalOppenned}
        onClose={() => {
          setNewTransactionModalOppenned(false);
        }}>
        <DialogTitle>Create manual gems transaction</DialogTitle>
        <Common.AddTransactionForm
          playerId={playerId}
          requester={BoyAPI.PlayerGemsTransactions.make}
          onSuccess={() => {
            notify({
              level: 'success',
              message: 'Gems transaction created !',
            });
            setCurrentPage(0);
            setLastDocument(undefined);
            setNewTransactionModalOppenned(false);
          }}
          onCancel={() => {
            setNewTransactionModalOppenned(false);
          }}
          notify={(n: Notif) => {
            notify(n);
          }}
          onError={(e: Error) => {
            onError(e);
          }}
        />
      </Dialog>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Stack
              direction="row"
              spacing={0}
              sx={{
                alignItems: 'center',
              }}>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Gems&nbsp;transactions
              </Typography>
              <ListItem>
                <Button onClick={() => setNewTransactionModalOppenned(true)}>
                  <AddCircleOutlineOutlined />
                  &nbsp;New&nbsp;transaction
                </Button>
              </ListItem>
            </Stack>
          }
        />
        <CardContent>
          <div style={{ minHeight: '400px', width: '100%' }}>
            <Table aria-label="collapsible table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.field}>{column.headerName}</TableCell>
                ))}
              </TableRow>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      style={{ textAlign: 'center' }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  [...transactionList]
                    .slice(
                      currentPage * currentRow,
                      (currentPage + 1) * currentRow,
                    )
                    .map((row) => (
                      <Row
                        key={row.id}
                        row={row}
                        fullList={transactionList}
                        columns={columns}
                      />
                    ))
                )}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                count={!endReach ? -1 : transactionList.length}
                onRowsPerPageChange={(e) => {
                  e?.preventDefault();
                  setCurrentRow(+e.target.value);
                }}
                onPageChange={(e, page) => {
                  e?.preventDefault();
                  setCurrentPage(page);
                }}
                page={currentPage}
                rowsPerPage={currentRow}
              />
            </Table>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default GemsTransactionsHistoryDataGrid;
