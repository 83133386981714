import { AxiosResponse } from 'axios';
import {
  Match as MatchType,
  NotificationTask,
  PlayerRanking,
  Review as ReviewType,
  WheelCollected,
  ModoRanking,
  MatchDistributionData,
  WaitDuration,
  NotificationStat,
  MessageCountByDay,
  MessageCountByPlayer,
  SocialLinksCount,
  BasicStatIdCount,
  MatchDistributionByGameData,
  MatchDistributionByPlatformData,
  MatchDistributionByGameAndPlatformData,
  ReferralCountByDay,
  MatchModeCountByDayData,
  Search2v2CountByDayData,
  GameStats,
  Device,
  SuspiciousBehavior,
  Tournament as TournamentType,
  TournamentWinner,
  UngroupedPlayerTransaction,
  OrderStore,
  GroupedPlayerTransaction,
  Bet,
  GoldsSums,
  Game,
  Shop as ShopType,
  Paginated,
  RewardUnit,
  ShopCategory,
  TournamentCancelReason,
  TournamentSetting,
  TokenAvailable,
  Web3Transaction,
} from 'core/types';
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import { generateBoyAPI } from './_helpers';

const BOY_API_URL = process.env.REACT_APP_BOY_API_URL;

const WINNER_URL = `${BOY_API_URL}/winners`;

const WinnerAPI = generateBoyAPI(WINNER_URL);
const Winner = {
  confirm(id: string, code: string) {
    return WinnerAPI.post('confirm_winner', {
      data: { winner_id: id, code: code },
    });
  },
  refund(id: string, reason: string) {
    return WinnerAPI.post('refund_winner', {
      data: { winner_id: id, RefundReason: reason },
    });
  },
  ban(id: string, banType: string, banReason: string, impliedAccounts: string) {
    return WinnerAPI.post('ban_winner', {
      data: {
        winner_id: id,
        ban_type: banType,
        reason: banReason,
        implied_accounts: impliedAccounts,
      },
    });
  },
  drawRaffleWinner(lotId: string, numberOfWinners: number, fromDate: string, toDate: string) {
    return WinnerAPI.post('lot/claim/draw_raffle', {
      data: {
        lot_id: lotId,
        number_of_winners: numberOfWinners,
        from_date: fromDate,
        to_date: toDate,
      },
    });
  },
};

const MatchAPI = generateBoyAPI(`${BOY_API_URL}/match`);
const Match = {
  automaticResolveMatch(matchId: string) {
    return MatchAPI.post(`${matchId}/result/auto_resolve`);
  },
};

const GamesAPI = generateBoyAPI(`${BOY_API_URL}/games`);
const Games = {
  list(): Promise<AxiosResponse<Game[]>> {
    return GamesAPI.get(`active`);
  },
};

const RefImporterAPI = generateBoyAPI(`${BOY_API_URL}/imports`);
const RefImporter = {
  import(
    category: string,
    file_url: string,
    items: string[] | null,
  ): Promise<AxiosResponse<object>> {
    return RefImporterAPI.post('', {
      data: { category: category, file_url: file_url, items: items },
    });
  },
  list(): Promise<AxiosResponse<object>> {
    return RefImporterAPI.get('');
  },
};

const BetsAPI = generateBoyAPI(`${BOY_API_URL}/bets`);
const Bets = {
  list_premiums(): Promise<AxiosResponse<Bet[]>> {
    return BetsAPI.get(`list/premiums`);
  },
};

const PlayerGoldsTransactions = {
  get_sums(player_id: string): Promise<AxiosResponse<GoldsSums>> {
    return PlayerAPI.get(`${player_id}/transactions/golds/sums`);
  },
  list_ungrouped(
    player_id: string,
    page: number,
    items_per_page: number,
    reason?: string,
    game_id?: string,
    admin_id?: string,
    lot_id?: string,
    from_date?: Date,
    to_date?: Date,
    order_by?: OrderStore,
  ): Promise<AxiosResponse<UngroupedPlayerTransaction>> {
    return PlayerAPI.get(`${player_id}/transactions/golds`, {
      params: {
        page,
        nb_items: items_per_page,
        game_id,
        admin_id,
        lot_id,
        reason,
        from_date: from_date
          ? from_date.toISOString().split('T')[0]
          : undefined,
        to_date: to_date ? to_date.toISOString().split('T')[0] : undefined,
        order_by: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === false)
          : undefined,
        order_by_desc: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === true)
          : undefined,
      },
    });
  },
  list_logical_grouped(
    player_id: string,
    page: number,
    items_per_page: number,
    reason?: string,
    game_id?: string,
    lot_id?: string,
    from_date?: Date,
    to_date?: Date,
    order_by?: OrderStore,
  ): Promise<AxiosResponse<GroupedPlayerTransaction>> {
    return PlayerAPI.get(`${player_id}/transactions/golds/grouped`, {
      params: {
        page,
        nb_items: items_per_page,
        game_id,
        lot_id,
        reason,
        from_date: from_date
          ? from_date.toISOString().split('T')[0]
          : undefined,
        to_date: to_date ? to_date.toISOString().split('T')[0] : undefined,
        order_by: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === false)
          : undefined,
        order_by_desc: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === true)
          : undefined,
      },
    });
  },
  make(
    player_id: string,
    amount: number,
    reason: string,
    metadata?: { [key: string]: any },
    notification?: string,
  ): Promise<AxiosResponse<UngroupedPlayerTransaction>> {
    return PlayerAPI.post(`${player_id}/golds/adjust`, {
      data: {
        amount,
        reason,
        opt_meta_data: metadata,
        notification,
      },
    });
  },
};

const PlayerPremiumBetsTransactions = {
  list_ungrouped(
    player_id: string,
    bet: number,
    is_free: boolean,
    page: number,
    items_per_page: number,
    reason?: string,
    game_id?: string,
    admin_id?: string,
    lot_id?: string,
    from_date?: Date,
    to_date?: Date,
    order_by?: OrderStore,
  ): Promise<AxiosResponse<UngroupedPlayerTransaction>> {
    return PlayerAPI.get(`${player_id}/transactions/premium_bets`, {
      params: {
        bet,
        is_free,
        page,
        nb_items: items_per_page,
        game_id,
        admin_id,
        lot_id,
        reason,
        from_date: from_date
          ? from_date.toISOString().split('T')[0]
          : undefined,
        to_date: to_date ? to_date.toISOString().split('T')[0] : undefined,
        order_by: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === false)
          : undefined,
        order_by_desc: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === true)
          : undefined,
      },
    });
  },
  list_logical_grouped(
    player_id: string,
    bet: number,
    is_free: boolean,
    page: number,
    items_per_page: number,
    reason?: string,
    game_id?: string,
    lot_id?: string,
    from_date?: Date,
    to_date?: Date,
    order_by?: OrderStore,
  ): Promise<AxiosResponse<GroupedPlayerTransaction>> {
    return PlayerAPI.get(`${player_id}/transactions/premium_bets/grouped`, {
      params: {
        page,
        bet,
        is_free,
        nb_items: items_per_page,
        game_id,
        lot_id,
        reason,
        from_date: from_date
          ? from_date.toISOString().split('T')[0]
          : undefined,
        to_date: to_date ? to_date.toISOString().split('T')[0] : undefined,
        order_by: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === false)
          : undefined,
        order_by_desc: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === true)
          : undefined,
      },
    });
  },
  make(
    player_id: string,
    amount: number,
    bet: number,
    is_free: boolean,
    reason: string,
    metadata?: { [key: string]: any },
    notification?: string,
  ): Promise<AxiosResponse<UngroupedPlayerTransaction>> {
    return PlayerAPI.post(`${player_id}/premium_bets/adjust`, {
      data: {
        bet,
        is_free,
        amount,
        reason,
        opt_meta_data: metadata,
        notification,
      },
    });
  },
};

const PlayerGemsTransactions = {
  make(
    player_id: string,
    amount: number,
    reason: string,
    metadata?: { [key: string]: any },
    notification?: string,
  ): Promise<AxiosResponse<UngroupedPlayerTransaction>> {
    return PlayerAPI.post(`${player_id}/gems/adjust`, {
      data: {
        amount,
        reason,
        opt_meta_data: metadata,
        notification,
      },
    });
  },
};

const PlayerStocks = {
  reset(player_id: string): Promise<AxiosResponse<unknown>> {
    return PlayerAPI.get(`${player_id}/transactions/stocks/reset`);
  },
};

const ResultAPI = generateBoyAPI(`${BOY_API_URL}/results`);
const Result = {
  litigationResolve(
    id: string,
    team1_result: string,
    team2_result: string,
    team1_ban: string,
    team2_ban: string,
  ) {
    let body = {
      team1_result: team1_result,
      team2_result: team2_result,
    };
    if (team1_ban) {
      body['team1_ban'] = team1_ban;
    }
    if (team2_ban) {
      body['team2_ban'] = team2_ban;
    }

    return ResultAPI.post(`litigation/${id}/resolve/`, {
      data: body,
    });
  },
  getOldestLitigation: async () => {
    const response = await ResultAPI.get<MatchType | undefined>(
      'get_oldest_litigation/',
    );
    return response.data;
  },
};

const NotificationAPI = generateBoyAPI(`${BOY_API_URL}/notifications`);
const Notifications = {
  create(
    topicCondition: string,
    title: string,
    message: string,
    popInApp: boolean,
    selectedDate: Date,
    url: string,
  ) {
    return NotificationAPI.post('schedule/', {
      data: {
        topic_condition: topicCondition,
        title: title,
        message: message,
        pop_in_app: popInApp,
        date: selectedDate,
        url: url,
      },
    });
  },
  update(
    id: string,
    topicCondition: string,
    title: string,
    message: string,
    popInApp: boolean,
    selectedDate: Date,
    url: string,
  ) {
    return NotificationAPI.post('schedule/', {
      data: {
        id: id,
        topic_condition: topicCondition,
        title: title,
        message: message,
        pop_in_app: popInApp,
        date: selectedDate,
        url: url,
      },
    });
  },
  get() {
    return NotificationAPI.get<{ tasks: NotificationTask[] }>('');
  },
  getById(id: string) {
    return NotificationAPI.get<{ task: NotificationTask }>(id);
  },
  delete(id: string) {
    return NotificationAPI.delete('cancel/' + id);
  },
};

const StatisticAPI = generateBoyAPI(`${BOY_API_URL}/statistics`);
const Statistic = {
  getLitigationsCount: async () => {
    const response = await StatisticAPI.get<{ count: number }>(
      'match/get_litigations_count/',
    );
    return response?.data.count;
  },
  getPlayerRanking: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: PlayerRanking[] }>(
      'match/get_player_ranking/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getWaitDuration: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: WaitDuration[] }>(
      'match/get_wait_duration/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getMatchDistribution: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: MatchDistributionData[] }>(
      'match/get_match_distribution/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    if (Array.isArray(response?.data.res)) {
      return response.data.res;
    }
    return [];
  },
  getMatchModeCountByDay: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{
      res: MatchModeCountByDayData[];
    }>('match/get_match_mode_count_by_day/', {
      data: {
        begin_date: beginDate,
        end_date: endDate,
      },
    });
    return response?.data.res;
  },
  getCountSearch2v2ByDay: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{
      res: Search2v2CountByDayData[];
    }>('match/get_count_search_2v2_by_day/', {
      data: {
        begin_date: beginDate,
        end_date: endDate,
      },
    });
    return response?.data.res;
  },
  getMatchDistributionByGame: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{
      res: MatchDistributionByGameData[];
    }>('match/get_match_distribution_by_game/', {
      data: {
        begin_date: beginDate,
        end_date: endDate,
      },
    });
    return response?.data.res;
  },
  getMatchDistributionByPlatform: async (
    beginDate: string,
    endDate: string,
  ) => {
    const response = await StatisticAPI.post<{
      res: MatchDistributionByPlatformData[];
    }>('match/get_match_distribution_by_platform/', {
      data: {
        begin_date: beginDate,
        end_date: endDate,
      },
    });
    return response?.data.res;
  },
  getMatchDistributionByGameAndPlatform: async (
    beginDate: string,
    endDate: string,
  ) => {
    const response = await StatisticAPI.post<{
      res: MatchDistributionByGameAndPlatformData[];
    }>('match/get_match_distribution_by_game_and_platform/', {
      data: {
        begin_date: beginDate,
        end_date: endDate,
      },
    });
    return response?.data.res;
  },
  getModoRanking: async (pyear: number, pmonth: number) => {
    const response = await StatisticAPI.post<{ res: ModoRanking[] }>(
      'match/get_modo_ranking/',
      {
        data: {
          year: pyear,
          month: pmonth,
        },
      },
    );
    return response?.data.res;
  },
  getWheelsCollectedByDay: async (endDate: Date) => {
    const beginDate = '2021-04-01';
    const response = await StatisticAPI.post<{ res: WheelCollected[] }>(
      'wheel/wheel_collected_by_day/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate
            .toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            .split('/')
            .reverse()
            .join('-'),
        },
      },
    );
    return response?.data.res;
  },
  getNotificationsStats: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: NotificationStat[] }>(
      'notifications/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getMessagesCountByDay: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: MessageCountByDay[] }>(
      'social/messages_count_by_days/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getMessagesCountByPlayer: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: MessageCountByPlayer[] }>(
      'social/get_messages_count_by_player/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getSocialLinksCount: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: SocialLinksCount[] }>(
      'social/get_social_links_count/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getFriendsCount: async () => {
    const response = await StatisticAPI.get<{ res: BasicStatIdCount[] }>(
      'social/friends_count_by_player/',
    );
    return response?.data.res;
  },
  getPlayerBlockedCount: async () => {
    const response = await StatisticAPI.get<{ res: BasicStatIdCount[] }>(
      'social/player_blocked_count/',
    );
    return response?.data.res;
  },
  getPlayerBlockedByDay: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: SocialLinksCount[] }>(
      'social/get_blocked_players_by_day/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },
  getPlayerBlocksCount: async () => {
    const response = await StatisticAPI.get<{ res: BasicStatIdCount[] }>(
      'social/player_blocks_count/',
    );
    return response?.data.res;
  },
  getReferralCount: async () => {
    const response = await StatisticAPI.get<{ res: number }>(
      'social/referral_count/',
    );
    return response?.data.res;
  },
  getReferralValidatedCount: async () => {
    const response = await StatisticAPI.get<{ res: number }>(
      'social/referral_validated_count/',
    );
    return response?.data.res;
  },
  getReferralDistinctCount: async () => {
    const response = await StatisticAPI.get<{ res: number }>(
      'social/distinct_referral_count/',
    );
    return response?.data.res;
  },
  getReferralCountByDay: async (beginDate: string, endDate: string) => {
    const response = await StatisticAPI.post<{ res: ReferralCountByDay[] }>(
      'social/referral_count_by_day/',
      {
        data: {
          begin_date: beginDate,
          end_date: endDate,
        },
      },
    );
    return response?.data.res;
  },

  getStatsInApp: async (id_game_in_app: string, dateRange?: [Date, Date]) => {
    let begindate: undefined | string = undefined;
    let enddate: undefined | string = undefined;
    if (dateRange) {
      let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[0],
      );
      let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[0],
      );
      let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[0],
      );
      begindate = `${ye1}-${mo1}-${da1}`;

      let ye2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[1],
      );
      let mo2 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[1],
      );
      let da2 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[1],
      );
      enddate = `${ye2}-${mo2}-${da2}`;
    }

    const response = await StatisticAPI.get<GameStats>(
      `challenges/${id_game_in_app}`,
      {
        params: {
          from_date: begindate,
          to_date: enddate,
        },
      },
    );
    return response?.data;
  },

  getStatsInAppFirstTwoGames: async (id_game_in_app: string) => {
    const response = await StatisticAPI.get<GameStats>(
      `challenges/${id_game_in_app}/first_two_games`,
    );
    return response?.data;
  },
};

const LegacyAdminAPI = generateBoyAPI(`${BOY_API_URL}/admin`);
const AdminAPI = generateBoyAPI(`${BOY_API_URL}/admins`);
const Admin = {
  setClaims: async (id: string, claims: string[]) => {
    const response = await LegacyAdminAPI.post('set_claims/', {
      data: {
        player_id: id,
        claims,
      },
    });
    return response;
  },
  listRights: async (): Promise<AxiosResponse<{ [right: string]: string }>> => {
    return await AdminAPI.get(`rights`);
  },
  setPlayerToReady: async (id: string) => {
    return await AdminAPI.get(`players/unblock/${id}`);
  },
  getTokenAvailable() {
    return AdminAPI.get<TokenAvailable[]>('commons/crypto/tokens');
  }
};

const CryptoTransaction = {
  getFailedTransaction: async ({
    page,
    nbItems,
    filter,
    sort,
  }: {
    page: number;
    nbItems: number;
    filter: any;
    sort: GetListParams['sort'];
  }) => {

    if (sort && sort.field) {
      console.log("test", sort);
    }

    const formattedSortBy =
    sort && sort.field && sort.field != "id"
      ? {
          [sort.order === 'ASC' ? 'order_by' : 'order_by_desc']: sort.field,
        }
      : {['order_by_desc']: "purchased_at"};

    try {

      const result =  await AdminAPI.get<{
        items: Web3Transaction[],
        total : number,
        total_page: number,
        page: number,
        nb_items: number,
      }>(`failed_crypto_transactions?page=${page}&nb_items=${nbItems}&${new URLSearchParams({
        ...filter,
        ...formattedSortBy,
      }).toString()}`); 


      return {
        data: [...result.data.items],
        total: result.data.total
      } as GetListResult;

    } catch (e) {
      console.error('error : fetch failed_crypto_transactions');
      console.error(e);

      return {
        data: [],
        total: 0,
      } as GetListResult;
    }
   
  },
  getOneFailedTransaction : async (id: string) => {
    try {

      const result =  await AdminAPI.get<Web3Transaction>(`failed_crypto_transactions/${id}`); 

      console.log("result", result);

      return { data: result.data };

    } catch (e) {
      console.error('error : fetch getOneFailedTransaction');
      console.error(e);
    }

  },
  refundFailedTransaction: async (transactionId : string, raison : string, refunded : boolean) => {
    return AdminAPI.post<{ "refunded": boolean }>(`failed_crypto_transactions/${transactionId}/refund`, {
      data: {
        note: raison,
        refunded : refunded,
      },
    })
  },
  reclaimFailedTransaction: async (transactionId : string, raison : string) => {
    return AdminAPI.post<{ "reclaim": boolean }>(`failed_crypto_transactions/${transactionId}/reclaim`, {
      data: {
        note: raison,
      },
    })
  },
  patchTransactionNote : async (transactionId : string, raison : string) => {
    return AdminAPI.patch(`failed_crypto_transactions/${transactionId}`, {
      data: {
        note: raison,
      },
    })
  }
}

const ReviewAPI = generateBoyAPI(`${BOY_API_URL}/reviews`);
const Review = {
  getOldestReview: async () => {
    const response = await ReviewAPI.get<{
      review: ReviewType | { id: undefined };
    }>('get_oldest_review/');
    return response.data?.review;
  },
  validateReview: async (id: string) => {
    const response = await ReviewAPI.post(`validate_review/${id}/`, {
      data: {
        review_id: id,
      },
    });
    return response;
  },
  refuseReview: async (id: string) => {
    const response = await ReviewAPI.post(`refuse_review/${id}/`, {
      data: {
        review_id: id,
      },
    });
    return response;
  },
};

const PlayerAPI = generateBoyAPI(`${BOY_API_URL}/players`);
const Player = {
  getAvatars: async (id: string) => {
    const response = await PlayerAPI.get<{
      avatars: {
        id: string;
        image_url: string;
        kind: string;
        onwed_since: string;
        title: string;
      }[];
    }>(`${id}/avatars`);
    return response.data;
  },
  update: async (id: string, data: any) => {
    return await PlayerAPI.patch(id, {
      data,
    });
  },
  ban(
    id: string,
    banType: string,
    banReason: string,
    impliedAccounts?: string | null,
  ) {
    return PlayerAPI.post(`${id}/ban`, {
      data: {
        reason: banReason,
        ban_id: banType,
        implied_accounts: impliedAccounts,
      },
    });
  },
  unban(id: string, banReason: string) {
    return PlayerAPI.post(`${id}/unban`, {
      data: {
        reason: banReason,
      },
    });
  },
  getDevices(id: string) {
    return PlayerAPI.get<Device[]>(`${id}/devices`);
  },
  getSuspiciousBehaviors(id: string) {
    return PlayerAPI.get<SuspiciousBehavior[]>(`${id}/suspicious_beahaviors`);
  },
  refreshNFT(id: string) {
    return PlayerAPI.post(`${id}/nft/refresh`);
  },
  toggleAutoBan(id: string) {
    return PlayerAPI.post(`${id}/toggle_auto_ban`);
  }
};

const WalletAPI = generateBoyAPI(`${BOY_API_URL}/wallets`);
const Wallets = {
  unlinkAll(playerId: string) {
    return WalletAPI.post(`${playerId}/unlink/all`);
  },
  unlink(playerId: string, walletId: string) {
    return WalletAPI.post(`${playerId}/${walletId}/unlink`);
  },
};

//lol

const ShopAPI = generateBoyAPI(`${BOY_API_URL}/shop`);

const Shop = {
  list(
    page: number,
    items_per_page: number,
    name?: string,
    is_global?: boolean,
    is_active?: boolean,
    order_by?: OrderStore,
  ): Promise<AxiosResponse<Paginated & { items: ShopType[] }>> {
    return ShopAPI.get(``, {
      params: {
        page,
        nb_items: items_per_page,
        name,
        is_global,
        is_active,
        order_by: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === false)
          : undefined,
        order_by_desc: order_by
          ? Object.keys(order_by).find((key) => order_by[key] === true)
          : undefined,
      },
    });
  },
  get(id: string): Promise<AxiosResponse<ShopType>> {
    return ShopAPI.get(`${id}`);
  },
  listCategories(): Promise<AxiosResponse<ShopCategory[]>> {
    return ShopAPI.get(`products/categories`);
  },
};

const TournamentsAPI = generateBoyAPI(`${BOY_API_URL}/tournaments`);

const Tournament = {
  getList: async ({
    page,
    nbItems,
    filter,
    sort,
  }: {
    page: number;
    nbItems: number;
    filter: any;
    sort: GetListParams['sort'];
  }) => {
    const formattedSortBy =
      sort && sort.field
        ? {
            [sort.order === 'ASC' ? 'order_by' : 'order_by_desc']: sort.field,
          }
        : {};
    try {
      const result = await TournamentsAPI.get<{
        items: TournamentType[];
      }>(
        `?page=${page}&nb_items=${nbItems}&${new URLSearchParams({
          ...filter,
          ...formattedSortBy,
        }).toString()}`,
      );

      return {
        data: [...result.data.items],
        total: result.data.items.length,
      } as GetListResult;
    } catch (e) {
      //console.log('error : fetch tournament');
      //console.log(e);

      return {
        data: [],
        total: 0,
      } as GetListResult;
    }
  },
  getById: async (id: string) => {
    const result = await TournamentsAPI.get<TournamentType>(id);
    return { data: result.data };
  },
  getLeaderBoard: async ({
    nbItems,
    tournamentId,
    page,
    filters,
  }: {
    tournamentId: string;
    nbItems: number;
    page: number;
    filters: { [key: string]: string };
  }) => {
    const result = await TournamentsAPI.get<{
      items: TournamentWinner[];
      total: number;
    }>(
      `${tournamentId}/leaderboard?${new URLSearchParams({
        nb_items: `${nbItems}`,
        page: `${page}`,
        ...filters,
      }).toString()}`,
    );
    return result;
  },
  update(id: string, data: any) {
    return TournamentsAPI.put(id, {
      data,
    });
  },
  create(
    game_id: string,
    start_at: Date,
    end_at: Date,
    reward_pool: number,
    unit: RewardUnit,
    reward_repartitions: { [key: string]: string },
    token_id?: string,
  ) {
    return TournamentsAPI.post('', {
      data: {
        game_id,
        reward_pool,
        start_at,
        end_at,
        reward_repartitions,
        unit,
        token_id
      },
    });
  },
  cancel(tournament_id: string, reason: TournamentCancelReason) {
    return TournamentsAPI.put(`${tournament_id}/cancel`, {
      data: {
        reason,
      },
    });
  },
  listSettings(): Promise<AxiosResponse<TournamentSetting[]>> {
    return TournamentsAPI.get('settings');
  },
  startSerie(
    game_id: string,
    start_at: Date,
    end_at: Date,
    reward_pool: number,
    unit: RewardUnit,
    reward_repartitions: { [key: string]: string },
    config_name: string,
    token_id?: string,
  ) {
    return TournamentsAPI.post('serie', {
      data: {
        game_id,
        reward_pool,
        start_at,
        end_at,
        reward_repartitions,
        unit,
        config_name,
        token_id
      },
    });
  }
};

//@ts-ignore
export const tournamentsDataProvider: DataProvider = {
  getList: (_: string, params: GetListParams) => {
    return BoyAPI.Tournament.getList({
      page: params.pagination.page,
      nbItems: params.pagination.perPage,
      filter: params.filter,
      sort: params.sort,
    });
  },
  getOne: (_: string, params: GetOneParams) => {
    return BoyAPI.Tournament.getById(params.id);
  },
  getMany: (resource: string, params: GetManyParams) => {},
  getManyReference: (resource: string, params: GetManyReferenceParams) => {},
  update: async (resource: string, params: UpdateParams) => {
    return BoyAPI.Tournament.update(params.id, params.data);
  },
  updateMany: (resource: string, params: UpdateManyParams) => {},
  create: (resource: string, params: CreateParams) => {},
  delete: (resource: string, params: DeleteParams) => {},
  deleteMany: (resource: string, params: DeleteManyParams) => {},
} as DataProvider;


//@ts-ignore
export const web3TransactionDataProvider: DataProvider = {
  getList: (_: string, params: GetListParams) => {
    return CryptoTransaction.getFailedTransaction({
      page: params.pagination.page,
      nbItems: params.pagination.perPage,
      filter: params.filter,
      sort: params.sort,
    });
  },
  getOne: (_: string, params: GetOneParams) => {
    return CryptoTransaction.getOneFailedTransaction(params.id);
  },
  getMany: (_resource: string, _params: GetManyParams) => {},
  getManyReference: (_resource: string, _params: GetManyReferenceParams) => {},
  update: async (_resource: string, _params: UpdateParams) => {
  },
  updateMany: (_resource: string, _params: UpdateManyParams) => {},
  create: (_resource: string, _params: CreateParams) => {},
  delete: (__resource: string, _params: DeleteParams) => {},
  deleteMany: (_resource: string, _params: DeleteManyParams) => {},
} as DataProvider;

const BoyAPI = {
  Admin,
  Bets,
  Games,
  Match,
  Notifications,
  Player,
  Wallets,
  Tournament,
  PlayerGemsTransactions,
  PlayerGoldsTransactions,
  PlayerPremiumBetsTransactions,
  PlayerStocks,
  RefImporter,
  Result,
  Review,
  Statistic,
  Shop,
  Winner,
  CryptoTransaction,
};

export default BoyAPI;
