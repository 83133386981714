import { NotificationType } from 'react-admin';

const Parser = {
  error(err: {
    response?: { data?: { error?: string; detail?: string }; status?: number };
  }): [string, { type: NotificationType }] {
    if (err.response === undefined) {
      return ['Unknown error', { type: 'error' }];
    }

    return [
      err.response.data?.error || err.response.data?.detail || 'Unknown error',
      {
        type: (err.response.status || 500) < 500 ? 'warning' : 'error',
      },
    ];
  },
};

export default Parser;
