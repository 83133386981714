import NumberCard from 'components/data/NumberCard';
  import { useState, useEffect } from 'react';
  import { MatchHistory } from 'core/types';
  
  type Props = {
    matchHistoryList : MatchHistory[]
  }

  const SameIdentifiersCard = ({matchHistoryList}:Props) => {
    const [sameIdentifiersCount, setSameIdentifiersCount] = useState<number | false>(false);

    useEffect(() => {
      (async () => {

        let count = matchHistoryList.filter(ma => ma.sameIdentifiers ).length

        setSameIdentifiersCount(count);

      })();
    }, []);
    return <NumberCard title={'Same identifiers matchs'} number={sameIdentifiersCount} />;
  };

  export default SameIdentifiersCard;
  