import { AxiosError, AxiosRequestConfig } from 'axios';
import { authFetch } from 'utils/fetch';

const generateBoyAPI = (baseUrl: string) => {
  const method = (methodName: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') => <
    Response
  >(
    path: string,
    options?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ) => {
    return authFetch<Response>({
      ...options,
      url: `${baseUrl}/${path}`,
      method: methodName,
    });
  };
  return {
    get: method('GET'),
    post: method('POST'),
    put: method('PUT'),
    patch: method('PATCH'),
    delete: method('DELETE'),
  };
};

const isValidationError = (error: AxiosError) => {
  if (!(error.response?.status === 422) || !error.response?.data.detail) {
    return false;
  }
  return true;
};

const arrayToString = (loc: string[]) => {
  return loc.slice(1, loc.length).reduce((acc, cur) => `${acc}.${cur}`, loc[0]);
};

const getValidationErrorFields = (
  error: AxiosError,
): { field: string; msg: string }[] => {
  if (!(error.response?.status === 422) || !error.response?.data.detail) {
    return [];
  }
  return error.response?.data.detail.map((item: any) => ({
    field: arrayToString(item.loc),
    msg: item.msg,
  }));
};

export { generateBoyAPI, isValidationError, getValidationErrorFields };
