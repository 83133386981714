import {
  Card,
  CardContent,
  Typography,
  Chip,
  ListItem,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  CardHeader,
  Tab,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import Common, { Error, InnerViewProps, Notif } from './common';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import BoyAPI from 'services/boy-api';
import Tabs from '@mui/material/Tabs';
import UngroupedPremiumBetsTransactions from './PremiumBets/UngroupedList';
import GroupedPremiumBetsTransactions from './PremiumBets/GroupedsList';
const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
  check: {
    marginBottom: 0,
    fontSize: 12,
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  clickableCell: {
    cursor: 'pointer',
  },
});

type ExistingBet = {
  value: number;
  isFree: boolean;
  logo: string;
};

const PremiumBetsTransactionsListing = ({
  playerId,
  notify,
  onError,
}: InnerViewProps) => {
  const { classes } = useStyles();

  const [liveStock, setLiveStock] = useState<number>(0);
  const [visibleStock, setVisibleStock] = useState<number>(0);

  const [displayGrouped, setDisplayedGrouped] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [
    newTransactionModalOppenned,
    setNewTransactionModalOppenned,
  ] = useState<boolean>(false);

  const onSuccess = useCallback((liveStock: number, visibleStock: number) => {
    setLiveStock(liveStock);
    setVisibleStock(visibleStock);
  }, []);

  const [availablePremiumBets, setAvailablePremiumBets] = useState<
    ExistingBet[]
  >([]);

  const [selectedBetTabIdx, setSelectedBetTabIdx] = useState<number>(0);
  const [selectedBetData, setSelectedBetData] = useState<
    ExistingBet | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      try {
        let res = await BoyAPI.Bets.list_premiums();
        let availableBets = res.data
          .flatMap((bet) => {
            if (bet.is_free_premium) {
              return [
                {
                  value: bet.value,
                  isFree: false,
                  logo: bet.logos.default,
                },
                {
                  value: bet.value,
                  isFree: true,
                  logo: bet.logos.free_premium || bet.logos.default,
                },
              ];
            }
            return {
              value: bet.value,
              isFree: false,
              logo: bet.logos.default,
            };
          })
          .sort((a, b) => {
            if (a.isFree && !b.isFree) {
              return 1;
            }
            if (!a.isFree && b.isFree) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            if (a.value < b.value) {
              return -1;
            }
            return 0;
          });

        setAvailablePremiumBets(availableBets);

        if (selectedBetData === undefined) {
          setSelectedBetData(availableBets[0]);
        }
      } catch (error) {
        onError({
          severity: error.response.status >= 500 ? 'error' : 'warning',
          error: error.response.data.error || error.response.data.detail,
          key: error.response.data.key,
        });

        return;
      }
    })();
  }, [onError, selectedBetData]);

  return (
    <>
      <Dialog
        open={newTransactionModalOppenned}
        onClose={() => {
          setNewTransactionModalOppenned(false);
        }}>
        <DialogTitle>Create manual premium bets transaction</DialogTitle>
        <Common.AddTransactionForm
          playerId={playerId}
          isPremiumBet
          requester={BoyAPI.PlayerPremiumBetsTransactions.make}
          onSuccess={(bet?: number, isFree?: boolean) => {
            setTimeout(function () {
              notify({
                level: 'success',
                message: 'Bets transaction created !',
              });

              let selectedBet = availablePremiumBets.filter((candidate) => {
                return candidate.isFree === isFree && candidate.value === bet;
              })[0];

              setSelectedBetData(selectedBet);
              setSelectedBetTabIdx(availablePremiumBets.indexOf(selectedBet));
              setRefresh(!refresh);
              setNewTransactionModalOppenned(false);
            }, 1000);
          }}
          onCancel={() => {
            setNewTransactionModalOppenned(false);
          }}
          notify={(n: Notif) => {
            notify(n);
          }}
          onError={(e: Error) => {
            onError(e);
          }}
        />
      </Dialog>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <ListItem>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                  }}>
                  <ListItem>
                    <Typography
                      color="textSecondary"
                      align="left"
                      className={classes.title}>
                      Premium&nbsp;bets&nbsp;transactions
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Button
                      onClick={() => setNewTransactionModalOppenned(true)}>
                      <AddCircleOutlineOutlined />
                      &nbsp;New&nbsp;transaction
                    </Button>
                  </ListItem>
                </Stack>
              </ListItem>

              <Stack direction="row">
                <ListItem>
                  <label for="displayBetsSwitch">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        color={displayGrouped ? 'textSecondary' : 'textPrimary'}
                        align="left"
                        className={classes.check}>
                        Raw
                      </Typography>
                      <Switch
                        id="displayBetsSwitch"
                        checked={displayGrouped}
                        onChange={(e) => {
                          setDisplayedGrouped(e.target.checked);
                        }}
                      />
                      <Typography
                        color={displayGrouped ? 'textPrimary' : 'textSecondary'}
                        align="left"
                        className={classes.check}>
                        Grouped
                      </Typography>
                    </Stack>
                  </label>
                </ListItem>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <ListItem sx={{ flexBasis: 2, flexGrow: 5 }}>
              <Tabs
                value={selectedBetTabIdx}
                scrollButtons={false}
                onChange={(_, idx: number) => {
                  setSelectedBetTabIdx(idx);
                  setSelectedBetData(availablePremiumBets[idx]);
                }}>
                {availablePremiumBets.map((bet) => {
                  return (
                    <Tab
                      label={
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ alignItems: 'center' }}>
                          <img src={bet.logo} height="30px" width="30px" />
                          <p>
                            {bet.isFree ? 'free' : ''} {bet.value}
                          </p>
                        </Stack>
                      }></Tab>
                  );
                })}
              </Tabs>
            </ListItem>
            <ListItem sx={{ flexBasis: 1, flexGrow: 0.5 }}>
              <Stack direction="row">
                <ListItem>
                  <Chip
                    icon={
                      <img
                        alt="premium bet"
                        src={selectedBetData?.logo}
                        height="25px"
                        width="25px"
                      />
                    }
                    label={'live: ' + liveStock.toString()}
                    variant="outlined"
                  />
                </ListItem>
                <ListItem>
                  <Chip
                    icon={
                      <img
                        alt="golds"
                        src={selectedBetData?.logo}
                        height="25px"
                        width="25px"
                      />
                    }
                    label={'visible: ' + visibleStock}
                    variant="outlined"
                    color={liveStock === visibleStock ? 'success' : 'error'}
                  />
                </ListItem>
              </Stack>
            </ListItem>
          </Stack>

          {selectedBetData &&
            (displayGrouped ? (
              <GroupedPremiumBetsTransactions
                playerId={playerId}
                value={selectedBetData?.value}
                isFree={selectedBetData?.isFree || false}
                refresh={refresh}
                onSuccess={onSuccess}
                onError={onError}
              />
            ) : (
              <UngroupedPremiumBetsTransactions
                playerId={playerId}
                value={selectedBetData?.value}
                isFree={selectedBetData?.isFree || false}
                refresh={refresh}
                onSuccess={onSuccess}
                onError={onError}
              />
            ))}
        </CardContent>
      </Card>
    </>
  );
};

export default PremiumBetsTransactionsListing;
