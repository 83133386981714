import React from 'react';
import { Chip as MuiChip, ChipProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    height: '24px',
    backgroundColor: '#1692ff',
  },
});

const Chip = (props: ChipProps) => {
  const { classes } = useStyles();
  return (
    <MuiChip
      {...props}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default Chip;
