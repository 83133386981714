import { Admin, CustomRoutes, Loading, Resource } from 'react-admin';
import './App.css';
import { dataProvider, authProvider, firebase } from 'services/firestore';
import { Route } from 'react-router-dom';
import PlayersList from 'pages/PlayersList';
import PersonIcon from '@mui/icons-material/Person';
import PlayerDetail from 'pages/PlayerDetail';
import theme, { themeMui } from 'core/theme';
import Dashboard from 'pages/Dashboard';
import { useEffect } from 'react';
import WinnerPage from './pages/Winner';
import ShopPage from './pages/Shop';
import SalesPage from './pages/Sales';
import PlayersRanking from './pages/PlayersRanking';
import ReferentialImport from './pages/ReferentialImport';
import ModoRanking from './pages/ModoRanking';
import SocialStat from './pages/SocialStat';
import ReferralStat from './pages/ReferralStat';
import MatchDistribution from './pages/MatchDistribution';
import Notification from './pages/Notification';
import Redeem from '@mui/icons-material/Redeem';
import TournamentPages from 'pages/Tournament';

import AddCircle from '@mui/icons-material/AddCircle';

import Money from '@mui/icons-material/AttachMoney';
import Review from 'pages/Review';
import AppLayout from 'components/navigation/AppLayout';
import useCurrentPlayer from 'hooks/use-current-player';
import PlayerPages from 'pages/Player';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import InAppStat from 'pages/InAppStats';
import { Permission } from 'core/types';
import { Shop } from '@mui/icons-material';
import TransactonFailedList from 'pages/Web3';
import TransactionFailedDetail from 'pages/Web3/TransactionFailedDetail';
// import { Storefront } from '@mui/icons-material';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  const {
    list: NotificationList,
    create: NotificationCreate,
    show: NotificationShow,
  } = Notification;

  const customRoutes = [
    <Route path="/shop" key="Shop" element={<ShopPage.Home />} />,
    <Route path="/shop/:id" key="Shop view" element={<ShopPage.View />} />,
  ];

  const customAdminRoutes = [
    <Route
      path="/PlayersRanking"
      element={<PlayersRanking />}
      key="PlayersRanking"
    />,
    <Route path="/ModoRanking" element={<ModoRanking />} key="ModoRanking" />,
    <Route
      path="/ReferentialImport"
      element={<ReferentialImport />}
      key="ReferentialImport"
    />,
    <Route
      path="/Notification"
      element={<NotificationList />}
      key="Notifications"
    />,
    <Route
      path="/Notification/create"
      element={<NotificationCreate />}
      key="NotificationCreate"
    />,
    <Route
      path="/Notification/show/:id"
      element={<NotificationShow />}
      key="NotificationShow"
    />,
    <Route path="/SocialStat" element={<SocialStat />} key="SocialStat" />,
    <Route
      path="/ReferralStat"
      element={<ReferralStat />}
      key="ReferralStat"
    />,
    <Route path="/InAppStat" element={<InAppStat />} key="InAppStat" />,
    <Route
      path="/MatchDistribution"
      element={<MatchDistribution />}
      key="MatchDistribution"
    />,
  ];

  const customModeratorRoutes = [
    <Route path="/ModoRanking" element={<ModoRanking />} />,
  ];

  const { player, loading } = useCurrentPlayer();

  useEffect(() => {
    if (loading || !player) {
      return;
    }
    if (!player.is_admin && !player.is_moderator) {
      firebase.auth().signOut();
    }
  }, [loading, player]);

  if (loading) {
    return <Loading loadingPrimary=" " loadingSecondary=" " />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeMui}>
        <div className="App">
          <Admin
            requireAuth
            disableTelemetry
            layout={AppLayout}
            dataProvider={dataProvider}
            customRoutes={(player?.is_admin
              ? customAdminRoutes
              : customModeratorRoutes
            ).push(...customRoutes)}
            authProvider={authProvider}
            theme={theme}
            dashboard={Dashboard}>
            <Resource name="log_identifiers" options={{ hidden: true }} />
            <Resource
              name="player"
              {...PlayerPages}
              list={PlayersList}
              icon={PersonIcon}
              show={PlayerDetail}
              options={{
                label: 'Players',
              }}
            />
            <Resource
              name="web3Transaction"
              list={TransactonFailedList}
              icon={PersonIcon}
              show={TransactionFailedDetail}
              options={{
                label: 'Web 3 transaction',
              }}
            />
            <Resource
              name="tournaments"
              {...TournamentPages}
              icon={EmojiEventsIcon}
              options={{
                label: 'Tournaments',
              }}
            />
            <CustomRoutes>{customRoutes}</CustomRoutes>

            <Resource
              name="review"
              icon={AddCircle}
              options={{ label: 'Bonus Reviews' }}
              {...Review}
            />
            {player?.is_admin ||
            player?.permissions?.includes(Permission.BanRefundLots) ||
            player?.permissions?.includes(Permission.ValidateLots) ? (
              <Resource
                name="winner"
                {...WinnerPage}
                icon={Redeem}
                options={{
                  label: 'Lot Winners',
                }}
                key="winner"
              />
            ) : (
              <></>
            )}
            {player?.is_admin ? (
              [
                <Resource
                  name="sales"
                  {...SalesPage}
                  icon={Money}
                  options={{
                    label: 'Sales',
                  }}
                  key="sales"
                />,
              ]
            ) : (
              <></>
            )}
            <CustomRoutes>
              {player?.is_admin ? customAdminRoutes : customModeratorRoutes}
            </CustomRoutes>
          </Admin>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
