import {
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Shop } from 'core/types';
import { useEffect, useMemo, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import BoyAPI from 'services/boy-api';
import { Parser, TextField, BoolField, ObjectField } from '../common';
import { useParams } from 'react-router-dom';
import { Country, GetCountry } from 'utils/countries';
import ProductList from '../Products/List';

const DEFAULT_COLUMNS = [
  { field: 'flag', headerName: 'Flag' },
  { field: 'name', headerName: 'Name' },
  { field: 'money_unit', headerName: 'Money unit' },
  { field: 'is_active', headerName: 'Active?' },
  { field: 'is_global', headerName: 'Global?' },
];

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: '1.8rem',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: '1.7rem',
    textTransform: 'capitalize',
  },
});

const ShopView = () => {
  const { id } = useParams();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(true);
  const [shop, setShop] = useState<Shop | undefined>(undefined);
  const [country, setCountry] = useState<Country | undefined>(undefined);

  const { classes } = useStyles();
  const columns = useMemo(() => [...DEFAULT_COLUMNS], []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        let res = await BoyAPI.Shop.get(id || 'nope');
        let country = GetCountry(res.data.flag);
        setShop(res.data);
        setCountry(country);
      } catch (error) {
        const [msg, type] = Parser.error(error);
        notify(msg, type);
      }

      setLoading(false);
    })();
  }, [notify, id]);

  return (
    <>
      <Title title={`Shop ${country?.flag} #${shop?.id}`} />
      {loading ? (
        <Card>
          <CircularProgress />
        </Card>
      ) : (
        <>
          <Card>
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  align="left"
                  className={classes.title}>
                  {country?.flag} {shop?.name}
                </Typography>
              }
            />
            <CardContent>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <TextField label="id" data={shop?.id} />
                      <BoolField label="global?" data={shop?.is_global} />
                      <BoolField label="active?" data={shop?.is_active} />
                    </Grid>
                    <Grid item>
                      <ObjectField label="labels" data={shop?.labels} />

                      <TextField label="money" data={shop?.money_unit} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              title={
                <Typography
                  color="textSecondary"
                  align="left"
                  className={classes.subtitle}>
                  Products
                </Typography>
              }
            />
            <ProductList shopId={shop?.id} />
          </Card>
        </>
      )}
    </>
  );
};

export default ShopView;
