import { CircularProgress } from '@mui/material';
import { Pie, ChartComponentProps } from 'react-chartjs-2';

const PieChart = ({
  loading,
  ...otherProps
}: ChartComponentProps & {
  loading?: boolean;
}) => {
  if (loading) {
    return <CircularProgress />;
  }
  return <Pie width={300} height={300} {...otherProps} />;
};

export default PieChart;
