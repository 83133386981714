import { Typography } from '@mui/material';
import React from 'react';
import {
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  useRecordContext,
} from 'react-admin';

const BanTypeField = () => {
  const record = useRecordContext();
  return (
    <Typography style={{ fontSize: 14 }}>
      {record.end_date ? 'Temporaire' : 'Définitif'}
    </Typography>
  );
};

const EndDateField = (props) => {
  const record = useRecordContext();
  if (record.end_date)
    return <DateField {...props} source="end_date" showTime />;
  return <Typography style={{ fontSize: 14 }}>&#8734;</Typography>;
};

const BanHistoryDataGrid = (props: any) => {
  return (
    <ArrayField {...props} source="ban_histo">
      <Datagrid rowClick="show">
        <BanTypeField label="Ban type" />
        <DateField source="date" showTime />
        <EndDateField label="End date" />
        <TextField source="referee" />
        <TextField source="reason" />
        <TextField source="ban_desc" />
      </Datagrid>
    </ArrayField>
  );
};

export default BanHistoryDataGrid;
