import NumberCard from 'components/data/NumberCard';

import { useEffect, useState } from 'react';
import firestore from 'services/firestore';

const PendingWinnerCard = () => {
  const [number, setNumber] = useState<number | false>(false);
  useEffect(() => {
    (async () => {
      const result = await firestore()
        .collection('winner')
        .where('state', '==', 'PENDING')
        .get();
      setNumber(result.size);
    })();
  }, []);
  return <NumberCard title={'Pending lot requests'} number={number} href="#/winner" />;
};

export default PendingWinnerCard;
