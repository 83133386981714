import {
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import firestore from 'services/firestore';
import { subHours } from 'date-fns';
import dateUtils from 'utils/date';
import { LogIdentifier } from 'core/types';

const MultiAccountCard = ({ multiAccounts, isReady }: any) => {
  return (
    <Card>
      <CardContent>
        {!isReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color={multiAccounts.length === 0 ? 'primary' : 'secondary'}>
              Multi-Comptes
            </Typography>
            {multiAccounts.length === 0 ? (
              <CheckCircle
                style={{ color: 'green', fontSize: 32, marginTop: 24 }}
              />
            ) : (
              <List>
                {multiAccounts.map((logId: any, index: any) => {
                  return (
                    <ListItem key={logId.id || index}>
                      <ListItemText
                        primary={
                          <a href={`#/player/${logId.player_id}/show`}>
                            {logId.player_boy_id}
                          </a>
                        }
                        secondary={`${logId.type} : ${logId.value}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MultiAccountCard;
