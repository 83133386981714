import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const RatioNightHoursMatchsCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalulIsReady] = useState(false);
  const [ratioNightHours, setratioNightHours] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      let matchesCount = matchHistoryList.length;
      let nightHoursCount = matchHistoryList.filter(
        (ma) => ma.night_hour === true,
      ).length;
      let ratio = (nightHoursCount * 100) / matchesCount;
      setratioNightHours(`${ratio.toFixed(2)}%`);
      setCalulIsReady(true);
    })();
  }, []);

  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">Matchs entre 1h et 10h</Typography>
            <p>{ratioNightHours}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RatioNightHoursMatchsCard;
