import { InputProps, useListContext } from 'react-admin';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useEffect, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { setTimeout } from 'timers';

const useStyles = makeStyles()({
  input: {
    paddingTop: 10,
    paddingBottom: 11,
  },
});
const PlayerFilter = ({}: InputProps) => {
  const { setFilters, filterValues } = useListContext();

  const { classes } = useStyles();

  const [currentIdentifierValue, setCurrentIdentifierValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIdentifierValue.length > 0) {
        setFilters(
          {
            ...filterValues,
            player_nickname: currentIdentifierValue,
          },
          {},
        );
      } else {
        setFilters(
          {
            ...filterValues,
            player_nickname: undefined,
          },
          {},
        );
      }

      return () => clearTimeout(timer);
    }, 300);
  }, [currentIdentifierValue]);

  return (
    <div>
      <TextField
        value={currentIdentifierValue}
        onChange={(e) => {
          setCurrentIdentifierValue(e.target.value);
        }}
        onKeyPress={({ nativeEvent }) => {
          if (nativeEvent.key === 'Enter') {
            setFilters(
              {
                player_nickname: currentIdentifierValue,
              },
              {},
            );
          }
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },

          endAdornment: currentIdentifierValue ? (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setCurrentIdentifierValue('')}
              size="large">
              <ClearIcon />
            </IconButton>
          ) : (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        id="standard-basic"
        placeholder="Search by BOY Nickname"
        variant="filled"
      />
    </div>
  );
};

export default PlayerFilter;
