import NumberCard from 'components/data/NumberCard';

import { useEffect, useState } from 'react';
import firestore from 'services/firestore';

const MatchInProgressCard = () => {
  const [number, setNumber] = useState<number | false>(false);
  useEffect(() => {
    (async () => {
      const result = await firestore()
        .collection('match')
        .where('state', '==', 'PLAYING')
        .get();

      setNumber(result.size);
    })();
  }, []);
  return <NumberCard title={'Matches in progress'} number={number} />;
};

export default MatchInProgressCard;
