import {
  Badge,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Device } from 'core/types';
import React, { useEffect, useState } from 'react';
import BoyAPI from 'services/boy-api';

const useStyles = makeStyles()({
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
});

const DevicesDataGrid = ({ playerId }) => {
  const { classes } = useStyles();
  const [data, setData] = useState<Device[]>([]);
  const columns = [
    {
      field: 'uuid',
      headerName: 'Device UUID',
    },
    {
      field: 'rooted',
      headerName: 'Security',
      render({ value }) {
        if (value) {
          return <Chip color="secondary" label="Device rooted" />;
        }
        return (
          <Chip
            color="primary"
            label="Device can be trusted"
            style={{ backgroundColor: 'green', color: 'white' }}
          />
        );
      },
    },
    {
      field: 'linked_to_accounts',
      headerName: 'Multi accounts with',
      render({ value }) {
        return value.join(', ');
      },
    },
    {
      field: 'last_used_at',
      headerName: 'Last used at',
    },
  ];

  useEffect(() => {
    (async () => {
      const response = await BoyAPI.Player.getDevices(playerId);
      setData(response.data);
    })();
  }, []);
  return (
    <>
      <Typography color="textSecondary" align="left" className={classes.title}>
        Devices
      </Typography>
      <Table>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.field}>{column.headerName}</TableCell>
          ))}
        </TableRow>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.uuid}>
              {columns.map((column) => (
                <TableCell>
                  {column.render
                    ? column.render({ value: row[column.field] })
                    : row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DevicesDataGrid;
