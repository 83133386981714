import {
  Button,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  BooleanField,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import BoyAPI from 'services/boy-api';
import {
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ButtonUI from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import { Permission, Player, Winner } from 'core/types';
import { useEffect, useState } from 'react';

import { themeMui } from 'core/theme';
import useCurrentPlayer from 'hooks/use-current-player';
import { useParams } from 'react-router-dom';
import { RemoveCircleOutline, RestoreOutlined } from '@mui/icons-material';

const WinnerImageField = () => {
  const record = useRecordContext();
  return (
    <>
      {/*console.log(record)*/}
      <img
        src={record.lot_img_urls && record.lot_img_urls[0]}
        style={{ height: 300, paddingRight: 16 }}
        alt=""
      />
    </>
  );
};
const PlayerField = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [player, setPlayer] = useState<Player | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const player = await dataProvider.getOne('player', {
        id: record.player_id,
      });
      setPlayer(player.data as Player);
    })();
  }, [record.player_id, dataProvider]);
  return (
    <div style={{ marginTop: 8, marginBottom: 4 }}>
      <InputLabel style={{ fontSize: 12 }}>Player Id</InputLabel>
      <div style={{ padding: '8px 0 4px' }}>
        <a
          href={`#/player/${record.player_id}/show`}
          style={{
            textDecoration: 'none',
            display: 'flex',
            color: 'inherit',
            flexDirection: 'row',
          }}>
          <Typography
            style={{
              fontSize: '0.875rem',
              color: themeMui.palette.primary.main,
            }}>
            {record.player_boy_id}
          </Typography>
          <LinkIcon color="primary" />
        </a>
        {player?.id_v1 && (
          <Typography style={{ fontSize: '0.75rem', opacity: 0.7 }}>
            (Id v1: {player.id_v1})
          </Typography>
        )}
      </div>
    </div>
  );
};
const WinnerActions = ({ data }: any) => {
  const { classes } = useStyles();

  const redirect = useRedirect();
  const notify = useNotify();
  const { player } = useCurrentPlayer();
  const refresh = useRefresh();
  const [reason, setReason] = useState<string>('MISSING_DATA');
  const [code, setCode] = useState<string>('');
  const [banType, setBanType] = useState<string>('BAN_3_DAYS');
  const [banReason, setBanReason] = useState<string>('ban_cheat');
  const [impliedAccounts, setImpliedAccounts] = useState<string | undefined>(
    undefined,
  );
  const [showFormBan, setShowFormBan] = useState(false);
  const [showFormRefund, setShowFormRefund] = useState(false);
  const [showFormWinner, setShowFormWinner] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeRefundForm = () => {
    setShowFormRefund(false);
  };

  const confirmRefundForm = async () => {
    setLoading(true);
    try {
      BoyAPI.Winner.refund(data.id, reason);
    } catch (err) {
      notify(
        'Refund error: ' + error.response.data.error ||
          error.response.data.detail,
        { type: error.response.status >= 500 ? 'error' : 'warning' },
      );
      setLoading(false);
      setShowFormRefund(false);
      return;
    }
    setTimeout(function () {
      setLoading(false);
      redirect('/winner');
      notify('Winner refunded', { type: 'success' });
      refresh();
      setShowFormRefund(false);
    }, 2000);
  };

  const closeWinnerForm = () => {
    setShowFormWinner(false);
  };

  const confirmWinnerForm = async () => {
    setLoading(true);
    try {
      await BoyAPI.Winner.confirm(data.id, code);
    } catch (error) {
      notify(
        'Confirm error: ' + error.response.data.error ||
          error.response.data.detail,
        { type: error.response.status >= 500 ? 'error' : 'warning' },
      );
      setLoading(false);
      setShowFormWinner(false);
      return;
    }
    setTimeout(function () {
      setLoading(false);
      redirect('/winner');
      notify('Winner confirmed', { type: 'success' });
      refresh();
      setShowFormWinner(false);
    }, 2000);
  };

  const closeBanForm = () => {
    setShowFormBan(false);
  };

  const confirmBanForm = async () => {
    setLoading(true);
    try {
      await BoyAPI.Player.ban(
        data.player_id,
        banType,
        banReason,
        impliedAccounts,
      );
    } catch (err) {
      notify(
        'Ban error: ' + err.response.data.error || err.response.data.detail,
        { type: err.response.status >= 500 ? 'error' : 'warning' },
      );
      setLoading(false);
      setShowFormBan(false);
      return;
    }
    setTimeout(function () {
      setLoading(false);
      redirect('/winner');
      notify('Winner banned', { type: 'success' });
      refresh();
      setShowFormBan(false);
    }, 2000);
  };

  if (data?.state !== 'PENDING') {
    return null;
  }
  return (
    <TopToolbar>
      <>
        <Button
          startIcon={<RestoreOutlined />}
          color="primary"
          onClick={() => {
            setShowFormRefund(true);
          }}
          label="Refund Winner"></Button>
        <Dialog
          open={showFormRefund}
          onClose={closeRefundForm}
          aria-labelledby="form-dialog-title"
          maxWidth={'xs'}
          fullWidth={true}>
          <Box className={classes.dialogSpacing}>
            <DialogTitle id="form-dialog-title">Refund form</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please select the cause of the refund
              </DialogContentText>
              <InputLabel style={{ fontSize: 12 }}>Reason</InputLabel>
              <Select
                labelId="reasonInput"
                id="refundReasonInput"
                value={reason}
                onChange={(event: any) => setReason(event.target.value)}>
                <MenuItem value={'MISSING_DATA'}>BAD_OR_MISSING_DATA</MenuItem>
                <MenuItem value={'MISSING_STOCK'}>MISSING_STOCK</MenuItem>
                <MenuItem value={'SUSPECTED_PLAYER'}>SUSPECTED_PLAYER</MenuItem>
                <MenuItem value={'ALREADY_ORDERED_THIS_WEEK'}>
                  Player already ordered this lot during the week
                </MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              {!loading ? (
                <>
                  <ButtonUI onClick={closeRefundForm} color="secondary">
                    Cancel
                  </ButtonUI>
                  <ButtonUI onClick={confirmRefundForm} color="primary">
                    Refund
                  </ButtonUI>
                </>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
      <>
        <Button
          startIcon={<RemoveCircleOutline />}
          color="secondary"
          onClick={() => {
            setShowFormBan(true);
          }}
          label="Ban Winner"></Button>
        <Dialog
          open={showFormBan}
          onClose={closeBanForm}
          aria-labelledby="form-dialog-title">
          <Box className={classes.dialogSpacing}>
            <DialogTitle id="form-dialog-title">Ban Form</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Write reason and duration of ban
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              <InputLabel style={{ fontSize: 12 }}>Ban type</InputLabel>
              <Select
                labelId="banType"
                id="banTypeInput"
                value={banType}
                onChange={(event: any) => setBanType(event.target.value)}>
                <MenuItem value={'BAN_DEF'}>BAN_DEF</MenuItem>
                <MenuItem value={'BAN_3_DAYS'}>BAN_3_DAYS</MenuItem>
                <MenuItem value={'BAN_7_DAYS'}>BAN_7_DAYS</MenuItem>
                <MenuItem value={'BAN_30_DAYS'}>BAN_30_DAYS</MenuItem>
              </Select>
            </DialogContent>
            <DialogContent>
              <InputLabel style={{ fontSize: 12 }}>Ban reason</InputLabel>
              <Select
                labelId="banReason"
                id="banReasonInput"
                value={banReason}
                onChange={(event: any) => setBanReason(event.target.value)}>
                <MenuItem value={'ban_cheat'}>BAN_CHEAT</MenuItem>
                <MenuItem value={'ban_multi_account'}>
                  BAN_MULTI_ACCOUNT
                </MenuItem>
              </Select>
            </DialogContent>
            <DialogContent>
              <InputLabel style={{ fontSize: 12 }}>
                Comptes impliqués
              </InputLabel>
              <Input
                id="impliedAccountsInput"
                value={impliedAccounts}
                onChange={(event: any) =>
                  setImpliedAccounts(event.target.value)
                }
              />
            </DialogContent>
            <DialogActions>
              {!loading ? (
                <>
                  <ButtonUI onClick={closeBanForm} color="secondary">
                    Cancel
                  </ButtonUI>
                  <ButtonUI onClick={confirmBanForm} color="primary">
                    Ban Player
                  </ButtonUI>
                </>
              ) : (
                <CircularProgress />
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
      {player?.is_admin ||
      player?.permissions?.includes(Permission.ValidateLots) ? (
        <>
          <Button
            startIcon={<CheckIcon />}
            color="primary"
            onClick={() => {
              setShowFormWinner(true);
            }}
            label="Confirm Winner"></Button>
          <Dialog
            open={showFormWinner}
            onClose={closeWinnerForm}
            aria-labelledby="form-dialog-title">
            <Box className={classes.dialogSpacing}>
              <DialogTitle id="form-dialog-title">Confirm lot</DialogTitle>
              <DialogContent>
                {data?.need_admin_action && (
                  <>
                    <DialogContentText>
                      Enter the validation code
                    </DialogContentText>
                    <Typography style={{ fontSize: '0.75rem', opacity: 0.7 }}>
                      Code :
                    </Typography>
                    <Input
                      id="codeInput"
                      value={code}
                      onChange={(event: any) => setCode(event.target.value)}
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                {!loading ? (
                  <>
                    <ButtonUI onClick={closeWinnerForm} color="secondary">
                      Cancel
                    </ButtonUI>
                    <ButtonUI onClick={confirmWinnerForm} color="primary">
                      Confirm
                    </ButtonUI>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </DialogActions>
            </Box>
          </Dialog>
        </>
      ) : null}
    </TopToolbar>
  );
};
const useStyles = makeStyles()(() => ({
  dialogSpacing: {
    margin: '10px',
    minWidth: 300,
  },
}));

const AddressField = () => {
  const record = useRecordContext();
  return (
    <div style={{ marginTop: 8, marginBottom: 4 }}>
      <InputLabel style={{ fontSize: 12 }}>Address</InputLabel>
      <Typography variant="body2">{record.address || record.adress}</Typography>
    </div>
  );
};
const WinnerDetail = (props: ShowProps & { winner: Winner }) => {
  const { classes } = useStyles();

  return (
    <Show
      {...props}
      actions={<WinnerActions data={props.winner} />}
      sx={{
        margin: '16px',
        [`@media (min-width: ${themeMui.breakpoints.values.md}px)`]: {
          margin: 0,
        },
      }}>
      <SimpleShowLayout
        sx={{
          '& .RaSimpleShowLayout-stack': {
            [`@media (min-width: ${themeMui.breakpoints.values.md}px)`]: {
              height: 300,
              display: 'flex',
              flexWrap: 'wrap',
            },
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
          },
        }}>
        <WinnerImageField />
        <TextField source="lot_id" />
        <TextField source="lot_value" />
        <BooleanField source="is_premium" looseValue={true} label="premium" />
        <BooleanField source="is_raffle" looseValue={true} label="raffle" />
        <TextField source="price" />
        <TextField source="currency" />
        <TextField source="group" />
        <PlayerField source="player_id" />
        <TextField source="nationality" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <TextField source="mail" />
        <TextField source="phone_number" />
        {/* @ts-ignore */}
        {props.winner?.crypto_wallet_id && (
          <TextField source="crypto_wallet_id" />
        )}
        <AddressField source="address" />
        <TextField source="city" />
        <TextField source="postal_code" />
        <TextField source="color" />
        <TextField source="size" />
        <TextField source="country" label="Shop" />
      </SimpleShowLayout>
    </Show>
  );
};
const ParentWinnerDetail = (props: any) => {
  const { id } = useParams();
  const { data, isLoading } = useGetOne('winner', { id });
  //console.log('isLoading');

  return <WinnerDetail {...props} winner={data} />;
};

export default ParentWinnerDetail;
