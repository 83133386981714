// in src/Menu.js
import * as React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  CircularProgress,
  ListItemButton,
} from '@mui/material';
import {
  DashboardMenuItem,
  MenuItemLink,
  useRedirect,
  useSidebarState,
  useResourceDefinitions,
} from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import SystemUpdate from '@mui/icons-material/SystemUpdate';
import useCurrentPlayer from 'hooks/use-current-player';
import { makeStyles } from 'tss-react/mui';
import { Storefront } from '@mui/icons-material';

const useStyles = makeStyles()({
  icon: {
    minWidth: 40,
  },
  nestedText: {
    fontSize: '0.875rem',
  },
  menuItem: {
    minHeight: 'auto',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
});
const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const [open] = useSidebarState();

  const resources = useResourceDefinitions();

  const [expanded, setExpanded] = React.useState(false);
  const redirect = useRedirect();
  const { classes } = useStyles();
  const handleClick = () => {
    setExpanded((e) => !e);
  };
  const { loading, player } = useCurrentPlayer();

  if (loading) {
    return (
      <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <ListItemButton
        className={classes.menuItem}
        onClick={() => {
          redirect('/shop');
        }}>
        <ListItemIcon className={classes.icon}>
          <Storefront />
        </ListItemIcon>
        <ListItemText
          primary="Shop"
          classes={{ primary: classes.nestedText }}
        />
      </ListItemButton>
      {Object.values(resources).map(
        (resource) =>
          !resource.options.hidden && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ),
      )}

      {isXSmall && logout}
      <ListItem button onClick={handleClick} className={classes.menuItem}>
        <ListItemIcon className={classes.icon}>
          <DefaultIcon />
        </ListItemIcon>
        <ListItemText primary="Others" />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {player?.is_admin ? (
            <ListItem
              button
              className={classes.menuItem}
              onClick={() => {
                redirect('/PlayersRanking');
              }}>
              <ListItemIcon className={classes.icon}>
                <StarBorder />
              </ListItemIcon>
              <ListItemText
                primary="Player Rankings"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              className={classes.menuItem}
              onClick={() => {
                redirect('/WaitDuration');
              }}>
              <ListItemIcon className={classes.icon}>
                <StarBorder />
              </ListItemIcon>
              <ListItemText
                primary="Wait Duration"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          <ListItem
            className={classes.menuItem}
            button
            onClick={() => {
              redirect('/ModoRanking');
            }}>
            <ListItemIcon className={classes.icon}>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Moderator Rankings"
              classes={{ primary: classes.nestedText }}
            />
          </ListItem>
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/ReferentialImport');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Imports"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/Notification');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Notification"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/NotificationStat');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Stats Notification"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/SocialStat');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Stats Social"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/ReferralStat');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Stats Parrainage"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/InAppStat');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Stats in-app game"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
          {player?.is_admin ? (
            <ListItem
              button
              onClick={() => {
                redirect('/MatchDistribution');
              }}
              className={classes.menuItem}>
              <ListItemIcon className={classes.icon}>
                <SystemUpdate />
              </ListItemIcon>
              <ListItemText
                primary="Match distribution"
                classes={{ primary: classes.nestedText }}
              />
            </ListItem>
          ) : null}
        </List>
      </Collapse>
    </div>
  );
};

export default Menu;
