import Axios, { AxiosRequestConfig } from 'axios';
import { firebase } from 'services/firestore';

const authFetch = async <T>(options: AxiosRequestConfig) => {
  const newToken = await firebase.auth().currentUser?.getIdToken(false);
  if (!newToken) {
    console.error('Cannot get new token. User is logged out ?');
  }
  return Axios.request<T>({
    ...options,
    headers: {
      Authorization: `Bearer ${newToken}`,
      ...options.headers,
    },
  });
};

export { authFetch };
