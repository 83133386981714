import { InputLabel, Typography, useMediaQuery, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  DateField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  Toolbar,
  useShowController,
  ShowControllerProps,
  useRecordContext,
} from 'react-admin';
import LinkIcon from '@mui/icons-material/Link';
import { useEffect, useState } from 'react';
import { firebase } from 'services/firestore';
import BoyAPI from 'services/boy-api';
import { useNotify, useRedirect } from 'react-admin';
import RefreshIcon from '@mui/icons-material/Refresh';

const ProofImageField = () => {
  const record = useRecordContext();
  const [url, setUrl] = useState();

  const { classes } = useStyles();

  useEffect(() => {
    (async () => {
      const downloadUrl = await firebase
        .storage()
        .ref(record.proof)
        .getDownloadURL();
      setUrl(downloadUrl);
    })();
  }, [record.proof]);
  if (!url) {
    return null;
  }
  return <img src={url} className={classes.image} alt="evidence-review-img" />;
};

const PlayerField = () => {
  const record = useRecordContext();
  return (
    <>
      <InputLabel style={{ fontSize: 12 }}>Player Id</InputLabel>
      <a
        href={`#/player/${record.player_id}/show`}
        style={{
          textDecoration: 'none',
          display: 'flex',
          color: 'inherit',
          flexDirection: 'row',
        }}>
        <Typography style={{ fontSize: '0.875rem' }}>
          {record.player_boy_id}
        </Typography>{' '}
        <LinkIcon />
      </a>
    </>
  );
};

const ReviewActions = ({ data }: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { classes } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const goToNextAvailableReview = async () => {
    const review = await BoyAPI.Review.getOldestReview();

    if (!review?.id) {
      notify('No more review to moderate');
      redirect('/');
    } else redirect(`/review/${review.id}/show`);
  };

  const onRefuse = async () => {
    setIsLoading(true);
    try {
      await BoyAPI.Review.refuseReview(data.id);
    } catch (e) {
      setIsLoading(false);
      notify('Unknown error');
      return;
    }
    setIsLoading(false);
    goToNextAvailableReview();
  };

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await BoyAPI.Review.validateReview(data.id);
    } catch (e) {
      //console.log(e);
      setIsLoading(false);
      notify('Unknown error');
      return;
    }
    setIsLoading(false);
    goToNextAvailableReview();
  };

  return (
    <div className={classes.buttonContainer}>
      <Button
        color="secondary"
        disabled={isLoading}
        classes={{ root: classes.buttonRoot }}
        onClick={onRefuse}>
        Refuse Review
      </Button>
      <Button
        color="primary"
        disabled={isLoading}
        classes={{ root: classes.buttonRoot }}
        onClick={onConfirm}>
        Confirm Review
      </Button>
    </div>
  );
};

const ReviewToolbar = ({ data }: any) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (!matches)
    return (
      <Toolbar>
        <ReviewActions data={data} />
      </Toolbar>
    );

  return (
    <TopToolbar>
      <ReviewActions data={data} />
    </TopToolbar>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    margin: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      margin: 0,
    },
  },
  cardContent: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      height: 550,
      display: 'flex',
      flexWrap: 'wrap',
    },
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  image: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      width: 'auto',
      height: 500,
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonRoot: {
    width: '50%',
  },
}));

const ReviewDetail = (props: ShowProps) => {
  const { classes } = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const { record }: ShowControllerProps = useShowController(props);

  const goToNextAvailableReview = async () => {
    const review = await BoyAPI.Review.getOldestReview();

    if (!review?.id) {
      notify('Plus de review à modérer');
      redirect('/');
    } else redirect(`/review/${review.id}/show`);
  };

  const cantBeConfirmed =
    record && record?.state !== 'MODERATING' && record?.state !== 'PENDING';

  return (
    <Show
      {...props}
      classes={{ card: classes.card }}
      actions={cantBeConfirmed ? <div /> : <ReviewToolbar data={record} />}>
      <SimpleShowLayout className={classes.cardContent}>
        <TopToolbar>
          <Button
            color="primary"
            onClick={goToNextAvailableReview}
            startIcon={<RefreshIcon fontSize="small" />}>
            Go to the next review
          </Button>
        </TopToolbar>
        <ProofImageField />
        <TextField source="bonus_id" />
        <PlayerField />
        <DateField source="ask_date" showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReviewDetail;
