import { useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import RAList from 'components/common/RAList';
import { useMemo } from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  NumberInput,
  SearchInput,
  SimpleList,
  TextField,
} from 'react-admin';
import dateUtils from 'utils/date';
import GameIdFilter from './GameIdFilter';
import PlayerBoyIdFilter from './PlayerBoyIdFilter';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
}));

export const PlayersList = (props: any) => {
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  

  const datagrid = useMemo(() => {
    if (matches) {
      return (
        <Datagrid rowClick="show" optimized>
          <TextField source="boy_id" label="BOY ID" />
          <TextField source="nickname" label="Nickname" />
          <TextField source="golds" />
          <TextField source="points" />
          <TextField source="gems" />
          <TextField source="phone_number" />
          <DateField source="birth_date" />
          <DateField source="subscription_date" />
          <TextField source="language" />
          <TextField source="nationality" />
          <TextField source="id" />
        </Datagrid>
      );
    }
    return (
      <SimpleList
        linkType={'show'}
        primaryText={(record) => record.nickname}
        secondaryText={(record) => record.phone_number}
        tertiaryText={(record) =>
          dateUtils.localizedFormat(new Date(record.subscription_date), 'Ppp')
        }
      />
    );
  }, [matches]);
  return (
    <RAList
    disableSyncWithLocation
      className={classes.root}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      total={10}
      {...props}
      filters={
        <Filter>
          <PlayerBoyIdFilter source="boy_id_lower" alwaysOn />
          <GameIdFilter source="identifier" alwaysOn />
          <SearchInput
            source="id"
            placeholder="Search by ID"
            resettable
            alwaysOn={matches}
          />
          <NumberInput
            source="phone_number"
            label="Phone number"
            resettable
            hiddenLabel
            InputLabelProps={{ style: { opacity: 0 } }}
            placeholder="Search by phone number"
          />
          <SearchInput source="language" placeholder="Search by language" />
          <SearchInput
            source="nationality"
            placeholder="Search by nationality"
          />
          <BooleanInput source="is_admin" placeholder="Admin" />
          <BooleanInput source="is_moderator" placeholder="Moderator" />
        </Filter>
      }>
      {datagrid}
    </RAList>
  );
};

export default PlayersList;
