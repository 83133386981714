import { css, TextField } from '@mui/material';
import { Game } from 'core/types';
import { useEffect, useState } from 'react';
import {
  DateTimeInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  Labeled,
  Toolbar,
  SaveButton,
  useNotify,
} from 'react-admin';
import { useController } from 'react-hook-form';
import BoyAPI from 'services/boy-api';

const RewardRepartitionInput = () => {
  const inputController = useController({ name: 'reward_repartition' });
  return (
    <>
      <Labeled
        label="Reward Repartition"
        sx={{
          '& .RaLabeled-label': css`
            align-self: flex-start;
            margin-left: 12px;
          `,
        }}>
        <TextField
          {...inputController.field}
          error={!!inputController.fieldState.error?.message}
          helperText={inputController.fieldState.error?.message}
          value={
            typeof inputController.field.value === 'string'
              ? inputController.field.value
              : JSON.stringify(inputController.field.value)
          }
          multiline
          style={{
            minWidth: '400px',
          }}
        />
      </Labeled>
    </>
  );
};

const TournamentEdit = (props: any) => {
  const notify = useNotify();
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const _games = await BoyAPI.Games.list();
        setGames(_games.data);
      } catch (error) {
        notify(
          error.response.data.error ||
            error.response.data.detail ||
            'Could not retrieve games: unkow reson',
          {
            type: error.response.status >= 500 ? 'error' : 'warning',
          },
        );
      }
    })();
  }, [notify]);

  return (
    <Edit {...props}>
      <SimpleForm
        redirect="show"
        warnWhenUnsavedChanges
        validate={(values) => {
          const errors: any = {};
          try {
            if (typeof values.reward_repartition === 'string') {
              JSON.parse(values.reward_repartition);
            }
          } catch (e) {
            errors.reward_repartition =
              'Input is invalid. Please provide a correct JSON, to verify: https://jsonlint.com/';
            //console.log(e);
          }
          return errors;
        }}
        toolbar={
          <Toolbar>
            <SaveButton
              type="button"
              label="Save"
              transform={(data) => {
                return {
                  ...data,
                  reward_repartition:
                    typeof data.reward_repartition === 'string'
                      ? JSON.parse(data.reward_repartition)
                      : data.reward_repartition,
                };
              }}
            />
          </Toolbar>
        }>
        <SelectInput
          source="game_id"
          label="Game Id"
          choices={games.map((game) => {
            return { id: game.ref, name: game.name };
          })}
        />
        <NumberInput source="reward_pool" label="Reward Pool" />
        <DateTimeInput source="end_at" label="End At" />
        <RewardRepartitionInput />
      </SimpleForm>
    </Edit>
  );
};

export default TournamentEdit;
