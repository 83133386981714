import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

const localizedFormat = (date: Date, formatStr: string) => {
  return format(date, formatStr, {
    locale: fr,
  });
};

const dateUtils = { localizedFormat };

export default dateUtils;
