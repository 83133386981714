import {
  Select,
  MenuItem,
  AlertColor,
  ButtonGroup,
  Card,
  CardContent,
  Button,
  FormGroup,
  FormHelperText,
  TextField,
  Checkbox,
} from '@mui/material';
import { OrderStore } from 'core/types';
import { useEffect, useState } from 'react';

import BoyAPI from 'services/boy-api';

export type Error = {
  severity: AlertColor;
  error: string;
  key?: string;
};

export type Notif = {
  level: AlertColor;
  message: string;
};

export type InnerViewProps = {
  playerId: string;
  notify: (n: Notif) => void;
  onError: (e: Error) => void;
};

const Tools = {
  navActions(
    setPage: (page: number) => void,
    currentPage: number,
    nbPage: number,
  ): (subProps: any) => any {
    return (subProps: any) => {
      const {
        page,
        count,
        rowsPerPage,
        backIconButtonProps,
        nextIconButtonProps,
        showLastButton,
        getItemAriaLabel,
        showFirstButton,
        onPageChange,
        ...restSubProps
      } = subProps;
      return (
        <>
          <div
            style={{ margin: '0 10px', flexDirection: 'row', display: 'flex' }}>
            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{ flex: '0.3 0.1 auto' }}>
              <Button
                onClick={() => {
                  setPage(1);
                }}
                disabled={currentPage === 1}>
                {'<<'}
              </Button>
              <Button
                onClick={() => {
                  setPage(currentPage - 1);
                }}
                disabled={currentPage === 1}>
                {'<'}
              </Button>
            </ButtonGroup>
            <Select
              size="small"
              onChange={(e: any) => {
                e?.preventDefault();
                setPage(e.target.value);
              }}
              value={currentPage}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 360 } },
              }}>
              {[...Array(nbPage).keys()].map((i) => {
                return (
                  <MenuItem id={i} key={i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                );
              })}
            </Select>
            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{ flex: '0.3 0.1 auto' }}>
              <Button disabled>/{nbPage}</Button>
              <Button
                onClick={() => {
                  setPage(currentPage + 1);
                }}
                disabled={currentPage === nbPage}>
                {'>'}
              </Button>
              <Button
                onClick={() => {
                  setPage(nbPage);
                }}
                disabled={currentPage === nbPage}>
                {'>>'}
              </Button>
            </ButtonGroup>
          </div>
        </>
      );
    };
  },
  addOrder(
    orderBy: OrderStore | undefined,
    setOrderBy: (OrderStore) => void,
  ): (key: string) => void {
    return (key: string) => {
      let newOrderBy = { ...orderBy } || {};
      newOrderBy[key] = key in newOrderBy ? !newOrderBy[key] : true;
      setOrderBy(newOrderBy);
    };
  },
  removeOrder(
    orderBy: OrderStore | undefined,
    setOrderBy: (OrderStore) => void,
  ): (key: string) => void {
    return (key: string) => {
      if (!orderBy || !(key in orderBy)) {
        return;
      }

      let newOrderBy = { ...orderBy };
      delete newOrderBy[key];
      setOrderBy(newOrderBy);
    };
  },
};

type AddTransactionFormProps = {
  playerId: string;
  isPremiumBet?: boolean;
  requester: any;
  onSuccess: (bet?: number, isFree?: boolean) => void;
  onCancel: () => void;
  notify: (notif: Notif) => void;
  onError: (error: Error) => void;
};

const AddTransactionForm = ({
  playerId,
  isPremiumBet,
  requester,
  onSuccess,
  onCancel,
  notify,
  onError,
}: AddTransactionFormProps) => {
  const [amount, setAmount] = useState<number | null>(null);
  const [betKind, setBetKind] = useState<number | undefined>(undefined);
  const [isFreeBet, setIsFreeBet] = useState<boolean>(false);
  const [reason, setTransactionReason] = useState<string | null>(null);

  const [availablePremiumBets, setAvailablePremiumBets] = useState<number[]>(
    [],
  );
  const [newTransactionNotification, setTransactionNotification] = useState<
    string | undefined
  >(undefined);

  const reset = () => {
    setTransactionNotification(undefined);
    setBetKind(undefined);
    setTransactionReason(null);
    setAmount(null);
    setIsFreeBet(false);
  };

  const submitTransaction = async () => {
    if (!amount) {
      notify({
        level: 'info',
        message: 'Amount required to create',
      });
      return;
    }

    if (!reason) {
      notify({
        level: 'info',
        message: 'Reason required to create',
      });
      return;
    }

    if (isPremiumBet && !betKind) {
      notify({
        level: 'info',
        message: 'Bet kind required to create',
      });
      return;
    }

    try {
      if (isPremiumBet) {
        await requester(
          playerId,
          amount,
          betKind,
          isFreeBet,
          reason,
          newTransactionNotification,
        );
      } else {
        await requester(playerId, amount, reason, newTransactionNotification);
      }
    } catch (error) {
      onError({
        severity: error.response.status >= 500 ? 'error' : 'warning',
        error: error.response.data.error || error.response.data.detail,
        key: error.response.data.key,
      });

      return;
    }

    onSuccess(betKind, isFreeBet);
    reset();
  };

  useEffect(() => {
    (async () => {
      if (!isPremiumBet) {
        return;
      }
      try {
        let res = await BoyAPI.Bets.list_premiums();
        setAvailablePremiumBets(
          res.data.map((val) => {
            return val.value;
          }),
        );
      } catch (error) {
        onError({
          severity: error.response.status >= 500 ? 'error' : 'warning',
          error: error.response.data.error || error.response.data.detail,
          key: error.response.data.key,
        });

        return;
      }
    })();
  }, [isPremiumBet, onError]);

  return (
    <Card>
      <CardContent>
        <FormGroup>
          <FormHelperText>Amount*</FormHelperText>
          <TextField
            id="new_transaction_amount"
            type="number"
            required
            value={amount}
            onChange={(event: any) => setAmount(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormHelperText>Reason*</FormHelperText>
          <TextField
            id="new_transaction_reason"
            type="text"
            required
            value={reason}
            onChange={(event: any) => setTransactionReason(event.target.value)}
          />
        </FormGroup>
        {isPremiumBet && (
          <FormGroup row>
            <FormGroup>
              <FormHelperText>Bet*</FormHelperText>
              <Select
                id="bet_value"
                required
                value={betKind}
                onChange={(event: any) => setBetKind(event.target.value)}>
                {availablePremiumBets.map((val, idx) => {
                  return (
                    <MenuItem id={idx} key={val} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormGroup>
            <FormGroup>
              <FormHelperText>IsFree</FormHelperText>
              <Checkbox
                value={isFreeBet}
                onClick={() => setIsFreeBet(!isFreeBet)}
              />
            </FormGroup>
          </FormGroup>
        )}
        {/* <FormGroup>
          <FormHelperText>Notify with message</FormHelperText>
          <TextField
            id="new_transaction_notification"
            type="text"
            value={newTransactionNotification}
            onChange={(event: any) =>
              setTransactionNotification(event.target.value)
            }
          />
        </FormGroup> */}
        <FormGroup sx={{ textAlign: 'center', margin: '10px 0 0' }}>
          <ButtonGroup sx={{ justifyContent: 'center' }}>
            <Button
              disabled={!amount || !reason || (isPremiumBet && !betKind)}
              onClick={submitTransaction}>
              Create
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                reset();
                onCancel();
              }}>
              Cancel
            </Button>
          </ButtonGroup>
        </FormGroup>
      </CardContent>
    </Card>
  );
};

const Common = {
  Tools,
  AddTransactionForm,
};

export default Common;
