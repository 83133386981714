import {
  Badge,
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { SuspiciousBehavior } from 'core/types';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import BoyAPI from 'services/boy-api';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles()({
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
});

const Row = ({ data }) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);

  return (
    <>
      <TableRow key={data.game_id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setCollapsibleOpen(!collapsibleOpen)}>
            {collapsibleOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <img src={data.logo_url} alt="" width="60px" />
          <span style={{ marginLeft: 10 }}>{data.game_id}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapsibleOpen} timeout="auto" unmountOnExit>
            {data.data?.scores_on_time ? (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Matchs scores other time
                </Typography>
                <Typography
                  fontStyle="italic"
                  variant="subtitle1"
                  gutterBottom
                  component="div">
                  Abnormally high scores per minute
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Score</TableCell>
                      <TableCell>Duration (seconds)</TableCell>
                      <TableCell>Score / minutes</TableCell>
                      <TableCell>Expected lower / minutes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.scores_on_time.map((score) => (
                      <TableRow key={score.at}>
                        <TableCell component="th" scope="row">
                          {score.at}
                        </TableCell>
                        <TableCell>{score.score}</TableCell>
                        <TableCell>{score.duration}</TableCell>
                        <TableCell>{score.score_per_seconds * 60}</TableCell>
                        <TableCell>
                          {score.threshold ? score.threshold * 60 : 'unknown'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
            {data.data?.scores ? (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Matchs
                </Typography>
                <Typography
                  fontStyle="italic"
                  variant="subtitle1"
                  gutterBottom
                  component="div">
                  Abnormally high scores
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Score</TableCell>
                      <TableCell>Expected lower</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.scores.map((score) => (
                      <TableRow key={score.at}>
                        <TableCell component="th" scope="row">
                          {score.at}
                        </TableCell>
                        <TableCell>{score.score}</TableCell>
                        <TableCell>{score.threshold || 'unknown'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
            {data.data?.match_serie ? (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Match Serie
                </Typography>
                <Typography
                  fontStyle="italic"
                  variant="subtitle1"
                  gutterBottom
                  component="div">
                  Abnormally high consecutive matches
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Match Count</TableCell>
                      <TableCell>Expected lower</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.match_serie.map((score) => (
                      <TableRow key={score.from}>
                        <TableCell component="th" scope="row">
                          {score.from}
                        </TableCell>
                        <TableCell>{score.to}</TableCell>
                        <TableCell>{score.len}</TableCell>
                        <TableCell>{score.threshold || 'unknown'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
            {data.data?.boting_serie ? (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Bot Serie
                </Typography>
                <Typography
                  fontStyle="italic"
                  variant="subtitle1"
                  gutterBottom
                  component="div">
                  Abnormally frequent/regular game start periods
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Match Count</TableCell>
                      <TableCell>Expected lower</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.boting_serie.map((score) => (
                      <TableRow key={score.from}>
                        <TableCell component="th" scope="row">
                          {score.from}
                        </TableCell>
                        <TableCell>{score.to}</TableCell>
                        <TableCell>{score.len}</TableCell>
                        <TableCell>{score.threshold || 'unknown'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
            {data.data?.win_series ? (
              <Box mt={2}>
                <Typography variant="h6" gutterBottom component="div">
                  Win series
                </Typography>
                <Typography
                  fontStyle="italic"
                  variant="subtitle1"
                  gutterBottom
                  component="div">
                  Abnormally high consecutive victories
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Match count</TableCell>
                      <TableCell>Expected lower</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.data.win_series.map((score) => (
                      <TableRow key={score.at}>
                        <TableCell>{score.from}</TableCell>
                        <TableCell>{score.to}</TableCell>
                        <TableCell>{score.len}</TableCell>
                        <TableCell>{score.threshold || 'unknown'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <></>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SuspiciousBehavioursDataGrid = ({ playerId }) => {
  const { classes } = useStyles();
  const [data, setData] = useState<SuspiciousBehavior[]>([]);

  useEffect(() => {
    (async () => {
      const response = await BoyAPI.Player.getSuspiciousBehaviors(playerId);
      setData(response.data);
    })();
  }, [playerId]);
  return (
    <>
      <Typography color="textSecondary" align="left" className={classes.title}>
        Suspicious Behaviors
      </Typography>
      <Table>
        <TableBody>
          {data.map((row) => (
            <Row data={row} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default SuspiciousBehavioursDataGrid;
