import { InputProps, useDataProvider, useListContext } from 'react-admin';
import { MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useEffect, useState } from 'react';
import { Identifier, LogIdentifier } from 'core/types';

const useStyles = makeStyles()({
  input: {
    paddingTop: 10,
    paddingBottom: 11,
  },
});
const GameIdFilter = ({}: InputProps) => {
  const { setFilters } = useListContext();

  const { classes } = useStyles();
  const dataProvider = useDataProvider();

  const [identifiers, setIdentifiers] = useState<Identifier[]>([]);
  const [currentIdentifier, setCurrentIdentifier] = useState('all');
  const [currentIdentifierValue, setCurrentIdentifierValue] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await dataProvider.getList('identifier', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'order', order: 'ASC' },
        filter: {},
      });
      setIdentifiers(data as Identifier[]);
      setCurrentIdentifier('all');
    })();
  }, [dataProvider]);

  return (
    <div>
      <TextField
        select
        value={currentIdentifier}
        onChange={(e) => {
          setCurrentIdentifier(e.target.value);
        }}
        variant="filled"
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        placeholder="Identifier"
        style={{ marginRight: 8 }}>
        <MenuItem key="all" value="all">
          All
        </MenuItem>
        {identifiers.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        value={currentIdentifierValue}
        onChange={(e) => {
          setCurrentIdentifierValue(e.target.value);
        }}
        onKeyPress={async ({ nativeEvent }) => {
          if (nativeEvent.key === 'Enter') {
            const filters: any = { value: currentIdentifierValue };
            if (currentIdentifier !== 'all') {
              filters.type = currentIdentifier;
            }
            const { data } = await dataProvider.getList('log_identifiers', {
              pagination: { page: 1, perPage: 100 },
              sort: { field: 'id', order: 'DESC' },
              filter: filters,
            });
            const logIdentifiers = data as LogIdentifier[];
            const playerIds = [
              ...new Set(logIdentifiers.map((value) => value.player_id)),
            ];
            //console.log(playerIds);
            setFilters(
              {
                id: playerIds.length > 0 ? playerIds : ['unknown'],
              },
              {},
            );
          }
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        id="standard-basic"
        placeholder="Search by Identifier"
        variant="filled"
      />
    </div>
  );
};

export default GameIdFilter;
