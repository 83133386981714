import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TablePagination,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Shop, ShopCategory } from 'core/types';
import React, { useEffect, useMemo, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import BoyAPI from 'services/boy-api';
import { Color, Parser, Render, Tools } from '../common';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const DEFAULT_COLUMNS = [
  { field: 'ref', headerName: 'Reference' },
  { field: 'is_active', headerName: 'Active?' },
  { field: 'is_parent', headerName: 'Parent?' },
  { field: 'parents', headerName: 'Parents' },
];

const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },

  clickableCell: {
    cursor: 'pointer',
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '2px',
    },
  },
});
function Row({ row }: { row: ShopCategory }) {
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.ref}
        </TableCell>
        <TableCell style={{ color: Color.bool(row.is_active) }}>
          {Render.bool(row.is_active)}
        </TableCell>
        <TableCell style={{ color: Color.bool(row.parent_id !== 'undefined') }}>
          {Render.bool(row.parent_id !== 'undefined')}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.parents && row.parents.join(', ')}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CategoryList = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<ShopCategory[]>([]);
  const currentPage = 1;
  const [currentItemPerPages, setCurrentItemPerPage] = useState<number>(25);
  const [totalItems, setTotalItems] = useState<number>(1);
  const nbPage = 1;

  const columns = useMemo(() => [...DEFAULT_COLUMNS], []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        let res = await BoyAPI.Shop.listCategories();

        setTotalItems(res.data.length);
        setCurrentItemPerPage(res.data.length);

        setCategories(res.data);
      } catch (error) {
        const [msg, type] = Parser.error(error);
        notify(msg, type);
      }

      setLoading(false);
    })();
  }, [notify]);

  return (
    <>
      <Title title={`Shop categories`} />
      <Table aria-label="collapsible table">
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
          }}
          onPageChange={(e) => {
            e?.preventDefault();
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Tools.navActions(() => {}, currentPage, nbPage)}
        />
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.field}
              sx={{ cursor: column.sortKey ? 'pointer' : 'inherit' }}>
              <label for={column.field + '_select'}>{column.headerName}</label>
            </TableCell>
          ))}
        </TableRow>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {categories.map((row, ind) => (
                <Row key={ind} row={row} />
              ))}
            </>
          )}
        </TableBody>
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
          }}
          onPageChange={(e) => {
            e?.preventDefault();
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Tools.navActions(() => {}, currentPage, nbPage)}
        />
      </Table>
    </>
  );
};

export default CategoryList;
