import { Layout, Sidebar } from 'react-admin';
import Menu from './Menu';

const MySidebar = (props) => (
  <Sidebar
    sx={{
      '& .RaSidebar-fixed': {
        position: 'relative',
      },
    }}
    {...props}
  />
);

const AppLayout = (props: any) => {
  return <Layout {...props} menu={Menu} sidebar={MySidebar} />;
};

export default AppLayout;
