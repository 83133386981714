import React, { useState } from 'react';
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  DateField,
  useRecordContext,
} from 'react-admin';
import { Typography } from '@mui/material';
import { Pagination } from 'react-admin';
type Props = {
  playerId: string;
};

const PriceField = () => {
  const record = useRecordContext();
  return (
    <Typography style={{ fontSize: 14 }}>
      {record.price} {record.currency}
    </Typography>
  );
};

const GiftHistoryDataGrid = ({ playerId, ...props }: Props) => {
  const [rowPerPage, setRowPerPage] = useState<number>(50);

  return (
    <ReferenceManyField
      {...props}
      perPage={rowPerPage}
      label="Lot"
      reference="winner"
      target="player_id"
      pagination={
        <Pagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          setPerPage={(qty: number) => {
            setRowPerPage(qty);
          }}
          perPage={rowPerPage}
        />
      }
      sort={{ field: 'selection_date', order: 'DESC' }}
      filter={{ player_id: playerId }}>
      <Datagrid rowClick="show">
        <TextField source="state" sortable={false} />
        <TextField source="lot_id" sortable={false} />
        <TextField source="player_boy_id" label="Boy Id" sortable={false} />
        <TextField source="lot_value" sortable={false} />
        <PriceField label="Price" />
        <TextField source="country" sortable={false} />
        <DateField source="selection_date" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default GiftHistoryDataGrid;
