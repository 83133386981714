import { Match } from '@testing-library/dom';
import { MatchHistory } from 'core/types';
import { RESULT_CHOICES, BAN_CHOICES } from 'utils/constants';

type Result = {
  value: string;
  label: string;
};

const FLAT_CHOICES = Object.keys(RESULT_CHOICES).reduce<Result[]>(
  (acc, cur) => {
    // @ts-ignore
    return [...acc, ...RESULT_CHOICES[cur]];
  },
  [],
);
const getResultLabel = (resultKey: string) => {
  const resultObj = FLAT_CHOICES.find((result) => result.value === resultKey);
  return resultObj?.label || resultKey;
};

const FLAT_BAN_CHOICES = Object.keys(BAN_CHOICES).reduce<Result[]>((acc) => {
  // @ts-ignore
  return [...acc, ...BAN_CHOICES];
}, []);
const getBanDurationLabel = (resultKey: string) => {
  const resultObj = FLAT_BAN_CHOICES.find(
    (result) => result.value === resultKey,
  );
  return resultObj?.label || resultKey;
};

const getWaitDuration = (match: MatchHistory) => {
  return match.wait_duration || match.wait_duration_int || 0;
};

const matchUtils = {
  getResultLabel,
  getBanDurationLabel,
  getWaitDuration,
};

export default matchUtils;
