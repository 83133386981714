import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  BasicStatIdCount,
  MessageCountByDay,
  MessageCountByPlayer,
  SocialLinksCount,
  PlayersBlockedByDay,
} from 'core/types';
import BoyAPI from 'services/boy-api';
import DateRangePicker from 'components/common/DateRangePicker';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const getFirstDate = () => {
  var prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 15);
  return prevDate;
};

function CustomToolbar(props) {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport
        csvOptions={{ fileName: props.title }}
        printOptions={{ fileName: props.title }}
      />
    </GridToolbarContainer>
  );
}

export const SocialStat = () => {
  const { classes } = useStyles();
  const [messageCountByDayList, setMessageCountByDayList] = useState<
    MessageCountByDay[]
  >();
  const [messageCountByDayIsReady, setMessageCountByDayIsReady] = useState(
    false,
  );

  const [messageCountByPlayerList, setMessageCountByPlayerList] = useState<
    MessageCountByPlayer[]
  >();
  const [
    messageCountByPlayerIsReady,
    setMessageCountByPlayerIsReady,
  ] = useState(false);

  const [socialLinksCountList, setSocialLinksCount] = useState<
    SocialLinksCount[]
  >();
  const [socialLinksCountIsReady, setSocialLinksCountIsReady] = useState(false);

  const [playerFriendsCountList, setPlayerFriendsCountList] = useState<
    BasicStatIdCount[]
  >();
  const [playerFriendsCountIsReady, setPlayerFriendsCountIsReady] = useState(
    false,
  );

  const [playerBlockedCountList, setPlayerBlockedCountList] = useState<
    BasicStatIdCount[]
  >();
  const [playerBlockedByDayIsReady, setPlayerBlockedByDayIsReady] = useState(
    false,
  );

  const [playerBlocksCountList, setPlayerBlocksCountList] = useState<
    BasicStatIdCount[]
  >();
  const [playerBlocksCountIsReady, setPlayerBlocksCountIsReady] = useState(
    false,
  );

  const [playerBlockedByDay, setPlayerBlockedByDay] = useState<
    PlayersBlockedByDay[]
  >();
  const [playerBlockedCountIsReady, setPlayerBlockedCountIsReady] = useState(
    false,
  );

  const columnsMessageCountByDay = [
    { field: 'date', headerName: 'Date', width: 250 },
    { field: 'count', headerName: 'Nb de messages', width: 250 },
  ];

  const columnsMessageCountByPlayer = [
    { field: 'boy_id', headerName: 'Player', width: 250 },
    { field: 'count', headerName: 'Nb de messages', width: 250 },
  ];

  const columnsSocialLinksCount = [
    { field: 'date', headerName: 'Date', width: 250 },
    { field: 'state', headerName: 'State', width: 250 },
    { field: 'count', headerName: 'Nb', width: 250 },
  ];

  const columnsBasicIdDateCount = [
    { field: 'id', headerName: 'Date', width: 250 },
    { field: 'count', headerName: 'Nb', width: 250 },
  ];

  const columnsBasicBoyIdCount = [
    { field: 'boy_id', headerName: 'Player', width: 250 },
    { field: 'count', headerName: 'Nb', width: 250 },
  ];

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstDate(),
    new Date(),
  ]);

  useEffect(() => {
    (async () => {
      setMessageCountByDayIsReady(false);
      setMessageCountByPlayerIsReady(false);
      setSocialLinksCountIsReady(false);
      setPlayerFriendsCountIsReady(false);
      setPlayerBlockedCountIsReady(false);
      setPlayerBlocksCountIsReady(false);
      let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[0],
      );
      let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[0],
      );
      let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[0],
      );
      let begindate = `${ye1}-${mo1}-${da1}`;

      let ye2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[1],
      );
      let mo2 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[1],
      );
      let da2 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[1],
      );
      let enddate = `${ye2}-${mo2}-${da2}`;

      BoyAPI.Statistic.getMessagesCountByDay(begindate, enddate).then(
        (respMessagesByDay) => {
          respMessagesByDay.forEach((m) => (m.id = m.date));
          setMessageCountByDayList(respMessagesByDay);
          setMessageCountByDayIsReady(true);
        },
      );

      BoyAPI.Statistic.getMessagesCountByPlayer(begindate, enddate).then(
        (respMessagesByPlayer) => {
          //console.log(respMessagesByPlayer);
          setMessageCountByPlayerList(respMessagesByPlayer);
          setMessageCountByPlayerIsReady(true);
        },
      );

      BoyAPI.Statistic.getSocialLinksCount(begindate, enddate).then(
        (respSocialLinksCount) => {
          //console.log(respSocialLinksCount);
          setSocialLinksCount(respSocialLinksCount);
          setSocialLinksCountIsReady(true);
        },
      );

      BoyAPI.Statistic.getPlayerBlockedByDay(begindate, enddate).then(
        (resp) => {
          setPlayerBlockedByDay(resp);
          setPlayerBlockedByDayIsReady(true);
        },
      );

      BoyAPI.Statistic.getFriendsCount().then((respGetFriendsCount) => {
        setPlayerFriendsCountList(respGetFriendsCount);
        setPlayerFriendsCountIsReady(true);
      });

      BoyAPI.Statistic.getPlayerBlockedCount().then((data) => {
        setPlayerBlockedCountList(data);
        setPlayerBlockedCountIsReady(true);
      });

      BoyAPI.Statistic.getPlayerBlocksCount().then((data) => {
        setPlayerBlocksCountList(data);
        setPlayerBlocksCountIsReady(true);
      });
    })();
  }, [dateRange]);

  return (
    <>
      <div style={{ margin: '16px 0', textAlign: 'left' }}>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            if (!value[0] || !value[1]) {
              return;
            }
            setDateRange([value[0], value[1]]);
          }}
        />
      </div>
      <Card className={classes.root}>
        <CardContent>
          {!messageCountByDayIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Messages par jour
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  rows={messageCountByDayList || []}
                  columns={columnsMessageCountByDay}
                  pageSize={20}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'message_count_per_day' },
                  }}
                  sortModel={[
                    {
                      field: 'date',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!messageCountByPlayerIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Messages par joueur
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'message_count_by_player' },
                  }}
                  rows={messageCountByPlayerList || []}
                  columns={columnsMessageCountByPlayer}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'count',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!socialLinksCountIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Demandes d'amis acceptées
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'social_link_count' },
                  }}
                  rows={socialLinksCountList || []}
                  columns={columnsSocialLinksCount}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'date',
                      sort: 'desc',
                    },
                    {
                      field: 'state',
                      sort: 'asc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!playerFriendsCountIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Amis
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'player_friend_count' },
                  }}
                  rows={playerFriendsCountList || []}
                  columns={columnsBasicBoyIdCount}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'count',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!playerBlockedByDayIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Joueurs bloqués par jour
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'player_blocked_per_day' },
                  }}
                  rows={playerBlockedByDay || []}
                  columns={columnsBasicIdDateCount}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'id',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!playerBlockedCountIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Joueurs bloqués
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'player_blocked_count' },
                  }}
                  rows={playerBlockedCountList || []}
                  columns={columnsBasicBoyIdCount}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'count',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!playerBlocksCountIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Joueurs bloquants
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'player_blocks_count' },
                  }}
                  rows={playerBlocksCountList || []}
                  columns={columnsBasicBoyIdCount}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'count',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SocialStat;
