import { Card, CardContent, Typography } from '@mui/material';
import PieChart from 'components/data/PieChart';
import theme from 'core/theme';
import React, { useEffect, useState } from 'react';
import firestore from 'services/firestore';
import { Winner } from 'core/types';
import firestoreUtils from 'utils/firestore';

const LotWinnedChart = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<{ data: number[]; labels: string[] }>({
    data: [],
    labels: [],
  });
  useEffect(() => {
    (async () => {
      let beginDate = new Date();
      beginDate.setDate(beginDate.getDate() - 30);

      const winnerList = await firestore()
        .collection('winner')
        .where('confirmation_date', '>', beginDate)
        .get();

      let countByLotId: { [key: string]: number } = {};

      winnerList.forEach((doc) => {
        const winner = doc.data() as Winner;
        if (!countByLotId.hasOwnProperty(winner.lot_id)) {
          countByLotId[winner.lot_id] = 0;
        }
        countByLotId[winner.lot_id]++;
      });

      setData({
        labels: Object.keys(countByLotId),
        data: Object.values(countByLotId),
      });
      setLoading(false);
    })();
  }, []);
  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Typography
          color="textSecondary"
          style={{ textTransform: 'uppercase' }}>
          Lots gagnés
        </Typography>

        <PieChart
          loading={loading}
          data={{
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  theme.palette.secondary.main,
                  'rgba(75,192,192,1)',
                  '#FFCE56',
                ],
              },
            ],
          }}
        />
      </CardContent>
    </Card>
  );
};

export default LotWinnedChart;
