import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PlayerRanking } from 'core/types';
import BoyAPI from 'services/boy-api';
import dateUtils from 'utils/date';
import DateRangePicker from 'components/common/DateRangePicker';
import { lastDayOfWeek, subDays } from 'date-fns';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const getFirstMonday = () => {
  var prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  return prevMonday;
};

export const PlayersRankingList = () => {
  const { classes } = useStyles();
  const [ranking, setRanking] = useState<PlayerRanking[]>();
  const [isReady, setIsReady] = useState(false);

  const columns = [
    { field: 'id', headerName: 'Boy Id', width: 180 },
    { field: 'player_boy_id', headerName: 'Boy Id', width: 180 },
    { field: 'total_matchs', headerName: 'Matchs', width: 150 },
    { field: 'victories', headerName: 'Victoires', width: 150 },
    { field: 'defeats', headerName: 'Défaites', width: 150 },
    { field: 'draws', headerName: 'Draws', width: 150 },
    {
      field: 'victories_percent',
      headerName: 'Victoires',
      width: 150,
      valueFormatter: (params) => Math.round(params.value).toString() + '%',
    },
    {
      field: 'defeats_percent',
      headerName: 'Défaites',
      width: 150,
      valueFormatter: (params) => Math.round(params.value).toString() + '%',
    },
    {
      field: 'draws_percent',
      headerName: 'Draws',
      width: 150,
      valueFormatter: (params) => Math.round(params.value).toString() + '%',
    },
  ];

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstMonday(),
    new Date(),
  ]);

  useEffect(() => {
    (async () => {
      setIsReady(false);
      let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[0],
      );
      let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[0],
      );
      let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[0],
      );
      let begindate = `${ye1}-${mo1}-${da1}`;

      let ye2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[1],
      );
      let mo2 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[1],
      );
      let da2 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[1],
      );
      let enddate = `${ye2}-${mo2}-${da2}`;

      //console.log(begindate);
      //console.log(enddate);
      const resp = await BoyAPI.Statistic.getPlayerRanking(begindate, enddate);
      setRanking(resp);
      setIsReady(true);
    })();
  }, [dateRange]);

  return (
    <>
      <div style={{ margin: '16px 0', textAlign: 'left' }}>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            if (!value[0] || !value[1]) {
              return;
            }
            setDateRange([value[0], value[1]]);
          }}
        />
      </div>
      <Card className={classes.root}>
        <CardContent>
          {!isReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Top joueurs
              </Typography>
              <div style={{ height: '800px', width: '100%' }}>
                <DataGrid
                  rows={ranking || []}
                  columns={columns}
                  pageSize={20}
                  checkboxSelection
                  sortModel={[
                    {
                      field: 'total_matchs',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PlayersRankingList;
