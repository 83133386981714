import {
  Datagrid,
  DateField,
  BooleanField,
  ListProps,
  TextField as TextFieldDefault,
  useRecordContext,
  TextInput,
  NullableBooleanInput,
  SelectInput,
  useListContext,
  DateTimeInput,
} from 'react-admin';
import { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import BoyAPI from 'services/boy-api';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import LotWinnedChart from './LotWinnedChart';
import RAList from 'components/common/RAList';

const useStyles = makeStyles()(() => ({
  item: {
    paddingBottom: 24,
    marginTop: 32,
    width: '100%',
    alignItems: 'stretch',
    display: 'flex',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
}));

type Winner = {
  player_nickname: string;
  player_id: string;
  lot_id: string;
  nationality: string;
  contact_infos: {
    firstname: string;
    lastname: string;
    mail: string;
    phone_number: string;
  };
  address_infos: {
    address: string;
    city: string;
    country: string;
    postal_code: string;
  };
};

const PriceField = () => {
  const record = useRecordContext();
  return (
    <Typography style={{ fontSize: 14 }}>
      {record.price} {record.currency}
    </Typography>
  );
};

const DrawWinnerButton = () => {
  const { filterValues } = useListContext();

  useEffect(() => {
    //console.log('filter', filterValues);
  }, []);

  const [numberWinner, setNumberWinner] = useState(0);
  const [winners, setWinners] = useState<Winner[]>([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    //console.log(numberWinner);
  }, [numberWinner]);

  const isDisabled =
    filterValues.is_raffle &&
    filterValues.state === 'CONFIRMED' &&
    filterValues.lot_id &&
    filterValues.selection_date_gte &&
    filterValues.selection_date_lte;

  return (
    <>
      <TextField
        onChange={(number) => {
          setNumberWinner(Number(number.target.value));
        }}
        placeholder="Number of Winners"
        variant="standard"
      />

      <Button
        disabled={!isDisabled}
        size="small"
        color={isDisabled ? 'primary' : 'inherit'}
        onClick={async () => {
          //console.log('test', filterValues);
          /*console.log(
            'date',
            filterValues.selection_date_gte,
            new Date(filterValues.selection_date_gte)
              .toISOString()
              .split('T')[0],
          );*/

          try {
            let res = await BoyAPI.Winner.drawRaffleWinner(
              filterValues.lot_id,
              numberWinner,
              new Date(filterValues.selection_date_gte)
                .toISOString()
                .split('T')[0] +
                ' ' +
                new Date(filterValues.selection_date_gte)
                  .toISOString()
                  .split('T')[1]
                  .split('.')[0],
              new Date(filterValues.selection_date_lte)
                .toISOString()
                .split('T')[0] +
                ' ' +
                new Date(filterValues.selection_date_lte)
                  .toISOString()
                  .split('T')[1]
                  .split('.')[0],
            );
            setWinners(res.data as Winner[]);
            //console.log('res', res.data);
          } catch (error) {
            //console.log('error', error);
          }
          setOpen(true);
        }}>
        Draw WinnerRaffle
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            And the winner is ...
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {winners.map((data) => {
              return (
                <p>
                  Nom: {data.player_nickname},<br />
                  id: {data.player_id},<br />
                  nationality: {data.nationality},<br />
                  contact: {data.contact_infos.firstname}{' '}
                  {data.contact_infos.lastname}, {data.contact_infos.mail},{' '}
                  {data.contact_infos.phone_number}
                  <br />
                  address: {data.address_infos.address}{' '}
                  {data.address_infos.city} {data.address_infos.postal_code}{' '}
                  {data.address_infos.country}
                  <br />
                </p>
              );
            })}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const winnerFilters = [
  <SelectInput
    source="state"
    choices={[
      { id: 'PENDING', name: 'Pending' },
      { id: 'REFUND', name: 'Refund' },
      { id: 'CANCELLED', name: 'Cancelled' },
      { id: 'CONFIRMED', name: 'Confirmed' },
      { id: 'BANNED', name: 'Banned' },
    ]}
    alwaysOn
  />,
  <NullableBooleanInput
    label="Raffle"
    source="is_raffle"
    defaultValue={null}
    alwaysOn
  />,
  <TextInput label="Lot" source="lot_id" alwaysOn />,
  <DateTimeInput label="From" source="selection_date_gte" alwaysOn />,
  <DateTimeInput label="To" source="selection_date_lte" alwaysOn />,
  <DrawWinnerButton alwaysOn />,
];

const WaitingValidation = (props: ListProps) => {
  return (
    <RAList
      {...props}
      exporter={false}
      filters={winnerFilters}
      filterDefaultValues={{ state: 'PENDING' }}
      sort={{ field: 'selection_date', order: 'DESC' }}>
      <Datagrid rowClick="show">
        <TextFieldDefault source="lot_id" />
        <TextFieldDefault source="state" />
        <TextFieldDefault source="player_boy_id" label="Boy Id" />
        <TextFieldDefault source="lot_value" />
        <BooleanField
          source="is_premium"
          looseValue={true}
          label="With Premium"
        />
        <BooleanField source="is_raffle" looseValue={true} label="Raffle !" />
        <PriceField />
        <TextFieldDefault source="country" label="Shop" />
        <TextFieldDefault source="nationality" />
        <DateField source="selection_date" />
      </Datagrid>
    </RAList>
  );
};

const WinnerList = (props: ListProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Typography className={classes.title} color="textSecondary">
        Waiting validation
      </Typography>

      <WaitingValidation {...props} />

      <Grid item xs={12} md={12} className={classes.item}>
        <LotWinnedChart />
      </Grid>
    </>
  );
};

export default WinnerList;
