import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { MatchHistory } from 'core/types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import firestore from 'services/firestore';
import fr from 'date-fns/locale/fr';
import dateUtils from 'utils/date';

const datasetOptions = {
  fill: false,
  lineTension: 0.4,
  backgroundColor: 'rgba(75,192,192,0.4)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(75,192,192,1)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  data: [65, 59, 80, 81, 56, 55, 40],
};

const useStyles = makeStyles()({
  root: {
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
});

const MatchChart = ({ playerId }: any) => {
  const { classes } = useStyles();
  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState<any>({
    matches: [],
    labels: [],
  });
  const theme = useTheme();
  useEffect(() => {
    (async () => {
      const result = await firestore()
        .collection('player')
        .doc(playerId)
        .collection('match_history')
        .orderBy('start_match_timestamp', 'desc')
        .get();

      const countMatchs: any = {};

      let beginDate = new Date();
      beginDate.setDate(beginDate.getDate() - 30);
      var now = new Date();

      for (var d = beginDate; d <= now; d.setDate(d.getDate() + 1)) {
        countMatchs[dateUtils.localizedFormat(new Date(d), 'P')] = 0;
      }

      result.forEach((doc) => {
        const data = doc.data() as MatchHistory;
        let fmdate: string = dateUtils.localizedFormat(
          data.start_match_timestamp.toDate(),
          'P',
        );
        if (fmdate in countMatchs) {
          countMatchs[fmdate]++;
        }
      });

      setData({
        labels: Object.keys(countMatchs),
        matchs: Object.values(countMatchs),
      });
      setIsReady(true);
    })();
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography
          color="textSecondary"
          align="left"
          className={classes.title}>
          Activités
        </Typography>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            paddingBottom: 16,
          }}>
          {!isReady ? (
            <CircularProgress />
          ) : (
            <Line
              data={{
                labels: data.labels,
                datasets: [
                  {
                    ...datasetOptions,
                    label: 'Matchs',
                    borderColor: theme.palette.secondary.main,
                    data: data.matchs,
                  },
                ],
              }}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MatchChart;
