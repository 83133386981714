import React from 'react';
import { List, ListProps, Pagination, PaginationProps } from 'react-admin';

const PostPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const RAList = (props: ListProps & { children: any }) => {
  return <List perPage={25} pagination={<PostPagination />} {...props} />;
};

export default RAList;
