import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';
import matchUtils from 'utils/match';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const AvgWaitDurationNightHourCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalulIsReady] = useState(false);
  const [avgWaitDuration, setavgWaitDuration] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      var sum = 0;
      let matchInNightHours = matchHistoryList.filter(
        (ma) => ma.night_hour === true,
      );

      matchInNightHours.forEach((match) => {
        sum += matchUtils.getWaitDuration(match);
      });
      var avg = sum / matchInNightHours.length;

      setavgWaitDuration(`${avg.toFixed(0)} s`);
      setCalulIsReady(true);
    })();
  }, []);

  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">
              Attente moyenne entre 1h et 10h
            </Typography>
            <p>{avgWaitDuration}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AvgWaitDurationNightHourCard;
