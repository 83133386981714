import {
  Datagrid,
  DateField,
  ListProps,
  TextField,
  List,
  Filter,
  SelectInput,
} from 'react-admin';

const FilterComponent = (props: any) => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      resettable
      source="state"
      choices={[
        { id: 'PENDING', name: 'Pending' },
        { id: 'COLLECTED', name: 'Collected' },
        { id: 'REFUSED', name: 'Refused' },
      ]}
    />
  </Filter>
);

const ReviewList = (props: ListProps) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<FilterComponent />}
      sort={{ field: 'ask_date', order: 'ASC' }}>
      <Datagrid rowClick="show">
        <TextField source="state" />
        <TextField source="bonus_id" />
        <TextField source="player_boy_id" />
        <DateField source="ask_date" showTime />
      </Datagrid>
    </List>
  );
};

export default ReviewList;
