import { Snackbar, Alert } from '@mui/material';
import { useCallback, useState } from 'react';
import { Error, Notif } from './common';

import GemsTransactionsListing from './GemsList';
import GoldsTransactionsListing from './GoldList';
import InAppProductsTransactionsListing from './InAppProductsList';
import PremiumBetsTransactionsListing from './PremiumBetList';

const Transactions = ({ playerId }: { playerId: string }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasNotif, setHasNotif] = useState<boolean>(false);

  const [error, setError] = useState<Error | null>(null);
  const [notif, setNotif] = useState<Notif | null>(null);

  const closeSnackBar = () => {
    setHasError(false);
    setError(null);
  };

  const closeNotifSnackBar = () => {
    setHasNotif(false);
    setNotif(null);
  };

  const notify = useCallback((n: Notif) => {
    setNotif(n);
    setHasNotif(true);
  }, []);

  const onError = useCallback((e: Error) => {
    setError(e);
    setHasError(true);
  }, []);

  return (
    <>
      <Snackbar
        open={hasError}
        autoHideDuration={2000}
        onClose={closeSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={closeSnackBar}
          severity={error ? error.severity : 'info'}>
          {error?.error}
          {error?.key ? '=> ' + error?.key : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={hasNotif}
        autoHideDuration={2000}
        onClose={closeNotifSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={closeNotifSnackBar}
          severity={notif ? notif.level : 'success'}>
          {notif?.message}
        </Alert>
      </Snackbar>
      <GoldsTransactionsListing
        playerId={playerId}
        notify={notify}
        onError={onError}
      />
      <PremiumBetsTransactionsListing
        playerId={playerId}
        notify={notify}
        onError={onError}
      />
      <GemsTransactionsListing
        playerId={playerId}
        notify={notify}
        onError={onError}
      />
      <InAppProductsTransactionsListing playerId={playerId} />
    </>
  );
};

export default Transactions;
