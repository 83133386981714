import { useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import RAList from 'components/common/RAList';
import { useMemo } from 'react';
import {
    BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  NumberInput,
  Pagination,
  SearchInput,
  SimpleList,
  TextField,
  UrlField,
} from 'react-admin';
import dateUtils from 'utils/date';
import PlayerFilter from './PlayerFilter';
import KindFilter from './KindFilter';
//import GameIdFilter from './GameIdFilter';
//import PlayerBoyIdFilter from './PlayerBoyIdFilter';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
}));

export const TransactonFailedList = (props: any) => {
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  

  const datagrid = useMemo(() => {
    if (matches) {
      return (
        <Datagrid rowClick="show" optimized  >
          <TextField source="player_nickname" label="Nickname" />
          <FunctionField
                label='wallet'
                render={record => {
                    if (record.wallet_public_key) {
                      const { REACT_APP_ENV } = process.env;
                      return (
                        <a target="_blank" href={`https://solscan.io/account/${record.wallet_public_key}${REACT_APP_ENV === "dev" ? "?cluster=devnet" : ''}`} style={{color : "red"}} >
                            {`${record.wallet_public_key}`}
                        </a>
                      );
                    } else {
                      return  null;
                    }

                } }
            />
          <TextField source="amount" />
          <TextField source="unit_symbol" label="unit" />
          <TextField source="kind" />
          {<FunctionField
                label='purchased_at'
                render={record => {
                  const data = record.purchased_at;
                  const d = new Date(data)
                  const format = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  return <p>{ d.getDate() !== null ? format : data  }</p>
                } }
              />}
          {<FunctionField
                label='refund link'
                render={record => {
                    if (record.data.refund_txn_sig) {
                      const { REACT_APP_ENV } = process.env;
                      return (
                        <a target="_blank" href={`https://solscan.io/tx/${record.data.refund_txn_sig}${REACT_APP_ENV === "dev" ? "?cluster=devnet" : ''}`} style={{color : "red"}} >
                            {"link"}
                        </a>
                      );
                    } else {
                      return  null;
                    }

                } }
              />}
           {<FunctionField
                label='done by'
                render={record => {
                  return <p>{record.refunded_by_nickname ? record.refunded_by_nickname : record.reclaimed_by_nickname ? record.reclaimed_by_nickname : record.edited_by_nickname  }</p>
                } }
              />}
          {<FunctionField
                label='edit at'
                render={record => {
                  const data = record.refunded_at ? record.refunded_at : record.reclaimed_at ? record.reclaimed_at : record.edited_at;
                  const d = new Date(data)
                  const format = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  return <p>{ d.getDate() !== null ? format : data  }</p>
                } }
              />}
          <BooleanField source="refunded" />
          <BooleanField source="reclaimed" />
          <TextField source="note" />
        </Datagrid>
      );
    }
    return (
      <SimpleList
        linkType={'show'}
        primaryText={(record) => record.player_id}
        secondaryText={(record) => record.player_nickname}
        tertiaryText={(record) => record.refunded
          //dateUtils.localizedFormat(new Date(record.subscription_date), 'Ppp')
        }
      />
    );
  }, [matches]);
  return (
    <RAList
    disableSyncWithLocation
      className={classes.root}
      exporter={false}
      bulkActionButtons={false}
      perPage={100}
      total={100}
      {...props}
      filters={
        <Filter>
          <PlayerFilter source="player_nickname" alwaysOn />
          <KindFilter source="kind" alwaysOn />
        </Filter>
      }>
      {datagrid}
    </RAList>
  );
};

export default TransactonFailedList;
