import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useEffect, useState, forwardRef } from 'react';
import { useRedirect, Show } from 'react-admin';
import BoyAPI from 'services/boy-api';
import { DataGrid } from '@mui/x-data-grid';
import { NotificationTask } from 'core/types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const showDialog = (data: any) => {
  alert(data.id);
};

const NotificationList = () => {
  const { classes } = useStyles();
  const [isReady, setIsReady] = useState(false);
  const [tasks, setTasks] = useState<NotificationTask[]>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [select, setSelection] = useState<any[]>();
  const redirect = useRedirect();
  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const loadTasks = async () => {
    setIsReady(false);
    const response = await BoyAPI.Notifications.get();
    setTasks(response.data.tasks);
    setIsReady(true);
  };

  const confirmDelete = async () => {
    if (tasks && select) {
      const res = select?.map((id) => {
        return BoyAPI.Notifications.delete(id?.id);
      });
      await Promise.all(res);
      loadTasks();
    }
    handleDeleteClose();
  };

  const columns = [
    { field: 'id', headerName: 'Id', width: 200 },
    { field: 'schedule_time', headerName: 'Planifié pour', width: 220 },
    { field: 'title', headerName: 'Titre', width: 200 },
    { field: 'topic_condition', headerName: 'Topics', width: 300 },
    { field: 'message', headerName: 'Message', width: 280 },
    { field: 'pop_in_app', headerName: 'Pop in app', width: 150 },
    { field: 'create_time', headerName: 'Créé le', width: 220 },
  ];

  useEffect(() => {
    loadTasks();
  }, []);

  return (
    <>
      <h2>Notifications planifiées</h2>
      <Card className={classes.root}>
        <CardContent>
          <Link to="/Notification/create">
            <Button variant="outlined" color="primary">
              Planifier une nouvelle notification
            </Button>
          </Link>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickDeleteOpen}>
            Supprimer
          </Button>

          {!isReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Notifications planifiées
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  onRowClick={(rowData) => {
                    redirect('/Notification/show/' + rowData.row.id);
                  }}
                  rows={tasks || []}
                  columns={columns}
                  pageSize={20}
                  checkboxSelection
                  onSelectionModelChange={(newSelection) => {
                    if (tasks?.length)
                      setSelection(tasks.filter(task => newSelection.find(selectionId => selectionId === task?.id)))
                  }}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={deleteOpen}
        keepMounted
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">{'Suppression'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Etes-vous sûr de vouloir supprimer les planifications sélectionées ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete} color="primary">
            Ok
          </Button>
          <Button onClick={handleDeleteClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationList;
