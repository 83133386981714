import { Typography } from '@mui/material';

export const TextField = ({
  label,
  data,
}: {
  label?: string;
  data?: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '10px',
      }}>
      <Typography
        sx={{
          textTransform: 'capitalize',
        }}
        color="textSecondary"
        component="p"
        variant="h6">
        {label}
      </Typography>
      <Typography component="span" variant="body1">
        {data}
      </Typography>
    </div>
  );
};

export const ObjectField = ({
  label,
  data,
}: {
  label?: string;
  data?: object;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '10px',
      }}>
      <Typography
        sx={{ textTransform: 'capitalize' }}
        color="textSecondary"
        component="p"
        variant="h6">
        {label}
      </Typography>
      {data &&
        Object.entries(data).map(([key, val]) => {
          return (
            <Typography component="span" variant="body1">
              {key}: {val}
            </Typography>
          );
        })}
    </div>
  );
};

// export const BoolField = ({
//   label,
//   data,
// }: {
//   label?: string;
//   data?: boolean;
// }) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         // alignItems: '',
//         paddingTop: '10px',
//       }}>
//       <Typography
//         sx={{ paddingRight: '10px', textTransform: 'capitalize' }}
//         color="textSecondary"
//         component="p"
//         variant="h6">
//         {label}
//       </Typography>
//       <Typography
//         color={Color.bool(data || false)}
//         component="span"
//         variant="body1">
//         {Render.bool(data || false)}
//       </Typography>
//     </div>
//   );
// };
