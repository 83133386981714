import { Card, CardContent, Typography } from '@mui/material';
import PieChart from 'components/data/PieChart';
import theme from 'core/theme';
import React, { useEffect, useState } from 'react';
import { MatchHistory } from 'core/types';

type Props = {
  matchHistoryList: MatchHistory[];
};

const MatchPerGameChart = ({ matchHistoryList }: Props) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<{ data: number[]; labels: string[] }>({
    data: [],
    labels: [],
  });
  useEffect(() => {
    (async () => {
      var count = matchHistoryList.reduce<{ [key: string]: number }>((p, c) => {
        var game = c.game_id;
        if (!p.hasOwnProperty(game)) {
          p[game] = 0;
        }
        p[game]++;
        return p;
      }, {});

      setData({
        labels: Object.keys(count),
        data: Object.values(count),
      });
      setLoading(false);
    })();
  }, [matchHistoryList]);
  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Typography
          color="textSecondary"
          style={{ textTransform: 'uppercase' }}>
          Repartition of Played Games
        </Typography>

        <PieChart
          loading={loading}
          options={{
            tooltips: {
              callbacks: {
                label(item: any, data: any) {
                  const sum = data.datasets[0].data.reduce(
                    (acc: number, cur: number) => acc + cur,
                    0,
                  );
                  const current = data.datasets[0].data[item.index];
                  return `${data.labels[item.index]}: ${current} (${Math.round(
                    (current * 100) / sum,
                  )}%)`;
                },
              },
            },
          }}
          data={{
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  theme.palette.secondary.main,
                  'rgba(75,192,192,1)',
                  '#FFCE56',
                ],
              },
            ],
          }}
        />
      </CardContent>
    </Card>
  );
};

export default MatchPerGameChart;
