import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://866c933505b44fe6b6b7fbb092ac2b18@o460486.ingest.sentry.io/4504646510313472',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_ENV || 'local',
  release: process.env.RELEASE || 'dash@local',
  enabled:
    process.env.SENTRY_ENABLED !== undefined
      ? process.env.SENTRY_ENABLED.toLowerCase() === 'true'
      : false,
  autoSessionTracking: true,
  ignore_errors: process.env.SENTRY_IGNORE
    ? process.env.SENTRY_IGNORE.split(',')
    : [],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
