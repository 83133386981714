import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { DataGrid, GridSortItem } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ModoRanking } from 'core/types';
import BoyAPI from 'services/boy-api';
import firestore from 'services/firestore';
import { addMonths } from 'date-fns';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const getFirstMonday = () => {
  var prevMonday = new Date();
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  return prevMonday;
};

export const ModoRankingList = () => {
  const { classes } = useStyles();
  const [rankingM, setRankingM] = useState<ModoRanking[]>();
  const [rankingM_1, setRankingM_1] = useState<ModoRanking[]>();
  const [currentMonthName, setCurrentMonthName] = useState<string>();
  const [previousMonthName, setPreviousMonthName] = useState<string>();
  const [isReady, setIsReady] = useState(false);

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'litigations',
      sort: 'desc',
    },
  ]);
  const columns = [
    { field: 'player_boy_id', headerName: 'Pseudo', width: 180 },
    { field: 'litigations', headerName: 'Litiges résolus', width: 250 },
    { field: 'reviews', headerName: 'Reviews', width: 150 },
    { field: 'total', headerName: 'Total', width: 150 },
  ];

  useEffect(() => {
    (async () => {
      setIsReady(false);

      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth() + 1;
      setCurrentMonthName(
        currentDate.toLocaleString('fr-FR', { month: 'long' }),
      );

      var previousDate = new Date(addMonths(new Date(), -1));
      let previousYear = previousDate.getFullYear();
      let previousMonth = previousDate.getMonth() + 1;
      setPreviousMonthName(
        previousDate.toLocaleString('fr-FR', { month: 'long' }),
      );

      const resp1 = await BoyAPI.Statistic.getModoRanking(
        currentYear,
        currentMonth,
      );
      const resp2 = await BoyAPI.Statistic.getModoRanking(
        previousYear,
        previousMonth,
      );

      resp1.forEach((md) => {
        md.id = md.player_boy_id;
      });

      resp2.forEach((md) => {
        md.id = md.player_boy_id;
      });

      setRankingM(resp1);
      setRankingM_1(resp2);
      setIsReady(true);
    })();
  }, []);

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          {!isReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Top modérateurs {currentMonthName}
              </Typography>
              <div style={{ height: '800px', width: '100%' }}>
                <DataGrid
                  rows={rankingM || []}
                  columns={columns}
                  pageSize={20}
                  checkboxSelection
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!isReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Top modérateurs {previousMonthName}
              </Typography>
              <div style={{ height: '800px', width: '100%' }}>
                <DataGrid
                  rows={rankingM_1 || []}
                  columns={columns}
                  pageSize={20}
                  checkboxSelection
                  sortModel={[
                    {
                      field: 'litigations',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ModoRankingList;
