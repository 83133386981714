import TournamentDetail from './TournamentDetail';
import TournamentEdit from './TournamentEdit';
import TournamentsList from './TournamentList';

const TournamentPages = {
  list: TournamentsList,
  show: TournamentDetail,
  edit: TournamentEdit,
};

export default TournamentPages;
