import {
  Card,
  CardContent,
  Typography,
  Chip,
  ListItem,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  CardHeader,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import Common, { Error, InnerViewProps, Notif } from './common';
import UngroupedGoldsTransactions from './Golds/UngroupedGoldsList';
import GroupedGoldsTransactions from './Golds/GroupedGoldsList';
import { AddCircleOutlineOutlined, RefreshOutlined } from '@mui/icons-material';
import BoyAPI from 'services/boy-api';
import { GoldsSums } from 'core/types';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
  check: {
    marginBottom: 0,
    fontSize: 12,
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  clickableCell: {
    cursor: 'pointer',
  },
});

const GoldsTransactionsListing = ({
  playerId,
  notify,
  onError,
}: InnerViewProps) => {
  const { classes } = useStyles();

  const [liveStock, setLiveStock] = useState<number>(0);
  const [visibleStock, setVisibleStock] = useState<number>(0);
  const [transactionSums, setTransactionSums] = useState<GoldsSums>({
    sum: 0,
    consumed: 0,
    gained: 0,
  });

  const [displayGrouped, setDisplayedGrouped] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [
    newTransactionModalOppenned,
    setNewTransactionModalOppenned,
  ] = useState<boolean>(false);

  const onSuccess = useCallback((liveStock: number, visibleStock: number) => {
    setLiveStock(liveStock);
    setVisibleStock(visibleStock);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const sum = await BoyAPI.PlayerGoldsTransactions.get_sums(playerId);
        setTransactionSums(sum.data);
      } catch (err) {
        onError({
          severity: error.response.status >= 500 ? 'error' : 'warning',
          error: error.response.data.error || error.response.data.detail,
          key: error.response.data.key,
        });
      }
    })();
  }, [refresh, playerId, onError]);

  return (
    <>
      <Dialog
        open={newTransactionModalOppenned}
        onClose={() => {
          setNewTransactionModalOppenned(false);
        }}>
        <DialogTitle>Create manual gold transaction</DialogTitle>
        <Common.AddTransactionForm
          playerId={playerId}
          requester={BoyAPI.PlayerGoldsTransactions.make}
          onSuccess={() => {
            setTimeout(function () {
              notify({
                level: 'success',
                message: 'Gold transaction created !',
              });
              setRefresh(!refresh);
              setNewTransactionModalOppenned(false);
            }, 1000);
          }}
          onCancel={() => {
            setNewTransactionModalOppenned(false);
          }}
          notify={(n: Notif) => {
            notify(n);
          }}
          onError={(e: Error) => {
            onError(e);
          }}
        />
      </Dialog>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <ListItem>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                  }}>
                  <ListItem>
                    <Typography
                      color="textSecondary"
                      align="left"
                      className={classes.title}>
                      Gold&nbsp;transactions
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Button
                      onClick={() => setNewTransactionModalOppenned(true)}>
                      <AddCircleOutlineOutlined />
                      &nbsp;New&nbsp;transaction
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button onClick={() => setRefresh(!refresh)}>
                      <RefreshOutlined />
                      &nbsp;Refresh
                    </Button>
                  </ListItem>
                </Stack>
              </ListItem>

              <Stack direction="row">
                <ListItem>
                  <Chip
                    icon={
                      <img
                        alt="golds"
                        src="https://storage.googleapis.com/boy-pbl-assets/assets/golds/icon-white.png"
                        height="20px"
                        width="20px"
                      />
                    }
                    label={'actual: ' + transactionSums.sum.toString()}
                    variant="outlined"
                  />
                </ListItem>
                <ListItem>
                  <Chip
                    icon={
                      <img
                        alt="golds"
                        src="https://storage.googleapis.com/boy-pbl-assets/assets/golds/icon-white.png"
                        height="20px"
                        width="20px"
                      />
                    }
                    label={'stocked: ' + liveStock.toString()}
                    variant="outlined"
                    color={
                      transactionSums.sum === visibleStock ? 'success' : 'error'
                    }
                  />
                </ListItem>
                <ListItem>
                  <Chip
                    icon={
                      <img
                        alt="golds"
                        src="https://storage.googleapis.com/boy-pbl-assets/assets/golds/icon-white.png"
                        height="20px"
                        width="20px"
                      />
                    }
                    label={'visible: ' + visibleStock}
                    variant="outlined"
                    color={
                      transactionSums.sum === visibleStock ? 'success' : 'error'
                    }
                  />
                </ListItem>
                <ListItem>
                  <label for="displayGoldsSwitch">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        color={displayGrouped ? 'textSecondary' : 'textPrimary'}
                        align="left"
                        className={classes.check}>
                        Raw
                      </Typography>
                      <Switch
                        id="displayGoldsSwitch"
                        checked={displayGrouped}
                        onChange={(e) => {
                          setDisplayedGrouped(e.target.checked);
                        }}
                      />
                      <Typography
                        color={displayGrouped ? 'textPrimary' : 'textSecondary'}
                        align="left"
                        className={classes.check}>
                        Grouped
                      </Typography>
                    </Stack>
                  </label>
                </ListItem>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          <Typography align="right"></Typography>
          {displayGrouped ? (
            <GroupedGoldsTransactions
              playerId={playerId}
              refresh={refresh}
              onSuccess={onSuccess}
              onError={onError}
            />
          ) : (
            <UngroupedGoldsTransactions
              playerId={playerId}
              refresh={refresh}
              onSuccess={onSuccess}
              onError={onError}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default GoldsTransactionsListing;
