import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Sale } from 'core/types';
import React, { useEffect, useState } from 'react';
import firestore from 'services/firestore';
import dateUtils from 'utils/date';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
});

const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const columns = [
  { field: 'item_id', headerName: 'Reference', width: 230 },
  { field: 'quantity', headerName: 'Quantity', width: 230 },
  { field: 'gem_value', headerName: 'Amount', width: 230 },
  { field: 'date', headerName: 'At', width: 230 },
  { field: 'type', headerName: 'Type', width: 230 },
];

function Row(props: any) {
  const { row } = props;
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>{row.item_id}</TableCell>
        <TableCell component="th" scope="row">
          {row.quantity}
        </TableCell>
        <TableCell>{row.gem_value}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>{row.type}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LIMITE_FETCH = 1000;

const InAppProductsTransactionsListing = ({
  playerId,
}: {
  playerId: string;
}) => {
  const { classes } = useStyles();
  const [transactionList, setTransactionList] = useState<Sale[]>([]);

  const [lastDocument, setLastDocument] = useState<any>();

  const [currentPage, setCurrentPage] = useState(0);
  const [currentRow, setCurrentRow] = useState(25);
  const [endReach, setEndReach] = useState(false);

  useEffect(() => {
    (async () => {
      let query = firestore()
        .collection('sales')
        .where('player_id', '==', playerId)
        .orderBy('date', 'desc');

      if (lastDocument !== undefined) {
        query = query.startAfter(lastDocument);
      }

      const result = await query.limit(LIMITE_FETCH).get();

      setLastDocument(result.docs[result.docs.length - 1]);

      if (result.docs.length < LIMITE_FETCH) {
        setEndReach(true);
      }

      const rows: Sale[] = [];

      result.forEach((doc) => {
        const data = doc.data() as Sale;

        rows.push(data);
      });
      setTransactionList(
        rows.map((item) => {
          return {
            ...item,
            date: dateUtils.localizedFormat(item.date.toDate(), 'Ppp'),
          };
        }),
      );
    })();
  }, [playerId]);

  useEffect(() => {
    (async () => {
      if (
        transactionList.length <= 0 ||
        transactionList.length >= (currentPage + 1) * currentRow ||
        endReach
      ) {
        return;
      }
      let query = firestore()
        .collection('sales')
        .where('player_id', '==', playerId)
        .orderBy('date', 'desc');

      if (lastDocument !== undefined) {
        query = query.startAfter(lastDocument);
      }

      const result = await query.limit(LIMITE_FETCH).get();

      setLastDocument(result.docs[result.docs.length - 1]);

      if (result.docs.length < LIMITE_FETCH) {
        setEndReach(true);
      }

      const rows: Sale[] = [];

      result.forEach((doc) => {
        const data = doc.data() as Sale;

        rows.push(data);
      });
      setTransactionList((value) => {
        return [
          ...value,
          ...rows.map((item) => {
            return {
              ...item,
              date: dateUtils.localizedFormat(item.date.toDate(), 'Ppp'),
            };
          }),
        ];
      });
    })();
  }, [transactionList, currentPage, currentRow]);

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Typography
            color="textSecondary"
            align="left"
            className={classes.title}>
            In app product transactions
          </Typography>
        }
      />
      <CardContent>
        <div style={{ minHeight: '400px', width: '100%' }}>
          <Table aria-label="collapsible table">
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
            <TableBody>
              {[...transactionList]
                .slice(currentPage * currentRow, (currentPage + 1) * currentRow)
                .map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    fullList={transactionList}
                    columns={columns}
                  />
                ))}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={!endReach ? -1 : transactionList.length}
              onRowsPerPageChange={(e) => {
                e?.preventDefault();
                setCurrentRow(+e.target.value);
              }}
              onPageChange={(e, page) => {
                e?.preventDefault();
                setCurrentPage(page);
              }}
              page={currentPage}
              rowsPerPage={currentRow}
            />
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default InAppProductsTransactionsListing;
