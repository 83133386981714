import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const LitigationRatioCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalulIsReady] = useState(false);
  const [ratioLitigation, setRatioLitigation] = useState<string | false>(false);
  const [ratioDescription, setRatioDescription] = useState<string | false>(
    false,
  );

  useEffect(() => {
    (async () => {
      let totalMatchesCount = matchHistoryList.length;
      let totalLitigationCount = matchHistoryList.filter(
        (ma) => ma.is_after_litigation == true,
      ).length;
      let ratio = (totalLitigationCount * 100) / totalMatchesCount;
      setRatioLitigation(`${ratio.toFixed(2)}%`);
      setRatioDescription(
        `${totalLitigationCount} sur ${totalMatchesCount} matchs`,
      );
      setCalulIsReady(true);
    })();
  }, []);

  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">Litiges</Typography>
            <p>{ratioLitigation}</p>
            <p>{ratioDescription}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default LitigationRatioCard;
