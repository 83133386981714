import {
  InputLabel,
  Input,
  FormHelperText,
  Button,
  FormControl,
  Checkbox,
  TextField,
} from '@mui/material';
import { Card, CardContent, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BoyAPI from 'services/boy-api';
import CheckCircle from '@mui/icons-material/CheckCircle';
import 'date-fns';
import Grid from '@mui/material/Grid';
import {
  TimePicker,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const SendNotificationForm = () => {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState('');
  const [topicCondition, setTopicCondition] = useState('');
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [popInApp, setPopInApp] = useState(false);
  const [result, setResult] = useState<any>();
  const [error, setError] = useState<any>();
  const [sendFinished, setSendFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await BoyAPI.Notifications.getById(id);
        setTitle(response.data.task.title);
        setMessage(response.data.task.message);
        setUrl(response.data.task.url);
        setPopInApp(response.data.task.pop_in_app);
        setTopicCondition(response.data.task.topic_condition);
        setName(response.data.task.name);
        setSelectedDate(response.data.task.schedule_time);
      }
    })();
  }, []);

  return (
    <>
      {!name ? (
        <CircularProgress />
      ) : (
        <>
          {!loading ? (
            <>
              <FormControl>
                <Input
                  id="topicInput"
                  value={topicCondition}
                  onChange={(event: any) =>
                    setTopicCondition(event.target.value)
                  }
                  aria-describedby="topic-helper-text"
                />
                <FormHelperText id="topic-helper-text">
                  Par exemple : 'language_es' in topics && 'nationality_fr' in
                  topics (pour une intersection) 'language_es' in topics ||
                  'language_fr' in topics (pour une union)
                </FormHelperText>
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="titleInput">Title</InputLabel>
                <Input
                  id="titleInput"
                  value={title}
                  onChange={(event: any) => setTitle(event.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="messageInput">Message</InputLabel>
                <Input
                  multiline
                  rows={4}
                  rowsMax={8}
                  id="messageInput"
                  value={message}
                  onChange={(event: any) => setMessage(event.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="urlInput">Url</InputLabel>
                <Input
                  id="urlInput"
                  value={url}
                  onChange={(event: any) => setUrl(event.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="popInAppInput">
                  Doit poper dans l'app ?
                </InputLabel>
                <Checkbox
                  id="popInAppInput"
                  checked={popInApp}
                  onChange={() => setPopInApp(!popInApp)}
                />
              </FormControl>

              <FormControl>
                <Grid container justifyContent="space-around">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      renderInput={(params) => <TextField {...params} />}
                      id="date-picker-inline"
                      label="Date picker inline"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <TimePicker
                      margin="normal"
                      id="time-picker"
                      label="Time picker"
                      format="HH:mm"
                      renderInput={(params) => <TextField {...params} />}
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </FormControl>

              <Button
                onClick={async () => {
                  setLoading(true);
                  setResult('');
                  setError('');
                  setSendFinished(false);
                  if (topicCondition && message) {
                    try {
                      let res = await BoyAPI.Notifications.update(
                        id,
                        topicCondition,
                        title,
                        message,
                        popInApp,
                        selectedDate,
                        url,
                      );
                      setResult(res.data);
                      setSendFinished(true);
                      setLoading(false);
                    } catch (error) {
                      setError(error);
                      setSendFinished(true);
                      setLoading(false);
                    }
                  } else {
                    setLoading(false);
                  }
                }}>
                Envoyer
              </Button>

              {sendFinished && error ? (
                <Card>
                  <CardContent>{JSON.stringify(error)}</CardContent>
                </Card>
              ) : (
                <></>
              )}

              {sendFinished && result ? (
                <Card>
                  <CardContent>
                    <CheckCircle
                      style={{ color: 'green', fontSize: 32, marginTop: 24 }}
                    />
                  </CardContent>
                </Card>
              ) : (
                <></>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
        </>
      )}
    </>
  );
};

export default SendNotificationForm;
