import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const MatchsWithLess3MinPauseCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalculIsReady] = useState(false);
  const [matchsWithLess3MinPause, setMatchsWithLess3MinPause] = useState<
    string | false
  >(false);
  const [
    matchsWithLess3MinPauseInRow,
    setMatchsWithLess3MinPauseInRow,
  ] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      let maxInARow = 0;
      let max = 0;
      let count = 0;
      matchHistoryList.forEach((match) => {
        if (match.pauseDuration < 180 && match.result.startsWith('VICTORY')) {
          count++;
          max++;
        } else {
          max = 0;
        }
        if (max > maxInARow) {
          maxInARow = max;
        }
      });
      setMatchsWithLess3MinPause(`${count} total,`);
      setMatchsWithLess3MinPauseInRow(`${maxInARow} in a row`);
      setCalculIsReady(true);
    })();
  }, [matchHistoryList]);
  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">
              Matchs with less 3 min pause
            </Typography>
            <p>{matchsWithLess3MinPause}</p>
            <p>{matchsWithLess3MinPauseInRow}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MatchsWithLess3MinPauseCard;
