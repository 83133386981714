import { makeStyles } from 'tss-react/mui';
import { Permission } from 'core/types';
import {
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import {
  BooleanInput,
  Edit,
  NotFound,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  TextInput,
  PasswordInput,
} from 'react-admin';
import BoyAPI from 'services/boy-api';
import _ from 'lodash';
import { useEffect, useState } from 'react';

const useStyles = makeStyles()({
  '@global': {
    '.MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  showCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const PlayerEditToolbar = (props) => {
  const notify = useNotify();
  const record = useRecordContext(props);
  return (
    <Toolbar {...props}>
      <SaveButton
        type="button"
        label="Save"
        disabled={props.pristine}
        transform={async (data) => {
          //console.log("data", data)
          const {
            phone_number,
            password,
            nickname,
            ...dataToSaveToFirestore
          } = data;

          const { is_admin, is_moderator, no_auto_ban } = data;

          let shouldSaveClaim = false;
          let claimToSaves: string[] = [];
          if (is_admin && !record.is_admin) {
            shouldSaveClaim = true;
            claimToSaves.push('admin');
          }
          if (!is_admin && record.is_admin) {
            shouldSaveClaim = true;
          }

          if (is_moderator && !record.is_moderator) {
            shouldSaveClaim = true;
            claimToSaves.push('moderator');
          }

          if (!is_moderator && record.is_moderator) {
            shouldSaveClaim = true;
          }

          if (no_auto_ban !== record.no_auto_ban) {
            try {
              await BoyAPI.Player.toggleAutoBan(record.id);
            } catch (e) {
              notify('Toggle Auto Ban error', 'error');
              throw e;
            }
          }

          if (nickname !== record.nickname) {
            try {
              await BoyAPI.Player.update(record.id, {
                nickname,
              });
            } catch (e: any) {
              const getErrorLabel = () => {
                if (e?.response?.data?.ERROR === 'USERNAME_ALREADY_EXISTS') {
                  return `This nickname (${nickname}) already exists`;
                }
                const errorDetails = e.response?.data?.details;
                const isInvalidNickname =
                  errorDetails &&
                  errorDetails[0] &&
                  errorDetails[0].message === 'must_match';
                if (isInvalidNickname) {
                  return 'This nickname invalid. Nickname must contain between 3 and 20 characters, can only contain letters, numbers and the special characters _-.';
                }
                return 'Cannot update nickname for unknown reason';
              };

              notify(getErrorLabel(), 'error');
              throw e;
            }
          }

          if (phone_number !== record.phone_number) {
            try {
              await BoyAPI.Player.update(record.id, {
                phone_number: `+${phone_number}`,
              });
            } catch (e) {
              const errorDetails = e.response?.data?.details;
              const isInvalidPhoneNumber =
                errorDetails &&
                errorDetails[0] &&
                errorDetails[0].message === 'not_a_phone_number';
              notify(
                isInvalidPhoneNumber
                  ? 'Invalid phone number'
                  : 'Cannot update phone number for unknown reason',
                'error',
              );
              throw e;
            }
          }

          if (password) {
            try {
              await BoyAPI.Player.update(record.id, {
                password,
              });
            } catch (e) {
              const errorDetails = e.response?.data?.details;
              const isInvalidPassword =
                errorDetails &&
                errorDetails[0] &&
                errorDetails[0].message === 'must_match';
              notify(
                isInvalidPassword
                  ? 'Invalid password. Password must contain at least 8 characters, one number and one letter'
                  : 'Cannot update password for unknown reason',
                'error',
              );
              throw e;
            }
          }

          if (shouldSaveClaim) {
            try {
              await BoyAPI.Admin.setClaims(record.id, claimToSaves);
            } catch (e) {
              notify('Save roles error', 'error');
              throw e;
            }
          }

          const sanitizedValues = _.omitBy(
            {
              ...dataToSaveToFirestore,
              permissions: dataToSaveToFirestore?.permissions || [],
            },
            (data) => data === '',
          );

          // TODO: handle deassign roles
          return { ...sanitizedValues, notify: true };
        }}
      />
    </Toolbar>
  );
};

const PlayerEdit = (props) => {
  const { classes } = useStyles();

  const notify = useNotify();
  const redirect = useRedirect();
  const [availableRights, setAvailableRights] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        let res = await BoyAPI.Admin.listRights();

        console.log("test", availableRights);
        console.log("test", res.data);

        setAvailableRights(
          Object.keys(res.data).map((key) => {
            return { id: key, name: res.data[key] };
          }),
        );
      } catch (error) {
        //console.log('Cannot list rights', error);
      }
    })();
  }, [/*availableRights*/]);

  const { permissions } = usePermissions();


  const { data: currentUser } = useGetOne('player', {
    id: permissions?.user_id || '',
  });

    if (permissions || permissions.admin || currentUser?.permissions?.includes(Permission.ManageUser)) {
      return (
        <Edit
          {...props}
          classes={{
            cardContent: classes.showCardContent,
          }}>
          <SimpleForm
            toolbar={<PlayerEditToolbar />}
            sanitizeEmptyValues
            warnWhenUnsavedChanges
            redirect="show">
            <SelectInput
              source="state"
              label="State"
              choices={[
                {
                  id: 'READY',
                  name: 'READY',
                },
                {
                  id: 'WAITING',
                  name: 'WAITING',
                },
                {
                  id: 'MATCHING',
                  name: 'MATCHING',
                },
                {
                  id: 'PLAYING',
                  name: 'PLAYING',
                },
              ]}
            />
            <TextInput
              source="boy_id"
              label="Boy ID"
              helperText="Must contain between 3 and 20 characters, can only contain letters, numbers and the special characters _-."
            />
            <TextInput
              source="phone_number"
              label="Phone number"
              helperText="With national calling code minus the '+' character. Example: 33123456789"
            />
            <PasswordInput
              source="password"
              label="Password"
              helperText="Password must contain at least 8 characters, one number and one letter"
              autoComplete="none"
            />
            <BooleanInput source="is_admin" label="Is Admin" />
            <BooleanInput source="is_moderator" label="Is Moderator" />
            <BooleanInput source="no_auto_ban" label="No Auto-Ban" />
            <SelectArrayInput
              label="Custom Permissions"
              source="permissions"
              choices={availableRights}
            />
          </SimpleForm>
        </Edit>
      );
    } else {
      return <NotFound title="Access denied" />;
    }
 
};

export default PlayerEdit;
