import {
    InputLabel,
    Typography,
    css,
    useMediaQuery,
    Button as ButtonMUI,
    Dialog,
    DialogTitle,
    Card,
    FormGroup,
    FormHelperText,
    TextField as MUIText,
    Select,
    MenuItem,
    Box,
    DialogContent,
    DialogContentText,
    Input,
    DialogActions,
    CircularProgress,
  } from '@mui/material';
  import { useEffect, useState } from 'react';
  import {
    BooleanField,
    DateField,
    EditButton,
    FunctionField,
    Show,
    ShowProps,
    Tab,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    TextField,
    TopToolbar,
    useGetOne,
    useNotify,
    usePermissions,
    useRecordContext,
    useRefresh,
    Button
  } from 'react-admin';
  import { useParams } from 'react-router-dom';
  import { makeStyles } from 'tss-react/mui';
  
  import BoyAPI from 'services/boy-api';
  import { CancelReasonSelect, Game, Permission, TournamentCancelReason } from 'core/types';
  import { GppBadSharp } from '@mui/icons-material';
  import { ButtonGroup } from 'rsuite';
  import Parser from 'utils/parser';
  
  const useStyles = makeStyles()((theme) => ({
    tabbedShowLayout: {
      [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
        display: 'flex',
      },
    },
    root: {
      marginTop: '32px',
    },
  }));

  
  const ShowActions = (props: any) => {
    const { id } = props;
    //const { classes } = useStyles();
    const { permissions } = usePermissions();

    
    const record = useRecordContext(props);
  
    const [reason, setReason] = useState<string>('');
    const [loading, setLoading] = useState(false);
  
    const [showFormRefund, setShowFormRefund] = useState(false);
    const [showFormReclaim, setShowFormReclaim] = useState(false);
    const [showFormPatchNote, setShowFormPatchNote] = useState(false);
  
    const [isDisableButton, setIsDisableButton] = useState(true);
  

    useEffect(() => {
        if (reason.length <= 0) {
          setIsDisableButton(true);
        } else {
          setIsDisableButton(false);
        }
    }, [reason])
  
  
    const refresh = useRefresh();
    //const notify = useNotify();
  
    const confirmRefundForm = async (refunded : boolean) => {
      setLoading(true);
      try {
        await BoyAPI.CryptoTransaction.refundFailedTransaction(id, reason, refunded);
      } catch (err) {
        //notify('Ban error', { type: 'error' });
        setLoading(false);
        setShowFormRefund(false);
        return;
      }
      setTimeout(function () {
        //notify('Player ban', { type: 'success' });
        setLoading(false);
        refresh();
        setShowFormRefund(false);
      }, 2000);
    };

    const confirmPatchNoteForm = async () => {
      setLoading(true);
      try {
        await BoyAPI.CryptoTransaction.patchTransactionNote(id, reason);
      } catch (err) {
        //notify('Ban error', { type: 'error' });
        setLoading(false);
        setShowFormPatchNote(false);
        return;
      }
      setTimeout(function () {
        //notify('Player ban', { type: 'success' });
        setLoading(false);
        refresh();
        setShowFormPatchNote(false);
      }, 2000);
    };

    const confirmReclaimForm = async () => {
      setLoading(true);
      try {
        await BoyAPI.CryptoTransaction.reclaimFailedTransaction(id, reason);
      } catch (err) {
        setLoading(false);
        setShowFormReclaim(false);
        return;
      }
      setTimeout(function () {
        setLoading(false);
        refresh();
        setShowFormReclaim(false);
      }, 2000);
    };
  
    const { data: currentUser } = useGetOne('player', {
      id: permissions?.user_id,
    });
    if (!record) {
      return null;
    }
  
    return (
      <TopToolbar>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          {(permissions?.admin ||
            currentUser?.permissions?.includes(Permission.BanUnbanUser)) && true && (
            <>
              <Button
                color="primary"
                onClick={() => {
                    setShowFormPatchNote(true);
                  }
                }
                label={'edite note'}
              />
             
              <Dialog
                open={showFormPatchNote}
                onClose={() => {
                  setShowFormPatchNote(false);
                }}
                aria-labelledby="form-dialog-title">
                <Box /*className={classes.dialogSpacing}*/>
                  <DialogTitle id="form-dialog-title">Edite note Form</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Write new note
                    </DialogContentText>
                  </DialogContent>
                  <DialogContent>
                    <InputLabel style={{ fontSize: 12 }}>note</InputLabel>
                    <Input
                      id="noteInput"
                      value={reason}
                      onChange={(event: any) => setReason(event.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    {!loading ? (
                      <>
                        <ButtonMUI onClick={() => {
                            setShowFormPatchNote(false);
                        }} color="secondary">
                          Cancel
                        </ButtonMUI>
                        <ButtonMUI
                          onClick={() => confirmPatchNoteForm()}
                          disabled={isDisableButton}
                          color="primary">
                          Edite player's note
                        </ButtonMUI>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </DialogActions>
                </Box>
              </Dialog>

              <Button
                color="secondary"
                onClick={() => {
                    setShowFormRefund(true);
                  }
                }
                label={'Refund player'}
              />
             
              <Dialog
                open={showFormRefund}
                onClose={() => {
                    setShowFormRefund(false);
                }}
                aria-labelledby="form-dialog-title">
                <Box /*className={classes.dialogSpacing}*/>
                  <DialogTitle id="form-dialog-title">Refund Form</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Write reason for refund
                    </DialogContentText>
                  </DialogContent>
                  <DialogContent>
                    <InputLabel style={{ fontSize: 12 }}>Refund reason</InputLabel>
                    <Input
                      id="banReasonInput"
                      value={reason}
                      onChange={(event: any) => setReason(event.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    {!loading ? (
                      <>
                        <ButtonMUI onClick={() => {
                            setShowFormRefund(false);
                        }} color="secondary">
                          Cancel
                        </ButtonMUI>
                        <ButtonMUI
                          onClick={() => confirmRefundForm(true)}
                          disabled={isDisableButton}
                          color="primary">
                          Refund Player
                        </ButtonMUI>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </DialogActions>
                </Box>
              </Dialog>
              <Button
                color="secondary"
                onClick={() => {
                  setShowFormReclaim(true);
                  }
                }
                label={'Reclaim'}
              />
              <Dialog
                open={showFormReclaim}
                onClose={() => {
                  setShowFormReclaim(false);
                }}
                aria-labelledby="form-dialog-title">
                <Box /*className={classes.dialogSpacing}*/>
                  <DialogTitle id="form-dialog-title">Reclaim Form</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Write reason for reclaim
                    </DialogContentText>
                  </DialogContent>
                  <DialogContent>
                    <InputLabel style={{ fontSize: 12 }}>Reclaim reason</InputLabel>
                    <Input
                      id="banReasonInput"
                      value={reason}
                      onChange={(event: any) => setReason(event.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    {!loading ? (
                      <>
                        <ButtonMUI onClick={() => {
                            setShowFormReclaim(false);
                        }} color="secondary">
                          Cancel
                        </ButtonMUI>
                        <ButtonMUI
                          onClick={() => confirmReclaimForm()}
                          disabled={isDisableButton}
                          color="primary">
                          Reclaim
                        </ButtonMUI>
                      </>
                    ) : (
                      <CircularProgress />
                    )}
                  </DialogActions>
                </Box>
              </Dialog>
            </>
          )}
        </div>
      </TopToolbar>
    );
  };
  
  const TransactionFailedDetail = (props: ShowProps) => {
    const isXlScreen = useMediaQuery('(min-width: 600px)');
    const { id } = useParams();

    const { classes } = useStyles();

    return (
      <Show {...props} actions={<ShowActions id={id} />} className={classes.root}>
        <TabbedShowLayout
          className={classes.tabbedShowLayout}
          //@ts-ignore
          sx={{
            '& .RaTabbedShowLayout-content': css`
              padding: 8px 16px;
              flex: 1;
            `,
          }}
          tabs={
            <TabbedShowLayoutTabs
              orientation={isXlScreen ? 'vertical' : 'horizontal'}
            />
          }>
          <Tab label="summary">
          <TextField source="player_nickname" label="Nickname" />
          <FunctionField
                source="wallet_public_key" label='wallet'
                render={record => {
                    if (record.wallet_public_key) {
                      const { REACT_APP_ENV } = process.env;
                      return (
                        <a target="_blank" href={`https://solscan.io/account/${record.wallet_public_key}${REACT_APP_ENV === "dev" ? "?cluster=devnet" : ''}`} style={{color : "red"}} >
                            {`${record.wallet_public_key}`}
                        </a>
                      );
                    } else {
                      return  null;
                    }

                } }
            />
          <TextField source="amount" />
          <TextField source="unit_symbol" label="unit" />
          {<DateField source="purchased_at" />}
          <FunctionField
                source="data" label='refund link'
                render={record => {
                    if (record.data.refund_txn_sig) {
                      const { REACT_APP_ENV } = process.env;
                      return (
                        <a target="_blank" href={`https://solscan.io/tx/${record.data.refund_txn_sig}${REACT_APP_ENV === "dev" ? "?cluster=devnet" : ''}`} style={{color : "red"}} >
                            {"link to solscan transaction"}
                        </a>
                      );
                    } else {
                      return  null;
                    }

                } }
            />
            <FunctionField
                source="data" label='data'
                render={record => {
                      return (
                         `${JSON.stringify(record.data)}`
                      );

                } }
            />
         <FunctionField
            //style={{width : '150px'}}
                source="data" label='is raffle'
                render={record => {
                    return `${record.data.is_raffle}`;
                } }
            />
          {/*<TextField maxWidth={150} source="encoded_transaction" />*/}
          <TextField source="refunded" />
          <TextField source="refunded_by" />
          <TextField source="refunded_by_nickname" />
          {<FunctionField
                label='purchased_at'
                render={record => {
                  const data = record.purchased_at;
                  const d = new Date(data)
                  const format = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  return <p>{ d.getDate() !== null ? format : data  }</p>
                } }
              />}
          <TextField source="reclaimed" />
          <TextField source="reclaimed_by" />
          <TextField source="reclaimed_by_nickname" />
          {<FunctionField
                label='reclaimed_at'
                render={record => {
                  const data = record.reclaimed_at;
                  const d = new Date(data)
                  const format = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  return <p>{ d.getDate() !== null ? format : data  }</p>
                } }
              />}
          <TextField source="edited_by" />
          <TextField source="edited_by_nickname" />
          {<FunctionField
                label='edit at'
                render={record => {
                  const data = record.refunded_at ? record.refunded_at : record.reclaimed_at ? record.reclaimed_at : record.edited_at;
                  const d = new Date(data)
                  const format = [d.getMonth()+1,
                    d.getDate(),
                    d.getFullYear()].join('/')+' '+
                   [d.getHours(),
                    d.getMinutes(),
                    d.getSeconds()].join(':');
                  return <p>{ d.getDate() !== null ? format : data  }</p>
                } }
              />}
          <TextField source="note" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  };
  
  export default TransactionFailedDetail;
  