import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import MatchChart from './MatchChart';
import MatchInProgressCard from './MatchInProgressCard';
import PendingReviewCard from './PendingReviewCard';
import PendingWinnerCard from './PendingWinnerCard';
import MatchPerGameChart from './MatchPerGameChart';
import StatisticsGrid from './StatisticsGrid';
import useCurrentPlayer from 'hooks/use-current-player';

const useStyles = makeStyles()((theme) => ({
  item: {
    paddingBottom: 24,
    alignItems: 'stretch',
    display: 'flex',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      paddingRight: 24,
    },
  },
}));
const Dashboard = () => {
  const { classes } = useStyles();
  const { player } = useCurrentPlayer();

  const cards = [
    <MatchInProgressCard />,
    <PendingReviewCard />,
    <PendingWinnerCard />,
  ];
  return (
    <Grid container style={{ padding: '24px 16px' }}>
      <Grid item xs={12} md={8}>
        <Grid container>
          {cards
            .filter((a) => !!a)
            .map(
              (item, i) =>
                item && (
                  <Grid item xs={12} md={4} className={classes.item} key={i}>
                    {item}
                  </Grid>
                ),
            )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <StatisticsGrid player={player} />
      </Grid>

      <Grid item xs={12} md={12}>
        <MatchPerGameChart />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
