import RsuiteDateRangePicker, {
  DateRangePickerProps,
} from 'rsuite/lib/DateRangePicker';

import 'rsuite/dist/styles/rsuite-dark.min.css';
import { subDays } from 'date-fns';

const DateRangePicker = (props: DateRangePickerProps) => {
  return (
    <RsuiteDateRangePicker
      defaultValue={[subDays(new Date(), 7), new Date()]}
      {...props}
    />
  );
};

export default DateRangePicker;
