import { NotificationType } from 'react-admin';
import {
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  Typography,
} from '@mui/material';
import { OrderStore } from 'core/types';
import { Check, Close } from '@mui/icons-material';
import { GetCountry } from 'utils/countries';
import { Cipher } from 'crypto';

const Parser = {
  error(err: {
    response?: { data?: { error?: string; detail?: string }; status?: number };
  }): [string, { type: NotificationType }] {
    if (err.response === undefined) {
      return ['Unknown error', { type: 'error' }];
    }

    return [
      err.response.data?.error || err.response.data?.detail || 'Unknown error',
      {
        type: (err.response.status || 500) < 500 ? 'warning' : 'error',
      },
    ];
  },
};
const Tools = {
  navActions(
    setPage: (page: number) => void,
    currentPage: number,
    nbPage: number,
  ): (subProps: any) => any {
    return (subProps: any) => {
      const {
        page,
        count,
        rowsPerPage,
        backIconButtonProps,
        nextIconButtonProps,
        showLastButton,
        getItemAriaLabel,
        showFirstButton,
        onPageChange,
        ...restSubProps
      } = subProps;
      return (
        <>
          <div
            style={{ margin: '0 10px', flexDirection: 'row', display: 'flex' }}>
            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{ flex: '0.3 0.1 auto' }}>
              <Button
                onClick={() => {
                  setPage(1);
                }}
                disabled={currentPage === 1}>
                {'<<'}
              </Button>
              <Button
                onClick={() => {
                  setPage(currentPage - 1);
                }}
                disabled={currentPage === 1}>
                {'<'}
              </Button>
            </ButtonGroup>
            <Select
              size="small"
              onChange={(e: any) => {
                e?.preventDefault();
                setPage(e.target.value);
              }}
              value={currentPage}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 360 } },
              }}>
              {[...Array(nbPage).keys()].map((i) => {
                return (
                  <MenuItem id={i} key={i} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                );
              })}
            </Select>
            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{ flex: '0.3 0.1 auto' }}>
              <Button disabled>/{nbPage}</Button>
              <Button
                onClick={() => {
                  setPage(currentPage + 1);
                }}
                disabled={currentPage === nbPage}>
                {'>'}
              </Button>
              <Button
                onClick={() => {
                  setPage(nbPage);
                }}
                disabled={currentPage === nbPage}>
                {'>>'}
              </Button>
            </ButtonGroup>
          </div>
        </>
      );
    };
  },
  addOrder(
    orderBy: OrderStore | undefined,
    setOrderBy: (OrderStore) => void,
  ): (key: string) => void {
    return (key: string) => {
      let newOrderBy = { ...orderBy } || {};
      newOrderBy[key] = key in newOrderBy ? !newOrderBy[key] : true;
      setOrderBy(newOrderBy);
    };
  },
  removeOrder(
    orderBy: OrderStore | undefined,
    setOrderBy: (OrderStore) => void,
  ): (key: string) => void {
    return (key: string) => {
      if (!orderBy || !(key in orderBy)) {
        return;
      }

      let newOrderBy = { ...orderBy };
      delete newOrderBy[key];
      setOrderBy(newOrderBy);
    };
  },
};

const Color = {
  bool(val: boolean, inverse: boolean = false): string {
    if ((val && !inverse) || (!val && inverse)) {
      return '#2cac51';
    }

    return '#B94C6D';
  },
};
const Render = {
  bool(val: boolean, inverse: boolean = false) {
    if ((val && !inverse) || (!val && inverse)) {
      return <Check />;
    }

    return <Close />;
  },
  country(key: string): string {
    const country = GetCountry(key);
    return country ? country.flag : '🏴‍☠️';
  },
};

const TextField = ({ label, data }: { label?: string; data?: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '10px',
      }}>
      <Typography
        sx={{
          textTransform: 'capitalize',
        }}
        color="textSecondary"
        component="p"
        variant="h6">
        {label}
      </Typography>
      <Typography component="span" variant="body1">
        {data}
      </Typography>
    </div>
  );
};

const ObjectField = ({ label, data }: { label?: string; data?: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: '10px',
      }}>
      <Typography
        sx={{ textTransform: 'capitalize' }}
        color="textSecondary"
        component="p"
        variant="h6">
        {label}
      </Typography>
      {data &&
        Object.entries(data).map(([key, val]) => {
          return (
            <Typography component="span" variant="body1">
              {key}: {val}
            </Typography>
          );
        })}
    </div>
  );
};

const BoolField = ({ label, data }: { label?: string; data?: boolean }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        // alignItems: '',
        paddingTop: '10px',
      }}>
      <Typography
        sx={{ paddingRight: '10px', textTransform: 'capitalize' }}
        color="textSecondary"
        component="p"
        variant="h6">
        {label}
      </Typography>
      <Typography
        color={Color.bool(data || false)}
        component="span"
        variant="body1">
        {Render.bool(data || false)}
      </Typography>
    </div>
  );
};

export { Parser, Tools, Color, Render, ObjectField, TextField, BoolField };
