import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import ArgentImg from '../../../assets/images/badges/badge-argent.png';
import CadeauxImg from '../../../assets/images/badges/badge-cadeaux.png';
import CuivreImg from '../../../assets/images/badges/badge-cuivre.png';
import GameImg from '../../../assets/images/badges/badge-game.png';
import MatchsImg from '../../../assets/images/badges/badge-matchs.png';
import OctocoinsImg from '../../../assets/images/badges/badge-octocoins.png';
import OrImg from '../../../assets/images/badges/badge-or.png';
import FirstplayerImg from '../../../assets/images/badges/badge-firstplayer.png';
import OldwinnerImg from '../../../assets/images/badges/badge-oldwinner.png';
import EarlyadopterImg from '../../../assets/images/badges/badge-earlyadopter.png';

const ASSETS = {
  argent: ArgentImg,
  cadeaux: CadeauxImg,
  cuivre: CuivreImg,
  game: GameImg,
  matchs: MatchsImg,
  octocoins: OctocoinsImg,
  or: OrImg,
  firstplayer: FirstplayerImg,
  oldwinner: OldwinnerImg,
  earlyadopter: EarlyadopterImg,
};

const useStyles = makeStyles<{ size: number }>()((theme, { size }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  badgeImgContainer: {
    position: 'relative',
  },
  badgeImg: {
    height: size,
    width: size,
  },
  badgeText: {
    position: 'absolute',
    fontSize: size / 4,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  badgeTitle: {
    fontSize: 7,
    marginTop: 4,
    textTransform: 'uppercase',
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,
  },
}));

type Props = {
  type: PlayerBadgeType;
  size?: number;
  centerText?: string;
  title?: Title;
};

type Title = string | JSX.Element;

function isTitleAString(tbd: Title | undefined): tbd is string {
  return !!(tbd as string);
}

function isTitleAJSXElement(tbd: Title | undefined): tbd is JSX.Element {
  return !!(tbd as JSX.Element);
}

const PlayerBadge = ({ type, size = 40, centerText, title }: Props) => {
  const { classes } = useStyles({ size });

  const memoizedTitleComponent = useMemo(() => {
    if (isTitleAString(title)) return <>{title}</>;
    else if (isTitleAJSXElement(title)) return title;
    else return undefined;
  }, [title]);

  return (
    <div className={classes.main}>
      <div className={classes.badgeImgContainer}>
        {centerText && <div className={classes.badgeText}>{centerText}</div>}
        <img src={ASSETS[type]} className={classes.badgeImg} alt="badge-img" />
      </div>
      {title && (
        <div className={classes.badgeTitle}>{memoizedTitleComponent}</div>
      )}
    </div>
  );
};

export type PlayerBadgeType = keyof typeof ASSETS;
export default PlayerBadge;
