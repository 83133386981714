import {
  Card,
  Grid,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  DataGrid,
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import NumberCard from 'components/data/NumberCard';
import { ReferralCountByDay } from 'core/types';
import BoyAPI from 'services/boy-api';
import DateRangePicker from 'components/common/DateRangePicker';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  item: {
    paddingBottom: 24,
    alignItems: 'stretch',
    display: 'flex',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      paddingRight: 24,
    },
  },
}));

function CustomToolbar(props) {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport
        csvOptions={{ fileName: props.title }}
        printOptions={{ fileName: props.title }}
      />
    </GridToolbarContainer>
  );
}

const getFirstDate = () => {
  var prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 15);
  return prevDate;
};

export const SocialStat = () => {
  const { classes } = useStyles();
  const [referralCountByDayList, setReferralCountByDayList] = useState<
    ReferralCountByDay[]
  >();
  const [referralCountByDayIsReady, setReferralCountByDayIsReady] = useState(
    false,
  );

  const [referralCount, setReferralCount] = useState<number>(0);
  const [referralCountIsReady, setReferralCountIsReady] = useState(false);

  const [referralValidatedCount, setReferralValidatedCount] = useState<number>(
    0,
  );
  const [
    referralValidatedCountIsReady,
    setReferralValidatedCountIsReady,
  ] = useState(false);

  const [referralDistinctCount, setReferralDistinctCount] = useState<number>(0);
  const [
    referralDistinctCountIsReady,
    setReferralDistinctCountIsReady,
  ] = useState(false);

  const columnsCountByDay: GridColumns = [
    { field: 'id', headerName: 'Date', width: 250 },
    { field: 'count', headerName: 'Nb', width: 250 },
  ];

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstDate(),
    new Date(),
  ]);

  useEffect(() => {
    (async () => {
      setReferralCountByDayIsReady(false);

      let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[0],
      );
      let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[0],
      );
      let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[0],
      );
      let begindate = `${ye1}-${mo1}-${da1}`;

      let ye2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[1],
      );
      let mo2 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[1],
      );
      let da2 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[1],
      );
      let enddate = `${ye2}-${mo2}-${da2}`;

      BoyAPI.Statistic.getReferralCountByDay(begindate, enddate).then(
        (resp) => {
          setReferralCountByDayList(resp);
          setReferralCountByDayIsReady(true);
        },
      );
    })();
  }, [dateRange]);

  useEffect(() => {
    (async () => {
      setReferralCountIsReady(false);
      setReferralValidatedCountIsReady(false);
      setReferralDistinctCountIsReady(false);

      BoyAPI.Statistic.getReferralCount().then((data) => {
        setReferralCount(data);
        setReferralCountIsReady(true);
      });

      BoyAPI.Statistic.getReferralValidatedCount().then((data) => {
        setReferralValidatedCount(data);
        setReferralValidatedCountIsReady(true);
      });

      BoyAPI.Statistic.getReferralDistinctCount().then((data) => {
        setReferralDistinctCount(data);
        setReferralDistinctCountIsReady(true);
      });
    })();
  }, []);

  return (
    <>
      <Grid container style={{ padding: '24px 16px' }}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={12} md={4} className={classes.item} key={0}>
              {!referralCountIsReady ? (
                <CircularProgress />
              ) : (
                <NumberCard
                  title={'Total des parrainages'}
                  number={referralCount}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.item} key={1}>
              {!referralValidatedCountIsReady ? (
                <CircularProgress />
              ) : (
                <NumberCard
                  title={'Parrainages validés'}
                  number={referralValidatedCount}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4} className={classes.item} key={2}>
              {!referralDistinctCountIsReady ? (
                <CircularProgress />
              ) : (
                <NumberCard
                  title={'Parrains uniques'}
                  number={referralDistinctCount}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ margin: '16px 0', textAlign: 'left' }}>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            if (!value[0] || !value[1]) {
              return;
            }
            setDateRange([value[0], value[1]]);
          }}
        />
      </div>
      <Card className={classes.root}>
        <CardContent>
          {!referralCountByDayIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Parrainages par jour
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'referral_count_by_day' },
                  }}
                  rows={referralCountByDayList || []}
                  columns={columnsCountByDay}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'id',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SocialStat;
