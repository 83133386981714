import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TablePagination,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Shop } from 'core/types';
import React, { useEffect, useMemo, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import BoyAPI from 'services/boy-api';
import { Color, Parser, Render, Tools } from '../common';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const DEFAULT_COLUMNS = [
  { field: 'flag', headerName: 'Flag' },
  { field: 'name', headerName: 'Name' },
  { field: 'money_unit', headerName: 'Money unit' },
  { field: 'is_active', headerName: 'Active?' },
  { field: 'is_global', headerName: 'Global?' },
];

const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },

  clickableCell: {
    cursor: 'pointer',
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '2px',
    },
  },
});
function Row({ row }: { row: Shop }) {
  const classes = useRowStyles();
  const navigate = useNavigate();

  const navigateToShop = (id: string) => {
    navigate(`/shop/${id}`, { relative: 'path' });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {Render.country(row.flag.toUpperCase())}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.money_unit}</TableCell>
        <TableCell style={{ color: Color.bool(row.is_active) }}>
          {Render.bool(row.is_active)}
        </TableCell>
        <TableCell style={{ color: Color.bool(row.is_global) }}>
          {Render.bool(row.is_global)}
        </TableCell>
        <TableCell>
          <Button onClick={() => navigateToShop(row.id)}>
            <Visibility /> Show
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ProductList = ({ shopId }: { shopId?: string }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(true);
  const [shops, setShops] = useState<Shop[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItemPerPages, setCurrentItemPerPage] = useState<number>(25);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [nbPage, setNbPage] = useState<number>(1);

  const columns = useMemo(() => [...DEFAULT_COLUMNS], []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        let res = await BoyAPI.Shop.list(currentPage, currentItemPerPages);

        setTotalItems(res.data.total);
        setNbPage(res.data.total_page);

        if (currentPage > nbPage) {
          setCurrentPage(1);
        } else {
          setShops(res.data.items);
        }
      } catch (error) {
        const [msg, type] = Parser.error(error);
        notify(msg, type);
      }

      setLoading(false);
    })();
  }, [notify, currentPage, nbPage, currentItemPerPages]);

  return (
    <>
      {!shopId && <Title title={`Products`} />}
      <Table aria-label="collapsible table">
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
            setCurrentItemPerPage(+e.target.value);
          }}
          onPageChange={(e, page) => {
            e?.preventDefault();
            setCurrentPage(page + 1);
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Tools.navActions(
            setCurrentPage,
            currentPage,
            nbPage,
          )}
        />
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.field}
              sx={{ cursor: column.sortKey ? 'pointer' : 'inherit' }}>
              <label for={column.field + '_select'}>{column.headerName}</label>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {shops.map((row, ind) => (
                <Row key={ind} row={row} />
              ))}
            </>
          )}
        </TableBody>
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
            setCurrentItemPerPage(+e.target.value);
          }}
          onPageChange={(e, page) => {
            e?.preventDefault();
            setCurrentPage(page + 1);
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Tools.navActions(
            setCurrentPage,
            currentPage,
            nbPage,
          )}
        />
      </Table>
    </>
  );
};

export default ProductList;
