import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Button, { ButtonProps } from 'components/common/Button';

type Props = {
  title: string;
  number: number | string | false;
  href?: string;
  button?: Partial<ButtonProps>;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    padding: '16px 24px 32px 32px',
  },
  numberText: {
    fontSize: 48,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    display: 'block',
  },
  title: {
    fontSize: 18,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textDecoration: 'none',
    display: 'block',
  },
  button: {
    marginTop: 8,
    width: '100%',
  },
}));

const NumberCard = ({ title, number, href, button }: Props) => {
 const { classes } = useStyles();
  const hrefProps = href
    ? {
        component: 'a',
        href,
      }
    : {};
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography
          variant="h5"
          className={classes.numberText}
          align="left"
          {...hrefProps}>
          {number === false ? <CircularProgress /> : number}
        </Typography>
        <Typography
          {...hrefProps}
          color="textSecondary"
          align="left"
          className={classes.title}>
          {title}
        </Typography>
        {button && (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            {...button}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default NumberCard;
