import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { MatchModeCountByDayData, Search2v2CountByDayData } from 'core/types';
import BoyAPI from 'services/boy-api';
import DateRangePicker from 'components/common/DateRangePicker';
import { Bar } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';

function CustomToolbar(props) {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport
        csvOptions={{ fileName: props.title }}
        printOptions={{ fileName: props.title }}
      />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const getFirstDate = () => {
  var prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 15);
  return prevDate;
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const sortObjectByValue = (obj) => {
  return Object.keys(obj)
    .sort((a, b) => obj[b] - obj[a])
    .reduce((acc, cur) => {
      acc[cur] = obj[cur];
      return acc;
    }, {});
};

export const MatchDistribution = () => {
  const { classes } = useStyles();

  const columnsMatchByModeData = [
    { field: 'id', headerName: 'Id', width: 250, hide: true },
    { field: 'date', headerName: 'Date', width: 250 },
    {
      field: 'mode',
      headerName: 'Mode',
      width: 250,
      valueFormatter: (params) => (params.value === 1 ? '1v1' : '2v2'),
    },
    { field: 'count', headerName: 'Nb', width: 250 },
  ];

  const columnsSearch2v2Data = [
    { field: 'id', headerName: 'Id', width: 250, hide: true },
    { field: 'date', headerName: 'Date', width: 250 },
    {
      field: 'from_invitation',
      headerName: 'Type',
      width: 250,
      valueFormatter: (params) => (params.value ? 'Invitation' : 'Matching'),
    },
    { field: 'state', headerName: 'Etat', width: 250 },
    { field: 'match_count', headerName: 'Nb de matchs', width: 250 },
    { field: 'count', headerName: 'Nb de recherches', width: 250 },
  ];

  const [matchByModeDataList, setMatchByModeDataList] = useState<
    MatchModeCountByDayData[]
  >();
  const [matchByModeDataListIsReady, setMatchByModeDataListIsReady] = useState(
    false,
  );
  const [
    search2v2CountByDayDataList,
    setSearch2v2CountByDayDataList,
  ] = useState<Search2v2CountByDayData[]>();
  const [
    search2v2CountByDayDataListIsReady,
    setSearch2v2CountByDayDataListIsReady,
  ] = useState(false);
  const [matchByGameDataList, setMatchByGameDataList] = useState<any>();
  const [matchByGameDataListIsReady, setMatchByGameDataListIsReady] = useState(
    false,
  );

  useEffect(() => {
    //console.log('matchByGameDataList', matchByGameDataList);
  }, [matchByGameDataList]);
  const [matchByGameModeDataList, setMatchByGameModeDataList] = useState<any>();

  const [matchByPlatformDataList, setMatchByPlatformDataList] = useState<any>();
  const [
    matchByPlatformDataListIsReady,
    setMatchByPlatformDataListIsReady,
  ] = useState(false);

  const [
    matchByGameAndPlatformDataList,
    setMatchByGameAndPlatformDataList,
  ] = useState<any>();
  const [
    matchByGameAndPlatformDataListIsReady,
    setMatchByGameAndPlatformDataListIsReady,
  ] = useState(false);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstDate(),
    new Date(),
  ]);

  useEffect(() => {
    (async () => {
      setMatchByGameDataListIsReady(false);
      let ye1 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[0],
      );
      let mo1 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[0],
      );
      let da1 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[0],
      );
      let begindate = `${ye1}-${mo1}-${da1}`;

      let ye2 = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
        dateRange[1],
      );
      let mo2 = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
        dateRange[1],
      );
      let da2 = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
        dateRange[1],
      );
      let enddate = `${ye2}-${mo2}-${da2}`;

      BoyAPI.Statistic.getMatchModeCountByDay(begindate, enddate).then(
        (resp) => {
          setMatchByModeDataList(resp);
          setMatchByModeDataListIsReady(true);
        },
      );

      BoyAPI.Statistic.getCountSearch2v2ByDay(begindate, enddate).then(
        (resp) => {
          setSearch2v2CountByDayDataList(resp);
          setSearch2v2CountByDayDataListIsReady(true);
        },
      );

      BoyAPI.Statistic.getMatchDistributionByGame(begindate, enddate).then(
        (resp) => {
          var MatchByGamesData = resp.reduce<{ [key: string]: number }>(
            (p, c) => {
              if (!p.hasOwnProperty(c.game_id)) {
                p[c.game_id] = 0;
              }
              p[c.game_id] = p[c.game_id] + c.count;
              return p;
            },
            {},
          );

          MatchByGamesData = sortObjectByValue(MatchByGamesData);

          var barData = {
            labels: Object.keys(MatchByGamesData),
            datasets: [
              {
                label: 'Nombre de matchs',
                data: Object.values(MatchByGamesData),
                backgroundColor: 'rgba(75,192,192,0.4)',
              },
            ],
          };

          var MatchByGameModesData = resp.reduce<{ [key: string]: number }>(
            (p, c) => {
              if (!p.hasOwnProperty(c.game_mode_id)) {
                p[c.game_mode_id] = 0;
              }
              p[c.game_mode_id] = p[c.game_mode_id] + c.count;
              return p;
            },
            {},
          );

          MatchByGameModesData = sortObjectByValue(MatchByGameModesData);

          var barDataMode = {
            labels: Object.keys(MatchByGameModesData),
            datasets: [
              {
                label: 'Nombre de matchs',
                data: Object.values(MatchByGameModesData),
                backgroundColor: 'rgba(75,192,192,0.4)',
              },
            ],
          };

          setMatchByGameDataList(barData);
          setMatchByGameModeDataList(barDataMode);
          setMatchByGameDataListIsReady(true);
        },
      );

      BoyAPI.Statistic.getMatchDistributionByPlatform(begindate, enddate).then(
        (resp) => {
          var MatchByPlatformData = resp.reduce<{ [key: string]: number }>(
            (p, c) => {
              if (!p.hasOwnProperty(c.platform_id)) {
                p[c.platform_id] = 0;
              }
              p[c.platform_id] = p[c.platform_id] + c.count;
              return p;
            },
            {},
          );

          MatchByPlatformData = sortObjectByValue(MatchByPlatformData);

          var barData = {
            labels: Object.keys(MatchByPlatformData),
            datasets: [
              {
                label: 'Nombre de matchs',
                data: Object.values(MatchByPlatformData),
                backgroundColor: 'rgba(75,192,192,0.4)',
              },
            ],
          };

          setMatchByPlatformDataList(barData);
          setMatchByPlatformDataListIsReady(true);
        },
      );

      BoyAPI.Statistic.getMatchDistributionByGameAndPlatform(
        begindate,
        enddate,
      ).then((resp) => {
        var MatchByPlatformData = resp.reduce<{ [key: string]: number }>(
          (p, c) => {
            var id = c.game_id + ' + ' + c.platform_id;
            if (!p.hasOwnProperty(id)) {
              p[id] = 0;
            }
            p[id] = p[id] + c.count;
            return p;
          },
          {},
        );

        MatchByPlatformData = sortObjectByValue(MatchByPlatformData);

        var barData = {
          labels: Object.keys(MatchByPlatformData),
          datasets: [
            {
              label: 'Nombre de matchs',
              data: Object.values(MatchByPlatformData),
              backgroundColor: 'rgba(75,192,192,0.4)',
            },
          ],
        };

        setMatchByGameAndPlatformDataList(barData);
        setMatchByGameAndPlatformDataListIsReady(true);
      });
    })();
  }, [dateRange]);

  return (
    <>
      <div style={{ margin: '16px 0', textAlign: 'left' }}>
        <DateRangePicker
          value={dateRange}
          onChange={(value) => {
            if (!value[0] || !value[1]) {
              return;
            }
            setDateRange([value[0], value[1]]);
          }}
        />
      </div>
      <Card className={classes.root}>
        {!matchByGameDataListIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color="textSecondary"
              align="left"
              className={classes.title}>
              <CardHeader
                title={'Matchs par jeux'}
                action={
                  <CSVLink
                    filename={'matchByGameDataList.csv'}
                    data={[
                      matchByGameDataList.labels,
                      matchByGameDataList.datasets[0].data,
                    ]}>
                    EXPORT
                  </CSVLink>
                }
              />
            </Typography>
            <CardContent>
              <div style={{ height: '100%', width: '100%' }}>
                <Bar data={matchByGameDataList} options={options} />
              </div>
            </CardContent>
          </>
        )}
      </Card>

      <Card className={classes.root}>
        {!matchByGameDataListIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color="textSecondary"
              align="left"
              className={classes.title}>
              <CardHeader
                title={'Matchs par mode de jeu'}
                action={
                  <CSVLink
                    filename={'matchByGameModeDataList.csv'}
                    data={[
                      matchByGameModeDataList.labels,
                      matchByGameModeDataList.datasets[0].data,
                    ]}>
                    EXPORT
                  </CSVLink>
                }
              />
            </Typography>
            <CardContent>
              <div style={{ height: '100%', width: '100%' }}>
                <Bar data={matchByGameModeDataList} options={options} />
              </div>
            </CardContent>
          </>
        )}
      </Card>

      <Card className={classes.root}>
        {!matchByPlatformDataListIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color="textSecondary"
              align="left"
              className={classes.title}>
              <CardHeader
                title={'Matchs par plateformes'}
                action={
                  <CSVLink
                    filename={'matchByPlatformDataList.csv'}
                    data={[
                      matchByPlatformDataList.labels,
                      matchByPlatformDataList.datasets[0].data,
                    ]}>
                    EXPORT
                  </CSVLink>
                }
              />
            </Typography>
            <CardContent>
              <div style={{ height: '100%', width: '100%' }}>
                <Bar data={matchByPlatformDataList} options={options} />
              </div>
            </CardContent>
          </>
        )}
      </Card>

      <Card className={classes.root}>
        {!matchByGameAndPlatformDataListIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color="textSecondary"
              align="left"
              className={classes.title}>
              <CardHeader
                title={'Matchs par jeux et plateformes'}
                action={
                  <CSVLink
                    filename={'matchByGameAndPlatformDataList.csv'}
                    data={[
                      matchByGameAndPlatformDataList.labels,
                      matchByGameAndPlatformDataList.datasets[0].data,
                    ]}>
                    EXPORT
                  </CSVLink>
                }
              />
            </Typography>
            <CardContent>
              <div style={{ height: '100%', width: '100%' }}>
                <Bar data={matchByGameAndPlatformDataList} options={options} />
              </div>
            </CardContent>
          </>
        )}
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!matchByModeDataListIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Nombre de matchs 1v1 et 2v2 par jour
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'match_1v1_and_2v2_per_day' },
                  }}
                  rows={matchByModeDataList || []}
                  columns={columnsMatchByModeData}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'id',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
      <Card className={classes.root}>
        <CardContent>
          {!search2v2CountByDayDataListIsReady ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                color="textSecondary"
                align="left"
                className={classes.title}>
                Nombre de recherches 2v2 par jour
              </Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'search_2v2_per_day' },
                  }}
                  rows={search2v2CountByDayDataList || []}
                  columns={columnsSearch2v2Data}
                  pageSize={20}
                  sortModel={[
                    {
                      field: 'id',
                      sort: 'desc',
                    },
                  ]}
                />
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default MatchDistribution;
