import {
  Button,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import BoyAPI from 'services/boy-api';
import { InputLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import LinkIcon from '@mui/icons-material/Link';

const PlayerField = () => {
  const record = useRecordContext();
  return (
    <>
      <InputLabel style={{ fontSize: 12 }}>Player Id</InputLabel>
      <a
        href={`#/player/${record.player_id}/show`}
        style={{
          textDecoration: 'none',
          display: 'flex',
          color: 'inherit',
          flexDirection: 'row',
        }}>
        <Typography style={{ fontSize: '0.875rem' }}>
          {record.player_id}
        </Typography>{' '}
        <LinkIcon />
      </a>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  card: {
    margin: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      margin: 0,
    },
  },
  cardContent: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      height: 300,
      display: 'flex',
      flexWrap: 'wrap',
    },
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
}));

const SaleDetail = (props: ShowProps) => {
  const { classes } = useStyles();
  return (
    <Show
      {...props}
      classes={{
        card: classes.card,
      }}>
      <SimpleShowLayout className={classes.cardContent}>
        <TextField source="id" />
        <PlayerField source="player_id" />
      </SimpleShowLayout>
    </Show>
  );
};

export default SaleDetail;
