import {
  CircularProgress,
  Tooltip,
  useMediaQuery,
  TablePagination,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { DataGrid } from '@mui/x-data-grid';
import { Identifiers, MatchHistory } from 'core/types';
import { useRedirect } from 'react-admin';

import { ListItem, Chip, ListItemText } from '@mui/material';
import { useMemo, useState } from 'react';
import { isBefore } from 'date-fns/esm';
import clsx from 'clsx';
import playerUtils from 'utils/player';
import { getForfeitLabel } from 'pages/Match/MatchDetail/ForfeitReason';

const useStyles = makeStyles()({
  '@global': {
    '.MuiDataGrid-window': {
      overflowY: 'hidden !important',
    },
  },
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  potentialCheat: {
    backgroundColor: '#424482e0',
  },
  clickableCell: {
    cursor: 'pointer',
  },
  potentialCheatListItem: {
    backgroundColor: '#424482e0',
    '&:hover': {
      backgroundColor: '#42448282',
    },
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tertiary: { float: 'right', opacity: 0.541176 },
  cheatChip: {
    position: 'absolute',
    bottom: 8,
    right: 8,
  },
  listItem: {
    paddingBottom: 48,
  },
});

export const renderReportCell = (params: any): JSX.Element => {
  if (params.value === 'FORFEIT') {
    const forfeitReason = getForfeitLabel(
      params.row.forfeit_reason,
      params.row.mode,
    );
    return (
      <Tooltip title={`${params.value} (reason: ${forfeitReason})`}>
        <span>
          {params.value}&nbsp;(reason: {forfeitReason})
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={params.value}>
      <span>{params.value}</span>
    </Tooltip>
  );
};

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

export const IdentifiersCell = ({
  identifiers,
}: {
  identifiers: Identifiers;
}) => {
  return identifiers ? (
    <Tooltip
      title={playerUtils.identifiersToString(identifiers).map((a) => (
        <>
          {a}
          <br />
        </>
      ))}>
      <span>{playerUtils.identifiersToString(identifiers)}</span>
    </Tooltip>
  ) : null;
};

const DEFAULT_PAGE_SIZE = 25;
const MatchHistoryDataGrid = ({ matchHistoryList, isReady }: Props) => {
  const { classes } = useStyles();

  const potentialCheatCellClassName = (params: any) =>
    params.row.potentialCheat.length > 0 &&
    params.row.result.startsWith('VICTORY')
      ? ''
      : '';
  const columns = [
    {
      field: 'opponent_boy_id',
      headerName: 'Opponent',
      width: 100,
      cellClassName: (params: any) =>
        clsx(potentialCheatCellClassName(params), classes.clickableCell),
      renderCell: (params: any) => (
        <a href={`/#/player/${params.row.opponent_id}/show`}>{params.value}</a>
      ),
    },
    {
      field: 'game_id',
      headerName: 'Jeu',
      width: 180,
      cellClassName: potentialCheatCellClassName,
    },

    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      cellClassName: potentialCheatCellClassName,
    },
    {
      field: 'id',
      headerName: 'Match',
      width: 230,
      cellClassName: (params: any) =>
        clsx(potentialCheatCellClassName(params), classes.clickableCell),
    },
    {
      field: 'amount',
      headerName: 'Montant',
      width: 150,
      cellClassName: potentialCheatCellClassName,
    },

    {
      field: 'result',
      headerName: 'Resultat',
      width: 200,
      cellClassName: potentialCheatCellClassName,
    },
    {
      field: 'opponent_result',
      headerName: 'Resultat Adversaire',
      width: 200,
      cellClassName: potentialCheatCellClassName,
    },

    {
      field: 'is_boosted',
      headerName: 'Boost',
      width: 130,
      cellClassName: potentialCheatCellClassName,
    },
  ];

  const redirect = useRedirect();
  const matches = useMediaQuery('(min-width:600px)');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);

  const sortedMatchHistoryList = useMemo(
    () =>
      [...matchHistoryList].sort((m1, m2) =>
        isBefore(
          m1.start_match_timestamp.toDate(),
          m2.start_match_timestamp.toDate(),
        )
          ? 1
          : -1,
      ),
    [matchHistoryList],
  );

  if (!isReady) {
    return <CircularProgress />;
  }

  if (matches) {
    return (
      <div
        style={{
          height: `${currentPageSize * 30}px`,
          width: '100%',
        }}>
        <DataGrid
          rows={sortedMatchHistoryList}
          columns={columns}
          rowHeight={30}
          onPageSizeChange={(size) => {
            setCurrentPageSize(size);
          }}
          pageSize={currentPageSize}
        />
      </div>
    );
  }

  return (
    <>
      {sortedMatchHistoryList
        .slice(
          currentPage * currentPageSize,
          (currentPage + 1) * currentPageSize,
        )
        .map((row) => (
          <ListItem
            className={clsx(
              classes.listItem,
              row.potentialCheat ? classes.potentialCheatListItem : undefined,
            )}
            button
            key={row.id}
            onClick={() => {
              redirect(`/match/${row.id}/show`);
            }}>
            <ListItemText
              primary={
                <div>
                  {row.opponent_boy_id}
                  <span className={classes.tertiary}>{row.date}</span>
                  {row.potentialCheat && (
                    <Chip
                      color="secondary"
                      label={row.potentialCheat}
                      className={classes.cheatChip}
                    />
                  )}
                </div>
              }
              secondary={row.game_id}
            />
          </ListItem>
        ))}
      <TablePagination
        component="span"
        count={matchHistoryList.length}
        page={currentPage}
        onPageChange={(_, page) => {
          setCurrentPage(page);
        }}
        onRowsPerPageChange={(e) => {
          setCurrentPageSize(Number.parseInt(e.target.value, 10));
        }}
        rowsPerPageOptions={[25, 50, 100]}
        rowsPerPage={currentPageSize}
      />
    </>
  );
};

export default MatchHistoryDataGrid;
