import {
  Select,
  InputLabel,
  Input,
  FormHelperText,
  Button,
  MenuItem,
  FormControl,
  Divider,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import BoyAPI from 'services/boy-api';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const { REACT_APP_ENV } = process.env;

export const RefImportForm = () => {
  const [availableImports, setImports] = useState<object>({});
  const [fileUrl, setFileUrl] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [availableItems, setAvailableItems] = useState<string[]>([]);
  const [items, setItems] = useState<string[]>([]);
  const [error, setError] = useState<object | null>(null);
  const [result, setResult] = useState<object | null>(null);
  const [importFinished, setImportFinished] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isDefaultUrl, setIsDefaultUrl] = useState(true);

  const setImportSideValueFromSelect = (key: string) => {
    setCategory(key);
    if (key in availableImports) {
      if (isDefaultUrl) {
        setFileUrl(availableImports[key].default_file);
      }
      setAvailableItems(availableImports[key].items);
    }
  };

  const setFileURL = (url: string) => {
    setFileUrl(url);
    setIsDefaultUrl(false);
  };

  const manageErrors = () => {
    const regroupRowsError = (details: object[]): object => {
      let res = {};
      details.forEach((detail) => {
        if (!detail.key.startsWith('rows')) {
          if (!('unknown_line' in res)) {
            res['unknown_line'] = [];
          }
          res.unknown_line.push(detail);
        } else {
          let splitted = detail.key.split('.', 3);
          if (!(splitted[1] in res)) {
            res[splitted[1]] = [];
          }
          res[splitted[1]].push({
            key: splitted[2],
            reason: detail.message,
            context: detail.value,
          });
        }
      });

      return res;
    };

    switch (error.status) {
      case 400:
        return (
          <>
            <CardHeader
              title={
                <>
                  <CancelIcon
                    style={{ color: 'orange', fontSize: 12, marginTop: 12 }}
                  />{' '}
                  Corrupted file
                </>
              }
              subheader="Some data might not have been imported !"></CardHeader>
            <CardContent>
              <List>
                {Object.entries(regroupRowsError(error.data.details)).map(
                  ([row, details], _) => {
                    return (
                      <ListItem>
                        <Card style={{ backgroundColor: '#13364f' }}>
                          <CardHeader title={'Line: ' + row}></CardHeader>
                          <CardContent>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              justifyContent="center">
                              {details.map((detail, i) => {
                                return (
                                  <Grid item key={i}>
                                    <Card>
                                      <CardHeader title={detail.key} />
                                      <CardContent>
                                        <h5>{detail.reason}</h5>
                                        <p>{JSON.stringify(detail.context)}</p>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </CardContent>
                        </Card>
                      </ListItem>
                    );
                  },
                )}
              </List>
            </CardContent>
          </>
        );
      case 404:
        return (
          <>
            <CardHeader
              title={
                <>
                  <CancelIcon
                    style={{ color: 'orange', fontSize: 12, marginTop: 12 }}
                  />{' '}
                  Not found
                </>
              }></CardHeader>
            <CardContent>{error.data.key}</CardContent>
          </>
        );
      case 403 || 401:
        return (
          <>
            <CardHeader
              title={
                <>
                  <CancelIcon
                    style={{ color: 'red', fontSize: 16, marginTop: 0 }}
                  />{' '}
                  Unautorized
                </>
              }></CardHeader>
            <CardContent>You do not have import management right !</CardContent>
          </>
        );
      default:
        return (
          <>
            <CardHeader
              title={
                <>
                  <CancelIcon
                    style={{ color: 'red', fontSize: 16, marginTop: 0 }}
                  />{' '}
                  Unexpected Error
                </>
              }></CardHeader>
            <CardContent>{error.data.error}</CardContent>
          </>
        );
    }
  };

  const emitImportRequest = async () => {
    setLoading(true);
    setResult(null);
    setError(null);
    setImportFinished(false);
    if (category && fileUrl) {
      try {
        let res = await BoyAPI.RefImporter.import(category, fileUrl, items);
        setResult(res.data);
        setImportFinished(true);
        setLoading(false);
      } catch (error) {
        setError(error.response);
        setImportFinished(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        let res = await BoyAPI.RefImporter.list();
        setImports(res.data);
        setLoading(false);
      } catch (error) {
        setError(error.response);
        setImportFinished(true);
        setLoading(false);
      }
    };

    fetch();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <h3>Importer un fichier en {REACT_APP_ENV}</h3>

          <FormControl>
            <FormHelperText id="category-helper-text">Import</FormHelperText>
            <Select
              labelId="importCategory"
              id="importCategory"
              value={category}
              onChange={(event: any) =>
                setImportSideValueFromSelect(event.target.value)
              }>
              {Object.values(availableImports).map((description, _) => {
                return (
                  <MenuItem
                    id={description.key}
                    key={description.key}
                    value={description.key}>
                    {description.label}
                  </MenuItem>
                );
              })}
            </Select>
            <br />
            <Divider light={true} />
            <br />
            <InputLabel htmlFor="fileUrlInput"></InputLabel>
            <FormHelperText id="fileUrl-helper-text">
              From file URL
            </FormHelperText>
            <Input
              id="fileUrlInput"
              onChange={(event: any) => setFileURL(event.target.value)}
              aria-describedby="fileUrl-helper-text"
              defaultValue={fileUrl}
              value={fileUrl}
            />
            <br />
            <Divider light={true} />
            <br />
            {availableItems && availableItems.length > 0 ? (
              <>
                <FormHelperText id="items-helper-text">
                  Using only (opt)
                </FormHelperText>
                <Select
                  labelId="importItems"
                  id="importItems"
                  value={items}
                  multiple={true}
                  onChange={(event: any) => setItems(event.target.value)}>
                  {Object.values(availableItems).map((item, i) => {
                    return (
                      <MenuItem id={i} key={i} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                <br />
                <Divider light={true} />
                <br />
              </>
            ) : (
              <></>
            )}

            <Button onClick={emitImportRequest}>Importer</Button>
          </FormControl>

          {importFinished && error ? <Card>{manageErrors()}</Card> : <></>}

          {importFinished && error === null ? (
            <Card>
              <CardContent>
                {result === null || result?.errors.length === 0 ? (
                  <>
                    <CardHeader
                      title={
                        <>
                          <CheckCircle
                            style={{
                              color: 'green',
                              fontSize: 18,
                              marginTop: 18,
                            }}
                          />{' '}
                          Imported without errors
                        </>
                      }
                    />
                    Have a nice day !
                  </>
                ) : (
                  <>
                    <CancelIcon
                      style={{ color: 'red', fontSize: 32, marginTop: 24 }}
                    />
                    {result.errors.map((error: any) => (
                      <p>{error}</p>
                    ))}
                  </>
                )}
              </CardContent>
            </Card>
          ) : (
            <></>
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default RefImportForm;
