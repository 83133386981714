import { Card, CardContent, Typography } from '@mui/material';
import PieChart from 'components/data/PieChart';
import theme from 'core/theme';
import React, { useEffect, useState } from 'react';
import firestore from 'services/firestore';
import firestoreUtils from 'utils/firestore';

const MatchPerGameChart = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<{ data: string[]; labels: string[] }>({
    data: [],
    labels: [],
  });
  useEffect(() => {
    (async () => {
      const games = firestoreUtils.collectionToArray(
        await firestore().collection('game').limit(100).get(),
      );
      const todayStatistic = firestoreUtils.collectionToArray(
        await firestore()
          .collection('statistic')
          .orderBy('id', 'desc')
          .limit(1)
          .get(),
      )[0] as any;
      const gameLabels = Object.keys(todayStatistic).filter((key) =>
        games.some((game: any) => game.id === key),
      );
      setData({
        labels: gameLabels,
        data: gameLabels
          .map((label) => todayStatistic[label])
          .sort((a, b) => b - a),
      });
      setLoading(false);
    })();
  }, []);
  return (
    <Card style={{ width: '100%', marginTop: 32 }}>
      <CardContent style={{ margin: 'auto' }}>
        <Typography
          color="textSecondary"
          style={{ textTransform: 'uppercase' }}>
          Daily Matches per game
        </Typography>
        <div style={{ maxWidth: '800px' }}>
          <PieChart
            loading={loading}
            data={{
              labels: data.labels,
              options: {
                legend: {
                  display: false,
                },
              },
              datasets: [
                {
                  data: data.data,
                  backgroundColor: [
                    theme.palette.secondary.main,
                    'rgba(75,192,192,1)',
                    '#FFCE56',
                  ],
                },
              ],
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default MatchPerGameChart;
