const RESULT_CHOICES = {
  Victoire: [
    {
      value: 'VICTORY_AFTER_LITIGATION',
      label: 'Victoire',
    },
  ],
  Nul: [
    {
      value: 'DRAW_AFTER_LITIGATION',
      label: 'Nul',
    },
    {
      value: 'DRAW_NOT_PLAYED',
      label: 'Nul match pas joué',
    },
    {
      value: 'DRAW_FALSE_ID',
      label: 'Nul faux pseudos',
    },
    {
      value: 'DRAW_CHEATING',
      label: 'Nul triche',
    },
    {
      value: 'DRAW_WITHOUT_PROOF',
      label: 'Nul pas/insuffisant',
    },
    {
      value: 'DRAW_RULES',
      label: 'Nul règles non appliqués',
    },
  ],
  Défaite: [
    {
      value: 'DEFEAT_AFTER_LITIGATION',
      label: 'Défaite',
    },
    {
      value: 'DEFEAT_NOT_PLAYED',
      label: 'Défaite pas joué',
    },
    {
      value: 'DEFEAT_BAD_PARAMETERS',
      label: 'Défaite mauvaises params',
    },
    {
      value: 'DEFEAT_FALSE_ID',
      label: 'Défaite faux pseudo',
    },
    {
      value: 'DEFEAT_BAN',
      label: 'Défaite BAN',
    },
  ],
};

const BAN_CHOICES = [
  {
    value: 'BAN_DEF',
    label: 'Définitif',
  },
  {
    value: 'BAN_3_DAYS',
    label: '3 jours',
  },
  {
    value: 'BAN_7_DAYS',
    label: '7 jours',
  },
  {
    value: 'BAN_30_DAYS',
    label: '30 jours',
  },
];

export { RESULT_CHOICES, BAN_CHOICES };
