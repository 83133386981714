import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
  FormGroup,
  FormHelperText,
  TextField,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GroupedPlayerTransactionItem,
  OrderStore,
  PlayerTransactionReason,
} from 'core/types';
import React, { useEffect, useMemo, useState } from 'react';
import BoyAPI from 'services/boy-api';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Common, { Error } from '../common';

const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },

  clickableCell: {
    cursor: 'pointer',
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '2px',
    },
  },
});

const DEFAULT_COLUMNS = [
  { field: 'game_id', headerName: 'Game' },
  { field: 'product_id', headerName: 'Item purchased' },
  { field: 'admin_id', headerName: 'Admin ID' },
  { field: 'amount', headerName: 'Amount' },
  { field: 'date', headerName: 'Date', sortKey: 'at' },
  { field: 'reason', headerName: 'Type' },
  { field: 'computed_in_stock', headerName: 'Stocked' },
  { field: 'used_pub_bonus', headerName: 'Watched pub' },
  { field: 'infos', headerName: 'Infos' },
];

function Row({ row, idx }: { row: GroupedPlayerTransactionItem; idx: number }) {
  const classes = useRowStyles();

  const colorAmount = (val: number): string => {
    if (val > 0) {
      return '#2cac51';
    }
    if (val < 0) {
      return '#B94C6D';
    }

    return '#f1bd0f';
  };

  const rowBgColor = idx % 2 === 0 ? '#162836' : '#0A1E2C';

  const colorBool = (val: boolean, inverse: boolean = false): string => {
    if ((val && !inverse) || (!val && inverse)) {
      return '#2cac51';
    }

    return '#B94C6D';
  };

  const getBorder = (ind: number, max: number): string => {
    if (ind < max) return 'none';

    return '1px solid rgb(81,81,81)';
  };

  return (
    <React.Fragment>
      {row.transactions.map((tx, ind) => {
        return (
          <TableRow
            className={classes.root}
            style={{ backgroundColor: rowBgColor }}>
            {ind === 0 && (
              <>
                <TableCell rowSpan={row.transactions.length}>
                  {row.game_id}
                </TableCell>
                <TableCell rowSpan={row.transactions.length}>
                  {row.product_id}
                </TableCell>
                <TableCell rowSpan={row.transactions.length}>
                  {row.admin_id}
                </TableCell>
              </>
            )}
            <TableCell
              component="th"
              scope="row"
              style={{
                color: colorAmount(tx.amount),
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {tx.amount}
            </TableCell>
            <TableCell
              style={{
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {tx.at}
            </TableCell>
            <TableCell
              style={{
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {tx.reason}
            </TableCell>
            <TableCell
              style={{
                color: colorBool(tx.computed_in_stock),
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {tx.computed_in_stock.toString()}
            </TableCell>
            <TableCell
              style={{
                color: colorBool(tx.used_pub_bonus),
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {tx.used_pub_bonus.toString()}
            </TableCell>
            <TableCell
              style={{
                borderBottom: getBorder(ind, row.transactions.length - 1),
              }}>
              {Object.entries(tx.infos).map(([key, value]) => {
                return (
                  <>
                    {key}:{' '}
                    {typeof value === 'object'
                      ? JSON.stringify(value)
                      : value.toString()}{' '}
                    <br />
                  </>
                );
              })}
            </TableCell>
          </TableRow>
        );
      })}
    </React.Fragment>
  );
}

type Props = {
  playerId: string;
  value: number;
  isFree: boolean;
  refresh: boolean;
  onSuccess: (liveStock: number, visibleStock: number) => void;
  onError: (error: Error) => void;
};

const GroupedPremiumBetsTransactions = ({
  playerId,
  value,
  isFree,
  refresh,
  onSuccess,
  onError,
}: Props) => {
  const [transactionList, setTransactionList] = useState<
    GroupedPlayerTransactionItem[]
  >([]);

  const columns = useMemo(() => [...DEFAULT_COLUMNS], []);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItemPerPages, setCurrentItemPerPage] = useState<number>(25);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [nbPage, setNbPage] = useState<number>(1);

  const [selectedReason, setSelectedReason] = useState<
    PlayerTransactionReason | undefined
  >(undefined);
  const [searchingGame, setSearchingGame] = useState<string | undefined>(
    undefined,
  );
  const [searchingLot, setSearchingLot] = useState<string | undefined>(
    undefined,
  );
  const [searchFromDate, setSearchFromDate] = useState<Date | undefined>(
    undefined,
  );
  const [searchToDate, setSearchToDate] = useState<Date | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<OrderStore | undefined>({ at: true });
  const [loading, setLoading] = useState<boolean>(true);

  const addOrder = Common.Tools.addOrder(orderBy, setOrderBy);
  const removeOrder = Common.Tools.removeOrder(orderBy, setOrderBy);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        let res = await BoyAPI.PlayerPremiumBetsTransactions.list_logical_grouped(
          playerId,
          value,
          isFree,
          currentPage,
          currentItemPerPages,
          selectedReason,
          searchingGame,
          searchingLot,
          searchFromDate,
          searchToDate,
          orderBy,
        );

        setTotalItems(res.data.total);
        setNbPage(res.data.total_page);

        if (currentPage > nbPage) {
          setCurrentPage(1);
          return;
        }

        onSuccess(res.data.live_stock, res.data.visible_stock);
        setTransactionList(res.data.items);
        setLoading(false);
      } catch (error) {
        onError({
          severity: error.response.status >= 500 ? 'error' : 'warning',
          error: error.response.data.error || error.response.data.detail,
          key: error.response.data.key,
        });

        setLoading(false);
      }
    })();
  }, [
    currentPage,
    currentItemPerPages,
    playerId,
    nbPage,
    selectedReason,
    searchingGame,
    searchingLot,
    searchFromDate,
    searchToDate,
    orderBy,
    onSuccess,
    onError,
    refresh,
    value,
    isFree,
  ]);

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ alignItems: 'end' }}>
        <FormGroup>
          <FormHelperText id="goldTsxReasonHelper">Type</FormHelperText>
          <Select
            labelId="goldTsxReason"
            id="goldTsxReason"
            value={selectedReason}
            onChange={(event: any) => {
              if (event.target.value !== '') {
                setSelectedReason(event.target.value);
              } else {
                setSelectedReason(event.target.value);
              }
            }}>
            <MenuItem id="reason.reset" key="reset" value={''}>
              x
            </MenuItem>
            {Object.entries(PlayerTransactionReason).map(([key, reason]) => {
              return (
                <MenuItem id={key} key={key} value={reason}>
                  {reason}
                </MenuItem>
              );
            })}
          </Select>
        </FormGroup>
        <FormGroup>
          <FormHelperText>Game</FormHelperText>
          <TextField
            id="game_filter"
            type="text"
            value={searchingGame}
            onChange={(event: any) => setSearchingGame(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormHelperText>Item purchased</FormHelperText>
          <TextField
            id="lot_filter"
            type="text"
            value={searchingLot}
            onChange={(event: any) => setSearchingLot(event.target.value)}
          />
        </FormGroup>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormGroup>
            <FormHelperText>From</FormHelperText>
            <DatePicker
              value={searchFromDate || null}
              onChange={(newValue) => {
                newValue?.setHours(12);
                setSearchFromDate(newValue || undefined);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>
          <FormGroup>
            <FormHelperText>To</FormHelperText>
            <DatePicker
              value={searchToDate || null}
              onChange={(newValue) => {
                newValue?.setHours(12);
                setSearchToDate(newValue || undefined);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>
        </LocalizationProvider>
      </Stack>
      <Table aria-label="collapsible table">
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
            setCurrentItemPerPage(+e.target.value);
          }}
          onPageChange={(e, page) => {
            e?.preventDefault();
            setCurrentPage(page + 1);
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Common.Tools.navActions(
            setCurrentPage,
            currentPage,
            nbPage,
          )}
        />
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.field}
              sx={{ cursor: column.sortKey ? 'pointer' : 'inherit' }}>
              <label for={column.field + '_select'}>{column.headerName}</label>
              {column.sortKey ? (
                <ButtonGroup
                  variant="text"
                  size="small"
                  sx={{ alignSelf: 'right' }}>
                  <Button
                    id={column.field + '_select'}
                    size="small"
                    sx={{
                      display:
                        orderBy && column.sortKey in orderBy
                          ? 'inherit'
                          : 'none',
                    }}
                    onClick={() => addOrder(column.sortKey)}>
                    {orderBy && orderBy[column.sortKey] ? (
                      <ArrowDropDownIcon />
                    ) : (
                      <ArrowDropUpIcon />
                    )}
                  </Button>
                  {orderBy && column.sortKey in orderBy ? (
                    <Button
                      size="small"
                      onClick={() => removeOrder(column.sortKey)}>
                      <CancelIcon />
                    </Button>
                  ) : (
                    ''
                  )}
                </ButtonGroup>
              ) : (
                ''
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {transactionList.map((row, ind) => (
                <Row key={ind} row={row} columns={columns} idx={ind} />
              ))}
            </>
          )}
        </TableBody>
        <TablePagination
          count={totalItems}
          onRowsPerPageChange={(e) => {
            e?.preventDefault();
            setCurrentItemPerPage(+e.target.value);
          }}
          onPageChange={(e, page) => {
            e?.preventDefault();
            setCurrentPage(page + 1);
          }}
          page={currentPage - 1}
          rowsPerPage={currentItemPerPages}
          ActionsComponent={Common.Tools.navActions(
            setCurrentPage,
            currentPage,
            nbPage,
          )}
        />
      </Table>
    </>
  );
};

export default GroupedPremiumBetsTransactions;
