import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  CardHeader,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { Player, Statistic } from 'core/types';
import theme from 'core/theme';
import { useEffect, useState } from 'react';
import firestore from 'services/firestore';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import DateRangePicker from 'components/common/DateRangePicker';

const formatDate = (date: Date) => {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('');
};

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  potentialCheat: {
    backgroundColor: theme.palette.secondary.main,
  },
  tableCellTrucate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const columns = [
  { field: 'date', headerName: 'Date', width: 200 },
  { field: 'subscriptions', headerName: 'Inscriptions', width: 130 },
  { field: 'searches', headerName: 'Recherches', width: 130 },
  { field: 'matches', headerName: 'Matchs', width: 130 },
  { field: 'litigations', headerName: 'Litiges', width: 130 },
  { field: 'reviews', headerName: 'Reviews', width: 130 },
  { field: 'bought_gems', headerName: 'Gemmes achetés', width: 200 },
  { field: 'bought_avatars', headerName: 'Avatars', width: 130 },
  { field: 'bought_premium_bets', headerName: 'Mises premiums', width: 200 },
  { field: 'collected_wheels', headerName: 'Roues Collectées', width: 200 },
];

const columnsCurrent = [
  { field: 'current_searches', headerName: 'Connexions', width: 200 },
  { field: 'current_matches', headerName: 'Matchs', width: 130 },
  { field: 'current_litigations', headerName: 'Litiges', width: 130 },
  { field: 'current_reviews', headerName: 'Reviews', width: 130 },
];

function CustomToolbar(props) {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport
        csvOptions={{ fileName: props.title }}
        printOptions={{ fileName: props.title }}
      />
    </GridToolbarContainer>
  );
}

const getFirstDate = () => {
  var prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 15);
  return prevDate;
};

const StatisticsGrid = ({ player }: { player?: Player }) => {
  const { classes } = useStyles();
  const [dataCurrent, setDataCurrent] = useState<Statistic[]>();
  const [data, setData] = useState<Statistic[]>();

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getFirstDate(),
    new Date(),
  ]);

  useEffect(() => {
    (async () => {
      var curDate = formatDate(new Date());

      const result = await firestore()
        .collection('statistic')
        .where('id', '==', curDate)
        .get();

      const rowsCurrent: Statistic[] = [];

      result.forEach((doc) => {
        const data = doc.data() as Statistic;
        data.date = format(
          new Date(
            `${data.id.substr(0, 4)}-${data.id.substr(4, 2)}-${data.id.substr(
              6,
              2,
            )}`,
          ),
          'P',
          {
            locale: fr,
          },
        );
        rowsCurrent.push(data);
      });

      setDataCurrent(rowsCurrent);
    })();
  }, []);

  useEffect(() => {
    const getRangeStatistics = async () => {
      const dateBefore = formatDate(dateRange[1]);
      const dateAfter = formatDate(dateRange[0]);

      const result = await firestore()
        .collection('statistic')
        .where('id', '<=', dateBefore)
        .where('id', '>=', dateAfter)
        .orderBy('id', 'desc')
        .get();

      const rows: Statistic[] = [];

      result.forEach((doc) => {
        const data = doc.data() as Statistic;

        data.date = format(
          new Date(
            `${data.id.substr(0, 4)}-${data.id.substr(4, 2)}-${data.id.substr(
              6,
              2,
            )}`,
          ),
          'P',
          {
            locale: fr,
          },
        );
        rows.push(data);
      });

      setData(rows);
    };

    getRangeStatistics();
  }, [dateRange]);

  return (
    <>
      {player?.is_admin && (
        <Card className={classes.root}>
          <CardContent>
            {!dataCurrent ? (
              <CircularProgress />
            ) : (
              <>
                <Typography
                  color="textSecondary"
                  align="left"
                  className={classes.title}>
                  En cours
                </Typography>
                <div style={{ height: '165px', width: '100%' }}>
                  <DataGrid
                    rows={dataCurrent}
                    columns={columnsCurrent}
                    pageSize={1}
                    checkboxSelection
                  />
                </div>
              </>
            )}
          </CardContent>
        </Card>
      )}

      <Card className={classes.root}>
        {!data ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              color="textSecondary"
              align="left"
              className={classes.title}>
              <CardHeader title={'Statistiques'} />
              <DateRangePicker
                value={dateRange}
                onChange={(value) => {
                  if (!value[0] || !value[1]) {
                    return;
                  }
                  setDateRange([value[0], value[1]]);
                }}
              />
            </Typography>
            <CardContent>
              <div style={{ height: '400px', width: '100%' }}>
                <DataGrid
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  componentsProps={{
                    toolbar: { title: 'statistiques' },
                  }}
                  rows={data}
                  columns={columns}
                  pageSize={15}
                  checkboxSelection
                />
              </div>
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
};

export default StatisticsGrid;
