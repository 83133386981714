import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';
import matchUtils from 'utils/match';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const BoostedMatchFoundUnder5sCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalculIsReady] = useState(false);
  const [boostedMatchFoundUnder5s, setBoostedMatchFoundUnder5s] = useState<
    string | false
  >(false);
  const [
    boostedMatchFoundUnder5sInRow,
    setBoostedMatchFoundUnder5sInRow,
  ] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      let maxInARow = 0;
      let max = 0;
      let count = 0;
      matchHistoryList.forEach((match) => {
        if (
          match.is_boosted === true &&
          matchUtils.getWaitDuration(match) < 5 &&
          match.result.startsWith('VICTORY')
        ) {
          count++;
          max++;
        } else {
          max = 0;
        }
        if (max > maxInARow) {
          maxInARow = max;
        }
      });
      setBoostedMatchFoundUnder5s(`${count} total,`);
      setBoostedMatchFoundUnder5sInRow(`${maxInARow} in a row`);
      setCalculIsReady(true);
    })();
  }, [matchHistoryList]);

  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">
              Boosted match found under 5s
            </Typography>
            <p>{boostedMatchFoundUnder5s}</p>
            <p>{boostedMatchFoundUnder5sInRow}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default BoostedMatchFoundUnder5sCard;
