import { Card, CardContent, Typography } from '@mui/material';
import PieChart from 'components/data/PieChart';
import theme from 'core/theme';
import React, { useEffect, useState } from 'react';
import { Player } from 'core/types';
import firestore from 'services/firestore';

type Props = {
  player: Player;
};

const MatchsPerResultsChart = ({ player }: Props) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<{ data: number[]; labels: string[] }>({
    data: [],
    labels: [],
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const _stats = await firestore()
        .collection('player_game_stats')
        .where('id', '==', player.id)
        .get();

      let count: { [key: string]: number };

      if (_stats.empty) {
        count = {
          Wins: 0,
          Losses: 0,
          Draws: 0,
        };
      } else {
        const stats = _stats.docs[0].data();

        count = {
          Wins: stats.global.victories,
          Losses: stats.global.defeats,
          Draws: stats.global.draws,
        };
      }

      setData({
        labels: Object.keys(count),
        data: Object.values(count),
      });
      setLoading(false);
    })();
  }, [player.id]);

  return (
    <Card style={{ width: '100%' }}>
      <CardContent>
        <Typography
          color="textSecondary"
          style={{ textTransform: 'uppercase' }}>
          Repartition of Win/Draw/Losses
        </Typography>

        <PieChart
          loading={loading}
          options={{
            tooltips: {
              callbacks: {
                label(item: any, data: any) {
                  const sum = data.datasets[0].data.reduce(
                    (acc: number, cur: number) => acc + cur,
                    0,
                  );
                  const current = data.datasets[0].data[item.index];
                  return `${data.labels[item.index]}: ${current} (${Math.round(
                    (current * 100) / sum,
                  )}%)`;
                },
              },
            },
          }}
          data={{
            labels: data.labels,

            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  theme.palette.secondary.main,
                  'rgba(75,192,192,1)',
                  '#FFCE56',
                ],
              },
            ],
          }}
        />
      </CardContent>
    </Card>
  );
};

export default MatchsPerResultsChart;
