import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useMediaQuery,
  Button as ButtonMUI,
  Box,
  Select,
  MenuItem,
  CircularProgress,
  Input,
  css,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ButtonUI from '@mui/material/Button';

import { useEffect, useRef, useState } from 'react';
import {
  GoldsSums,
  LogIdentifier,
  MatchHistory,
  Permission,
  Player,
  PlayerState,
  Sale,
} from 'core/types';
import MatchChart from './MatchChart';
import MatchPerGameChart from './MatchPerGameChart';
import MatchHistoryDataGrid from './MatchHistoryDataGrid';
import MatchsPerResultsChart from './MatchsPerResultsChart';
import { InputLabel, Typography } from '@mui/material';
import firestore from 'services/firestore';
import BoostedMatchFoundUnder5sCard from './BoostedMatchFoundUnder5sCard';
import MultiAccountCard from './MultiAccountCard';
import LitigationRatioCard from './LitigationRatioCard';
import AvgWaitDurationCard from './AvgWaitDurationCard';
import SameIdentifiersCard from './SameIdentifiersCard';
import RatioNightHoursMatchsCard from './RatioNightHoursMatchsCard';
import AvgWaitDurationNightHourCard from './AvgWaitDurationNightHourCard';
import BoostedMatchsWonByForfeitCard from './BoostedMatchsWonByForfeitCard';
import MatchsWithLess3MinPauseCard from './MatchsWithLess3MinPauseCard';
import RecurringOpponentCard from './RecurringOpponentCard';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import TextFieldMUI from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Badge from '@mui/material/Badge';

import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ShowProps,
  useDataProvider,
  TabbedShowLayoutTabs,
  useRecordContext,
  TopToolbar,
  EditButton,
  Loading,
  Button,
  useGetOne,
  FunctionField,
  useNotify,
  useRefresh,
  usePermissions,
} from 'react-admin';
import dateUtils from 'utils/date';
import Chip from 'components/common/Chip';
import BoyAPI from 'services/boy-api';
import usePlayerBadges from '../../hooks/use-player-badges';
import GiftHistoryDataGrid from './GiftHistoryDataGrid';
import BanHistoryDataGrid from './BanHistoryGrid';
import { Info } from '@mui/icons-material';
import useCurrentPlayer from 'hooks/use-current-player';
import matchUtils from 'utils/match';
import DevicesDataGrid from './DevicesDataGrid';
import SuspiciousBehaviours from './SuspiciousBehavioursDataGrid';
import { useParams } from 'react-router-dom';
import Transactions from './Transactions/View';
import Cryptos from './CryptosView';

function monthDiff(d1: Date, d2: Date) {
  let months: number = 0;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function NFTHolderInformation({
  avatars,
}: {
  avatars?: {
    id: string;
    image_url: string;
    kind: string;
    onwed_since: string;
    title: string;
  }[];
}) {
  if (!avatars) {
    return <p>false</p>;
  }

  const dateNow = new Date();

  const nftAvatar = avatars
    .filter((avatar) => avatar.kind === 'nft')
    .sort((a, b) => {
      if (a.onwed_since < b.onwed_since) {
        return -1;
      }
      if (a.onwed_since > b.onwed_since) {
        return 1;
      }
      return 0;
    });

  if (nftAvatar.length > 0) {
    return (
      <p>
        true since {monthDiff(new Date(avatars[0].onwed_since), dateNow)}{' '}
        month(s)
      </p>
    );
  }

  return <p>false</p>;
}

function isPremiumResult(record: any) {
  const dateNow = new Date();

  let result = 'false';

  if (
    record.android_premium_subscription &&
    dateNow < record.android_premium_subscription.expiration_date
  ) {
    return (
      'true since ' +
      record.android_premium_subscription.purchase_date.toLocaleDateString()
    );
  } else if (
    record.ios_premium_subscription &&
    dateNow < record.ios_premium_subscription.expiration_date
  ) {
    return (
      'true since ' +
      record.ios_premium_subscription.purchase_date.toLocaleDateString()
    );
  }

  if (
    record.ios_premium_subscription &&
    record.ios_premium_subscription.expiration_date
  ) {
    result =
      'false since ' +
      monthDiff(record.ios_premium_subscription.expiration_date, dateNow) +
      ' month(s)';
  }

  if (
    record.android_premium_subscription &&
    record.android_premium_subscription.expiration_date
  ) {
    result =
      'false since ' +
      monthDiff(record.android_premium_subscription.expiration_date, dateNow) +
      ' month(s)';
  }

  if (
    record.ios_premium_subscription &&
    record.ios_premium_subscription.expiration_date &&
    record.android_premium_subscription &&
    record.android_premium_subscription.expiration_date
  ) {
    let monthSinceLastIOS: number = monthDiff(
      record.ios_premium_subscription.expiration_date,
      dateNow,
    );
    let monthSinceLastAndroid: number = monthDiff(
      record.android_premium_subscription.expiration_date,
      dateNow,
    );
    if (monthSinceLastAndroid > monthSinceLastIOS) {
      result = 'false since ' + monthSinceLastIOS + ' month(s)';
    } else {
      result = 'false since ' + monthSinceLastAndroid + ' month(s)';
    }
  }

  if (record.subscription?.kind === 'NFT' && record.subscription?.active) {
    result = 'true with NFT';
  }

  return result;
}

const useStyles = makeStyles()((theme) => ({
  '@global': {
    '.MuiTab-root.Mui-selected': {
      backgroundColor: '#3c607b',
    },
  },
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
  item: {
    paddingBottom: 24,
    alignItems: 'stretch',
    display: 'flex',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      paddingRight: 24,
    },
  },
  tabbedLayout: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'flex',
    },
  },
  showRoot: {
    width: '100%',
  },
  showMain: {
    height: '100%',
  },
  showCard: {
    width: '100%',
  },
  showNoActions: {
    marginTop: 0,
  },
  showCardContent: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      flexWrap: 'wrap',
      flex: 1,
      height: '400px',
      padding: 0,
      paddingBottom: 16,
    },
    paddingBottom: 0,
  },
  identifierLogs: {
    padding: '0 16px 16px 16px',
  },
  layoutTab: {
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      borderRight: '1px solid #cccccc5c',
    },
  },
  dialogSpacing: {
    margin: '10px',
    minWidth: 300,
  },
}));

const FormattedSubscriptionDateField = () => {
  const record = useRecordContext();
  return (
    <div style={{ marginTop: 8, marginBottom: 4 }}>
      <InputLabel style={{ fontSize: 12 }}>Inscription</InputLabel>
      <Typography variant="body2">
        {dateUtils.localizedFormat(record.subscription_date, 'Pp')}
      </Typography>
    </div>
  );
};

const StateField = () => {
  const [playerState, setPlayerState] = useState<PlayerState | undefined>(
    undefined,
  );
  const [stateInfoIsReady, setStateInfoIsReady] = useState(false);
  const record = useRecordContext();
  useEffect(() => {
    setStateInfoIsReady(false);
    (async () => {
      const resultPS = await firestore()
        .collection('player_state')
        .where('id', '==', record.id)
        .get();

      let playerState: PlayerState | undefined = undefined;

      resultPS.forEach((doc) => {
        playerState = doc.data() as PlayerState;
      });

      setPlayerState(playerState);

      setStateInfoIsReady(true);
    })();
  }, [record.id]);

  return (
    <>
      {stateInfoIsReady ? (
        <div style={{ marginTop: 8, marginBottom: 16 }}>
          <InputLabel style={{ fontSize: 12 }}>State</InputLabel>
          <Typography style={{ marginTop: 8 }} variant="body2">
            Dernier update :{' '}
            {record.update_date
              ? record.update_date.toISOString()
              : 'no update'}
          </Typography>
          <Typography style={{ marginTop: 8 }} variant="body2">
            State : {playerState?.state}
          </Typography>
          <Typography style={{ marginTop: 8 }} variant="body2">
            Dernier update PlayerState :{' '}
            {playerState && playerState.update_date
              ? new Date(playerState.update_date.seconds * 1000).toISOString()
              : 'no update'}
          </Typography>
        </div>
      ) : (
        <CircularProgress />
      )}{' '}
    </>
  );
};

const GoldsFields = () => {
  const notify = useNotify();
  const [goldsInfoIsReady, setGoldsInfoIsReady] = useState(false);
  const record = useRecordContext();
  const [
    playerGoldsTransactionsSums,
    setPlayerGoldsTransactionsSums,
  ] = useState<GoldsSums | undefined>();

  useEffect(() => {
    (async () => {
      ////console.log('Hello ?', record);
      setGoldsInfoIsReady(false);
      try {
        const sum = await BoyAPI.PlayerGoldsTransactions.get_sums(record.id);
        //console.log('Ok till here');
        setPlayerGoldsTransactionsSums(sum.data);
        setGoldsInfoIsReady(true);
      } catch (err) {
        notify(err.response.data.error || err.response.data.detail, {
          type: err.response.status >= 500 ? 'error' : 'warning',
        });
      }
    })();
  }, [record, notify]);

  return (
    <>
      {goldsInfoIsReady ? (
        <>
          <div style={{ marginTop: 8, marginBottom: 16 }}>
            <InputLabel style={{ fontSize: 12 }}>Golds</InputLabel>
            <Typography style={{ marginTop: 8 }} variant="body2">
              Current: {playerGoldsTransactionsSums?.sum}
            </Typography>
            <Typography style={{ marginTop: 8 }} variant="body2">
              Collected: {playerGoldsTransactionsSums?.gained}
            </Typography>
            <Typography style={{ marginTop: 8 }} variant="body2">
              Used for purchases: {playerGoldsTransactionsSums?.consumed}
            </Typography>
          </div>
          <div style={{ marginTop: 8, marginBottom: 16 }}>
            <InputLabel style={{ fontSize: 12 }}>
              Points (before migration)
            </InputLabel>
            <Typography style={{ marginTop: 8 }} variant="body2">
              Visible: {record?.points}
            </Typography>{' '}
            <Typography style={{ marginTop: 8 }} variant="body2">
              Total: {record?.total_points}
            </Typography>
            <Typography style={{ marginTop: 8 }} variant="body2">
              Waiting collection: {record?.total_points_to_collect}
            </Typography>
          </div>
        </>
      ) : (
        <CircularProgress />
      )}{' '}
    </>
  );
};

const BadgesField = () => {
  const record = useRecordContext();
  const badges = usePlayerBadges({ user: record, badgeSize: 40 });

  return (
    <div>
      <InputLabel style={{ fontSize: 12 }}>Badges</InputLabel>
      <div style={{ display: 'flex', marginTop: 8 }}>
        {badges.map((e) => e.component)}
      </div>
    </div>
  );
};

const RolesField = () => {
  // TODO: get permissions from get_claims
  const permissions = { admin: false, moderator: false };
  const record = useRecordContext();

  if (
    !permissions.admin &&
    !permissions.moderator &&
    !record.is_moderator &&
    !record.is_admin
  ) {
    return null;
  }
  const inconsistentAdminRole =
    (record.is_admin && !permissions.admin) ||
    (!record.is_admin && permissions.admin);
  const inconsistentModeratorRole =
    (record.is_moderator && !permissions.moderator) ||
    (!record.is_moderator && permissions.moderator);

  return (
    <div style={{ marginTop: 8, marginBottom: 4 }}>
      <InputLabel style={{ fontSize: 12, marginBottom: 8 }}>Roles</InputLabel>
      {(record.is_admin || permissions.admin) && (
        <Chip label="Admin" style={{ marginRight: 4 }} />
      )}
      {(record.is_moderator || permissions.moderator) && (
        <Chip label="Moderator" />
      )}
    </div>
  );
};

const AnnotationButton = ({
  onClick,
  record,
}: {
  onClick: () => void;
  record: any;
}) => {
  const [annotations, setAnnotations] = useState<any>([]);

  useEffect(() => {
    if (!record) {
      return;
    }
    const getAdminComment = async () => {
      const commentsCollection = await firestore()
        .collection('player')
        .doc(record.id)
        .collection('admin_comments')
        .get();

      let comments: any[] = [];

      commentsCollection.forEach((doc) => {
        let data = doc.data();
        data.date = data.date.toDate().toDateString();
        comments.push(data);
      });

      setAnnotations(comments);
    };
    getAdminComment();
  }, [record]);

  const badgeAnnotation = () => {
    return (
      <div style={{ marginRight: 5 }}>
        <Badge badgeContent={annotations.length} max={100} color="primary">
          <Info />
        </Badge>
      </div>
    );
  };

  return (
    <ButtonMUI variant="text" startIcon={badgeAnnotation()} onClick={onClick}>
      Annotation
    </ButtonMUI>
  );
};

const AnnotationItem = ({
  comment,
  author,
  date,
}: {
  comment: string;
  author: string;
  date: any;
}) => {
  return (
    <ListItem style={{ width: '100%' }}>
      <Paper elevation={3} style={{ padding: 5, width: '100%' }}>
        <TextFieldMUI
          multiline
          value={comment}
          disabled={true}
          style={{
            paddingBottom: 10,
            width: '100%',
            flex: 1,
            flexWrap: 'wrap',
            resize: 'none',
            backgroundColor: '#1E2E3C',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}>
          <p>{author}</p>
          <p>{date}</p>
        </div>
      </Paper>
    </ListItem>
  );
};

const AnnotationList = ({ record }) => {
  const [annotations, setAnnotations] = useState<any>([]);
  const [comment, setComment] = useState<string>('');

  const refElement = useRef<HTMLDivElement>(null);

  const { player } = useCurrentPlayer();

  useEffect(() => {
    getAdminComment();
  }, []);

  useEffect(() => {
    refElement.current?.scrollIntoView();
  }, [annotations]);

  const getAdminComment = async () => {
    const commentsCollection = await firestore()
      .collection('player')
      .doc(record.id)
      .collection('admin_comments')
      .orderBy('date', 'asc')
      .get();

    let comments: any[] = [];

    commentsCollection.forEach((doc) => {
      let data = doc.data();
      data.date = data.date.toDate().toDateString();
      comments.push(data);
    });

    setAnnotations(comments);
  };

  const createAnnotation = () => {
    firestore()
      .collection('player')
      .doc(record.id)
      .collection('admin_comments')
      .add({
        comment: comment,
        author_boy_id: player?.nickname,
        author_nickname: player?.nickname,
        author_id: player?.id,
        date: firestore.FieldValue.serverTimestamp(),
      })
      .then((a) => {
        firestore()
          .collection('player')
          .doc(record.id)
          .collection('admin_comments')
          .doc(a.id)
          .update({
            id: a.id,
          });
      })
      .then(() => {
        setComment('');
        getAdminComment();
      });
  };

  return (
    <Paper
      style={{
        display: 'flex',
        height: '100%',
        width: 400,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <List
        style={{
          flex: 10,
          overflow: 'scroll',
        }}>
        {annotations.map((item, index) => (
          <AnnotationItem
            key={index}
            comment={item.comment}
            author={item.author_boy_id}
            date={item.date}
          />
        ))}
        <div ref={refElement} />
      </List>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 10,
          flex: 2,
        }}>
        <TextFieldMUI
          disabled={!player?.is_admin && !player?.is_moderator}
          onChange={(event) => {
            setComment(event.target.value);
          }}
          multiline
          minRows={2}
          value={comment}
          id="outlined-basic"
          label="comment"
          variant="outlined"
        />
        <ButtonMUI
          disabled={!player?.is_admin && !player?.is_moderator}
          onClick={createAnnotation}
          style={{ marginTop: 10 }}
          color="primary"
          variant="contained"
          endIcon={<SendIcon />}>
          Send
        </ButtonMUI>
      </div>
    </Paper>
  );
};

const ShowActions = (props: any) => {
  const { basePath, id } = props;
  const { classes } = useStyles();
  const { permissions } = usePermissions();

  const [readyModalOpen, setReadyModalOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const record = useRecordContext(props);

  const [banType, setBanType] = useState<string>('BAN_3_DAYS');
  const [banReason, setBanReason] = useState<string>('');
  const [impliedAccounts, setImpliedAccounts] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [showFormBan, setShowFormBan] = useState(false);
  const [showFormUnban, setShowFormUnban] = useState(false);

  const [isDisableBanButton, setIsDisableBanButton] = useState(true);

  useEffect(() => {
    if (banReason.length <= 0) {
      setIsDisableBanButton(true);
    } else {
      setIsDisableBanButton(false);
    }
  }, [banReason]);

  const closeBanForm = () => {
    setShowFormBan(false);
  };

  const closeUnbanForm = () => {
    setShowFormUnban(false);
  };

  const refresh = useRefresh();
  const notify = useNotify();

  const confirmBanForm = async () => {
    setLoading(true);
    try {
      await BoyAPI.Player.ban(id, banType, banReason, impliedAccounts);
    } catch (err) {
      notify('Ban error', { type: 'error' });
      setLoading(false);
      setShowFormBan(false);
      return;
    }
    setTimeout(function () {
      notify('Player ban', { type: 'success' });
      setLoading(false);
      refresh();
      setShowFormBan(false);
    }, 2000);
  };

  const confirmUnBanForm = async () => {
    setLoading(true);
    try {
      await BoyAPI.Player.unban(id, banReason);
    } catch (err) {
      notify('Unban error', { type: 'error' });
      setLoading(false);
      setShowFormUnban(false);
      return;
    }
    setTimeout(function () {
      notify('Player unban', { type: 'success' });
      setLoading(false);
      refresh();
      setShowFormUnban(false);
    }, 2000);
  };

  const resetStocks = async () => {
    if (!record) {
      return;
    }
    try {
      await BoyAPI.PlayerStocks.reset(record.id);
      setTimeout(function () {
        notify('Stock resets', { type: 'success' });
        refresh();
      }, 500);
    } catch (err) {
      notify('Could not reset stocks', { type: 'error' });
    }
  };

  const setToReady = async () => {
    if (!record) {
      return;
    }
    try {
      await BoyAPI.Admin.setPlayerToReady(id);
      setTimeout(function () {
        notify('Unblocked player', { type: 'success' });
        refresh();
      }, 500);
    } catch (err) {
      notify('Could not unblock player', { type: 'error' });
    }
  };

  const { data: currentUser } = useGetOne('player', {
    id: permissions.user_id,
  });
  if (!record) {
    return null;
  }

  return (
    <TopToolbar>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}>
        <AnnotationButton
          record={record}
          onClick={() => {
            setDrawerOpen((value) => {
              return !value;
            });
          }}
        />
        <Drawer
          anchor={'right'}
          open={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}>
          <AnnotationList record={record} />
        </Drawer>
        {(permissions.admin ||
          currentUser?.permissions?.includes(Permission.ManageUser)) && (
          <>
            <EditButton {...props} style={{ marginRight: 32 }} />
            <Button
              color="secondary"
              onClick={resetStocks}
              label="Reset stocks"
            />
          </>
        )}
        {(permissions.admin ||
          currentUser?.permissions?.includes(Permission.UnblockUser)) && (
          <>
            <Button
              color="secondary"
              onClick={() => {
                setReadyModalOpen(true);
              }}
              label="Unblock"
            />
            <Dialog open={readyModalOpen}>
              <DialogTitle>{'Unblock user'}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  User will quit current waiting room / game room and return to
                  home screen
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setReadyModalOpen(false);
                  }}
                  color="primary"
                  label="Cancel"
                />
                <Button
                  onClick={() => {
                    setToReady();
                    setReadyModalOpen(false);
                  }}
                  color="secondary"
                  label="Confirm"
                />
              </DialogActions>
            </Dialog>
          </>
        )}
        {(permissions.admin ||
          currentUser?.permissions?.includes(Permission.BanUnbanUser)) && (
          <>
            <Button
              color="secondary"
              onClick={() => {
                if (record?.is_banned) {
                  setShowFormUnban(true);
                } else {
                  setShowFormBan(true);
                }
              }}
              label={record?.is_banned ? 'unban player' : 'ban player'}
            />
            <Dialog
              open={showFormBan}
              onClose={closeBanForm}
              aria-labelledby="form-dialog-title">
              <Box className={classes.dialogSpacing}>
                <DialogTitle id="form-dialog-title">Ban Form</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Write reason and duration of ban
                  </DialogContentText>
                </DialogContent>
                <DialogContent>
                  <InputLabel style={{ fontSize: 12 }}>Ban type</InputLabel>
                  <Select
                    labelId="banType"
                    id="banTypeInput"
                    value={banType}
                    onChange={(event: any) => setBanType(event.target.value)}>
                    <MenuItem value={'BAN_DEF'}>BAN_DEF</MenuItem>
                    <MenuItem value={'BAN_3_DAYS'}>BAN_3_DAYS</MenuItem>
                    <MenuItem value={'BAN_7_DAYS'}>BAN_7_DAYS</MenuItem>
                    <MenuItem value={'BAN_30_DAYS'}>BAN_30_DAYS</MenuItem>
                  </Select>
                </DialogContent>
                <DialogContent>
                  <InputLabel style={{ fontSize: 12 }}>Ban reason</InputLabel>
                  <Input
                    id="banReasonInput"
                    value={banReason}
                    onChange={(event: any) => setBanReason(event.target.value)}
                  />
                </DialogContent>
                <DialogContent>
                  <InputLabel style={{ fontSize: 12 }}>
                    Account involve
                  </InputLabel>
                  <Input
                    id="impliedAccountsInput"
                    value={impliedAccounts}
                    onChange={(event: any) =>
                      setImpliedAccounts(event.target.value)
                    }
                  />
                </DialogContent>
                <DialogActions>
                  {!loading ? (
                    <>
                      <ButtonUI onClick={closeBanForm} color="secondary">
                        Cancel
                      </ButtonUI>
                      <ButtonUI
                        onClick={confirmBanForm}
                        disabled={isDisableBanButton}
                        color="primary">
                        Ban Player
                      </ButtonUI>
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </DialogActions>
              </Box>
            </Dialog>
            <Dialog
              open={showFormUnban}
              onClose={closeUnbanForm}
              aria-labelledby="form-dialog-title">
              <Box className={classes.dialogSpacing}>
                <DialogTitle id="form-dialog-title">Unban Form</DialogTitle>
                <DialogContent>
                  <DialogContentText>Write reason of unban</DialogContentText>
                </DialogContent>
                <DialogContent>
                  <InputLabel style={{ fontSize: 12 }}>Unban reason</InputLabel>
                  <Input
                    id="banReasonInput"
                    value={banReason}
                    onChange={(event: any) => setBanReason(event.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  {!loading ? (
                    <>
                      <ButtonUI onClick={closeUnbanForm} color="secondary">
                        Cancel
                      </ButtonUI>
                      <ButtonUI
                        onClick={confirmUnBanForm}
                        disabled={isDisableBanButton}
                        color="primary">
                        Unban Player
                      </ButtonUI>
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </DialogActions>
              </Box>
            </Dialog>
          </>
        )}
      </div>
    </TopToolbar>
  );
};

const PlayerDetail = (props: ShowProps) => {
  const { id } = useParams();
  const [multiAccounts, setMultiAccounts] = useState<LogIdentifier[]>([]);
  const { classes } = useStyles();
  const [multiAccountIsReady, setMultiAccountIsReadyIsReady] = useState(false);
  const [matchHistoryList, setMatchHistoryList] = useState<MatchHistory[]>([]);
  const [matchHistoryIsReady, setMatchHistoryIsReady] = useState(false);
  const isBigScreen = useMediaQuery('(min-width: 600px)');
  const isXlScreen = useMediaQuery('(min-width: 960px)');

  const [avatars, setAvatars] = useState<
    | {
        id: string;
        image_url: string;
        kind: string;
        onwed_since: string;
        title: string;
      }[]
    | undefined
  >(undefined);

  useEffect(() => {
    const getAvatars = async () => {
      if (id) {
        const result = await BoyAPI.Player.getAvatars(id);
        setAvatars(result.avatars);
      }
    };
    getAvatars();
  }, [id]);

  const [gemsBought, setGemsBought] = useState<number | undefined>(undefined);
  const [moneySpent, setMoneySpent] = useState<number | undefined>(undefined);

  useEffect(() => {
    (async () => {
      let total = 0;
      let money = 0;
      const result = await firestore()
        .collection('sales')
        .where('player_id', '==', id)
        .where('type', '==', 'gem')
        .get();

      result.forEach((doc) => {
        const data = doc.data() as Sale;
        total += data.quantity;
        money += data.price;
      });

      setGemsBought(total);
      setMoneySpent(money);
    })();
  }, [id]);

  const currentTab =
    window.location.hash.indexOf('show') === window.location.hash.length - 4
      ? 'summary'
      : 'others';
  const dataProvider = useDataProvider();

  const [player, setPlayer] = useState<Player | undefined>();

  useEffect(() => {
    if (!id) {
      return;
    }
    dataProvider
      .getOne('player', { id })
      .then(({ data }: any) => {
        setPlayer(data);
      })
      .catch((error: any) => {
        //console.log(error);
      });
  }, [id, dataProvider]);

  useEffect(() => {
    (async () => {
      const resultFSMA = await firestore()
        .collection('log_identifiers')
        .where('idlist', 'array-contains', id)
        .get();

      let resultMA: LogIdentifier[] = [];

      resultFSMA.forEach((doc) => {
        const data = doc.data() as LogIdentifier;
        if (data.player_id !== id) {
          if (
            resultMA.some(
              (item) => item.type === data.type && item.value === data.value,
            )
          ) {
            return;
          }
          resultMA.push(data);
        }
      });
      setMultiAccounts(resultMA);
      setMultiAccountIsReadyIsReady(true);

      const resultFSMH = await firestore()
        .collection('player')
        .doc(id)
        .collection('match_history')
        .orderBy('start_match_timestamp', 'asc')
        .get();

      const rows: MatchHistory[] = [];

      // Pour le temps de pause
      let lastBeginDate: Date;

      // Recurring opponent
      let lastOpponent: string;
      let lastOpponent2: string;
      resultFSMH.forEach((doc) => {
        const data = doc.data() as MatchHistory;

        // Pour le temps de pause
        if (lastBeginDate != null) {
          data.pauseDuration =
            (data.start_match_timestamp.toDate().getTime() -
              lastBeginDate.getTime()) /
            1000;
        }
        // Pour le temps de pause
        lastBeginDate = data.start_match_timestamp.toDate();

        // Recurring opponent
        if (lastOpponent2 != null) {
          data.recurringOpponent = data.opponent_id === lastOpponent2;
        }
        lastOpponent2 = lastOpponent;
        lastOpponent = data.opponent_id;

        // Same match identifier et player_ids_str pour le grid
        data.sameIdentifiers = false;
        data.player_ids_str = '';

        for (const [key, value] of Object.entries(
          data.player_ids ? data.player_ids : {},
        )) {
          if (!value.value || value.value === '') {
            continue;
          }

          data.player_ids_str += `${key} : ${value.value} `;

          if (key in data.opponent_ids) {
            if (value.value === data.opponent_ids[key].value) {
              data.sameIdentifiers = true;
            }
          }
        }

        data.opponent_ids_str = '';
        for (const [key, value] of Object.entries(data.opponent_ids)) {
          data.opponent_ids_str += `${key} : ${value.value} `;
        }

        data.potentialCheat = '';

        // Triche potentielle
        if (data.sameIdentifiers) {
          data.potentialCheat += 'Same identifiers';
        }
        if (data.recurringOpponent) {
          if (data.potentialCheat.length > 0) {
            data.potentialCheat += ', ';
          }
          data.potentialCheat += 'Recurring opponent';
        }
        if (data.is_boosted === true && matchUtils.getWaitDuration(data) < 5) {
          if (data.potentialCheat.length > 0) {
            data.potentialCheat += ', ';
          }
          data.potentialCheat += 'Boosted found under 5s';
        }
        if (
          data.is_boosted === true &&
          (data.result === 'VICTORY_BY_FORFEIT' ||
            data.opponent_result === 'FORFEIT')
        ) {
          if (data.potentialCheat.length > 0) {
            data.potentialCheat += ', ';
          }
          data.potentialCheat += 'Boosted won by forfeit';
        }
        if (data.pauseDuration < 180) {
          if (data.potentialCheat.length > 0) {
            data.potentialCheat += ', ';
          }
          data.potentialCheat += 'Less 3min pause';
        }

        rows.push(data);
      });
      setMatchHistoryList(
        rows.map((item) => {
          return {
            ...item,
            date: dateUtils.localizedFormat(
              item.start_match_timestamp.toDate(),
              'Ppp',
            ),
          };
        }),
      );
      setMatchHistoryIsReady(true);
    })();
  }, [id]);

  if (!id) {
    return null;
  }
  if (!player) {
    return <Loading />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
        <Show
          {...props}
          actions={<ShowActions id={id} />}
          classes={{
            root: classes.showRoot,
            main: classes.showMain,
            card: classes.showCard,
            noActions: classes.showNoActions,
          }}>
          <TabbedShowLayout
            className={classes.tabbedLayout}
            sx={{
              '& .RaTabbedShowLayout-content': css`
                padding: 8px 16px;
                flex: 1;
              `,
            }}
            tabs={
              <TabbedShowLayoutTabs
                // @ts-ignore
                variant="scrollable"
                // @ts-ignore
                orientation={isXlScreen ? 'vertical' : 'horizontal'}
                className={classes.layoutTab}
                {...props}
              />
            }>
            <Tab label="summary" contentClassName={classes.showCardContent}>
              <TextField label="Pseudo" source="boy_id" />
              <TextField label="is banned" source="is_banned" />
              <FunctionField
                label="is premium"
                render={(record) => {
                  return isPremiumResult(record);
                }}
              />
              <FunctionField
                label="nft holder"
                render={(record) => {
                  if (
                    record.subscription?.kind === 'NFT' &&
                    record.subscription?.active
                  ) {
                    return <NFTHolderInformation avatars={avatars} />;
                  }
                  return 'false';
                }}
              />

              <FunctionField
                label="total gems bought (total price)"
                render={() => {
                  if (gemsBought !== undefined) {
                    return gemsBought + ' gems (' + moneySpent + ')';
                  }
                  return 'Loading...';
                }}
              />
              <RolesField />
              <TextField label="Nationalité" source="nationality" />
              <FormattedSubscriptionDateField />
              <TextField label="Langue" source="language" />
              <TextField label="Note" source="note" />
              <TextField label="Téléphone" source="phone_number" />
              <GoldsFields />
              <TextField
                label="Bets before new system"
                source="old_premium_bets"
              />

              {/* <LastMatchField label="Dernier match" source="last_match" /> */}
              <TextField source="id_v1" />
              {/* <IdentifiersField /> */}
              <StateField />
              {/* <TextField
                source="stats.global.total"
                label="Nombre total de matchs"
              /> */}
              <BadgesField />
            </Tab>
            <Tab
              label="matches"
              path="match_history"
              contentClassName={classes.identifierLogs}>
              {multiAccountIsReady && (
                <MatchHistoryDataGrid
                  matchHistoryList={matchHistoryList.filter(
                    (match) => !match.mode || match.mode === 1,
                  )}
                  isReady={matchHistoryIsReady}
                />
              )}
            </Tab>
            {isBigScreen && [
              <Tab
                label="Transactions"
                path="transactions"
                contentClassName={classes.identifierLogs}>
                <Transactions playerId={id} />
              </Tab>,
              <Tab
                label="gifts"
                path="gift_history"
                contentClassName={classes.identifierLogs}>
                <GiftHistoryDataGrid playerId={id} />
              </Tab>,
              <Tab
                label="bans"
                path="ban_history"
                contentClassName={classes.identifierLogs}>
                <BanHistoryDataGrid />
              </Tab>,
              <Tab label="devices" path="devices">
                <DevicesDataGrid playerId={id} />
              </Tab>,
              <Tab label="Suspicious behaviors" path="behaviors">
                <SuspiciousBehaviours playerId={id} />
              </Tab>,
              <Tab
                label="Block chains"
                path="blockchains"
                contentClassName={classes.identifierLogs}>
                <Cryptos
                  playerId={id}
                  wallets={player.wallets}
                  wallet_ids={player.wallet_ids}
                />
              </Tab>,
            ]}
          </TabbedShowLayout>
        </Show>
      </Grid>
      {currentTab === 'summary' && (
        <>
          <Grid container style={{ marginTop: 16 }}>
            <Grid item xs={12} md={4} className={classes.item}>
              <div style={{ flex: 1 }}>
                <MatchChart playerId={id} />
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.item}>
              <div style={{ flex: 1 }}>
                {matchHistoryIsReady && (
                  <MatchPerGameChart matchHistoryList={matchHistoryList} />
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.item}
              style={{ paddingRight: 0 }}>
              <div style={{ flex: 1 }}>
                {matchHistoryIsReady && (
                  <MatchsPerResultsChart player={player} />
                )}
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} className={classes.item}>
            <MultiAccountCard
              multiAccounts={multiAccounts}
              isReady={multiAccountIsReady}
            />
            {matchHistoryIsReady && (
              <LitigationRatioCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
            {matchHistoryIsReady && (
              <AvgWaitDurationCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.item}>
            {matchHistoryIsReady && (
              <RatioNightHoursMatchsCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
            {matchHistoryIsReady && (
              <AvgWaitDurationNightHourCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} className={classes.item}>
            {matchHistoryIsReady && (
              <BoostedMatchFoundUnder5sCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
            {matchHistoryIsReady && (
              <BoostedMatchsWonByForfeitCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
            {matchHistoryIsReady && (
              <MatchsWithLess3MinPauseCard
                matchHistoryList={matchHistoryList}
                isReady={matchHistoryIsReady}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.item}>
            {matchHistoryIsReady && (
              <RecurringOpponentCard matchHistoryList={matchHistoryList} />
            )}
            {matchHistoryIsReady && (
              <SameIdentifiersCard matchHistoryList={matchHistoryList} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PlayerDetail;
