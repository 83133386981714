import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { MatchHistory } from 'core/types';
import matchUtils from 'utils/match';

type Props = {
  matchHistoryList: MatchHistory[];
  isReady: boolean;
};

const AvgWaitDurationCard = ({ matchHistoryList, isReady }: Props) => {
  const [calculIsReady, setCalulIsReady] = useState(false);
  const [avgWaitDuration, setavgWaitDuration] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      var sum = 0;
      for (var i = 0; i < matchHistoryList.length; i++) {
        sum += matchUtils.getWaitDuration(matchHistoryList[i]);
      }
      var avg = sum / matchHistoryList.length;

      setavgWaitDuration(`${avg.toFixed(0)} s`);
      setCalulIsReady(true);
    })();
  }, []);

  return (
    <Card>
      <CardContent>
        {!isReady && calculIsReady ? (
          <CircularProgress />
        ) : (
          <>
            <Typography color="primary">Attente moyenne</Typography>
            <p>{avgWaitDuration}</p>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AvgWaitDurationCard;
