import { createMuiTheme } from '@mui/material';

import { adaptV4Theme } from '@mui/material/styles';

import { createTheme, Palette, PaletteOptions } from '@mui/material';
import { defaultTheme } from 'react-admin';

declare module '@mui/material/styles' {
  interface Theme {
    palette: Palette;
  }
  interface DeprecatedThemeOptions {
    palette?: PaletteOptions;
  }
}

export const themeMui = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#00C2FF',
      },
      secondary: {
        main: '#FF007C',
        contrastText: '#FFFFFF',
      },
      background: {
        default: '#091C2BEE',
        paper: '#091C2B',
      },
      text: {
        primary: '#FFFFFF',
        secondary: '#8995B4',
      },
      grey: {
        600: '#bfbfbf',
      },
      success: {
        main: '#31d900',
      },
    },
  }),
);

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF007C',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#00C2FF',
    },
    secondary: {
      main: '#FF007C',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#091C2BEE',
      paper: '#091C2B',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8995B4',
    },
    grey: {
      600: '#bfbfbf',
    },
    success: {
      main: '#31d900',
    },
  },
};

export default theme;
