import NotificationList from './NotificationList'
import CreateNotification from './CreateNotification';
import ShowNotification from './ShowNotification';

const Notification = {
  list : NotificationList,
  show : ShowNotification,
  create: CreateNotification
};

export default Notification;
