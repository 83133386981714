import { LinkOffOutlined, RefreshOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  Table,
  Card,
  CardHeader,
  Stack,
  ListItem,
  Typography,
  CardContent,
  TableRow,
  TableCell,
  Button,
  ButtonGroup,
} from '@mui/material';
import { PlayerWalletProvider, PlayerWallets } from 'core/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import BoyAPI from 'services/boy-api';
import { makeStyles } from 'tss-react/mui';

const DEFAULT_COLUMNS = [
  { field: 'wallet_id', headerName: 'Wallet public key' },
  { field: 'provider', headerName: 'Provider' },
  { field: 'actions', headerName: 'Actions' },
];

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    textTransform: 'uppercase',
  },
  check: {
    marginBottom: 0,
    fontSize: 12,
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  clickableCell: {
    cursor: 'pointer',
  },
});
const useRowStyles = makeStyles()({
  root: {
    height: '35px',
    '& > *': {
      borderBottom: 'unset',
    },
  },

  clickableCell: {
    cursor: 'pointer',
  },
  '@global': {
    '.MuiTableCell-root': {
      padding: '2px',
    },
  },
});

function Row({
  playerId,
  publicKey,
  provider,
}: {
  playerId: string;
  publicKey: string;
  provider: PlayerWalletProvider;
}) {
  const classes = useRowStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const unlinkWallet = async (key: string) => {
    try {
      await BoyAPI.Wallets.unlink(playerId, key);
    } catch (err) {
      notify('Unlink error', { type: 'error' });
      return;
    }
    setTimeout(function () {
      notify('Unlinked wallet', { type: 'success' });
      refresh();
    }, 2000);
  };

  const colorProvider = (val: PlayerWalletProvider): string => {
    switch (val) {
      case PlayerWalletProvider.OCTO:
        return '#FF1788';
      default:
        return '#8A9FD8';
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {publicKey}
        </TableCell>
        <TableCell style={{ color: colorProvider(provider) }}>
          {provider}
        </TableCell>

        <TableCell>
          <ButtonGroup variant="text" size="small" sx={{ alignSelf: 'right' }}>
            <Button
              color="secondary"
              size="small"
              onClick={() => unlinkWallet(publicKey)}>
              <LinkOffOutlined />
              Unlink wallet
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Cryptos = ({
  playerId,
  wallets,
  wallet_ids,
}: {
  playerId: string;
  wallets: PlayerWallets;
  wallet_ids: string[];
}) => {
  const { classes } = useStyles();
  const columns = useMemo(() => [...DEFAULT_COLUMNS], []);
  const refresh = useRefresh();
  const notify = useNotify();
  const [displayWallets, setWallets] = useState<PlayerWallets | undefined>(
    wallets,
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  const unlinkAllWallet = async () => {
    setLoading(true);
    try {
      await BoyAPI.Wallets.unlinkAll(playerId);
    } catch (err) {
      notify('Unlink error', { type: 'error' });
      return;
    }
    setTimeout(function () {
      notify('Unlinked wallets', { type: 'success' });
      setLoading(false);
      refresh();
    }, 2000);
  };

  const refreshNfts = async () => {
    setLoading(true);
    try {
      await BoyAPI.Player.refreshNFT(playerId);
    } catch (err) {
      notify('Refresh error', { type: 'error' });
      return;
    }
    setTimeout(function () {
      notify('NFT refreshed', { type: 'success' });
      setLoading(false);
      refresh();
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);

    let newWallets = { ...displayWallets };

    wallet_ids.forEach((wallet_id) => {
      if (!(wallet_id in newWallets)) {
        newWallets[wallet_id] = PlayerWalletProvider.EXTERNAL;
      }
    });

    setWallets(newWallets);
    setLoading(false);
  }, [playerId, displayWallets, wallet_ids]);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <ListItem>
                <Typography
                  color="textSecondary"
                  align="left"
                  className={classes.title}>
                  Solana wallets
                </Typography>
              </ListItem>

              <Stack direction="row">
                <ListItem>
                  <ButtonGroup
                    variant="text"
                    size="small"
                    sx={{ alignSelf: 'right' }}>
                    <Button size="small" onClick={() => refreshNfts()}>
                      <RefreshOutlined />
                      Refresh&nbsp;nfts
                    </Button>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => unlinkAllWallet()}>
                      <LinkOffOutlined />
                      Unlink&nbsp;all&nbsp;wallet
                    </Button>
                  </ButtonGroup>
                </ListItem>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          <Typography align="right" />

          <Table>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  <label for={column.field + '_select'}>
                    {column.headerName}
                  </label>
                </TableCell>
              ))}
            </TableRow>
            {isLoading ? (
              <>
                <CircularProgress />
              </>
            ) : displayWallets ? (
              <>
                {Object.entries(displayWallets).map(([key, provider]) => {
                  return (
                    <Row
                      playerId={playerId}
                      publicKey={key}
                      provider={provider}
                    />
                  );
                })}
              </>
            ) : (
              <TableRow>No wallets</TableRow>
            )}
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default Cryptos;
