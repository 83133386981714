import React, { useMemo } from 'react';
import numeralJs from 'numeral';

import PlayerBadge, { PlayerBadgeType } from 'components/common/PlayerBadge';

type Props = {
  user: any;
  badgeSize?: number;
};

type BadgeInput = {
  user: any;
};

type BadgeList = {
  icon: (args: BadgeInput) => JSX.Element;
  condition: (args: BadgeInput) => boolean;
  isImage?: boolean;
  id: string;
}[];

const formatNumber = (number: number, format?: string) => {
  const typedNumber = numeralJs(number);
  return typedNumber.format(format, Math.floor);
};

const COMMON_BADGES: BadgeList = [
  {
    id: 'matchs',
    icon: ({ user }) => (
      <PlayerBadge
        type="matchs"
        title="Games"
        centerText={formatNumber(user.stats?.global?.total || 0, '0a')}
      />
    ),
    condition: ({ user }) =>
      !!user.stats?.global?.total && user.stats.global.total > 0,
  },
  {
    id: 'verified_profile',
    icon: ({ user }) => {
      if (typeof user.stats?.global?.total !== 'number') {
        return <></>;
      }
      let badgeName = 'copper';
      let badgeType: PlayerBadgeType = 'cuivre';
      if (user.stats?.global?.total >= 5000) {
        badgeName = 'gold';
        badgeType = 'or';
      } else if (user.stats?.global?.total >= 1000) {
        badgeName = 'silver';
        badgeType = 'argent';
      }
      return (
        <PlayerBadge
          type={badgeType}
          title={
            <div style={{ textAlign: 'center' }}>
              verified profile
              <br />
              {badgeName}
            </div>
          }
        />
      );
    },
    condition: ({ user }) =>
      !!user.stats?.global?.total && user.stats.global.total >= 500,
  },
];

const usePlayerBadges = ({ user, badgeSize = 80 }: Props) => {
  const allbadges = useMemo(() => [...COMMON_BADGES], []);

  return allbadges
    .filter((badge) => badge !== null)
    .map((badge) => ({
      id: badge.id,
      component: React.cloneElement(badge.icon({ user }), { size: badgeSize }),
    }));
};

export default usePlayerBadges;
