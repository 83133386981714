function convertTimestamp<T>(
  object: { [key: string]: any },
  fields: string | string[],
): T {
  return (Object.keys(object).reduce((acc, cur) => {
    if (typeof fields === 'string' ? fields === cur : fields.includes(cur)) {
      return {
        ...acc,
        [cur]:
          object[cur] && object[cur].toDate
            ? object[cur].toDate().toString()
            : object[cur],
      };
    }
    return {
      ...acc,
      [cur]: object[cur],
    };
  }, {}) as unknown) as T;
}

function collectionToArray<T>(collection: any, processingFn?: (item: T) => T) {
  const result: T[] = [];
  collection.forEach((doc: any) => {
    const data = doc.data() as T;
    result.push(processingFn ? processingFn(data) : data);
  });
  return result;
}

const firestoreUtils = { convertTimestamp, collectionToArray };

export default firestoreUtils;
