import RAList from 'components/common/RAList';
import {
  Datagrid,
  DateField,
  ListProps,
  TextField,
  NumberField,
  Filter,
  SearchInput,
} from 'react-admin';

const SaleList = (props: ListProps) => {
  return (
    <RAList
      {...props}
      exporter={false}
      filters={
        <Filter>
          <SearchInput
            source="player_boy_id"
            placeholder="Search by pseudo"
            alwaysOn
          />
          <SearchInput source="type" placeholder="Search by type" alwaysOn />
          <SearchInput source="item_id" placeholder="Search by item" alwaysOn />
        </Filter>
      }>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <DateField source="date" />
        <TextField source="player_id" />
        <TextField source="player_boy_id" />
        <TextField source="item_id" />
        <TextField source="type" />
        <NumberField source="quantity" />
        <NumberField source="price" />
        <NumberField source="gem_value" />
      </Datagrid>
    </RAList>
  );
};

export default SaleList;
