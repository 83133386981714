import { ListItem } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { TournamentWinner } from 'core/types';
import React, { useEffect, useRef, useState } from 'react';
import BoyAPI from 'services/boy-api';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

//console.log(getGridStringOperators());
const DEFAULT_PAGE_SIZE = 25;
const WinnersList = ({ tournamentId }: { tournamentId: string }) => {
  const [winners, setWinners] = useState<TournamentWinner[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [rowsCount, setRowsCount] = useState(2);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  const pagesFetched = useRef(0);
  //console.log(filters);

  useEffect(() => {
    (async () => {
      if (currentPage < pagesFetched.current) {
        return;
      }
      const result = await BoyAPI.Tournament.getLeaderBoard({
        tournamentId,
        nbItems: currentPageSize,
        page: currentPage + 1,
        filters,
      });
      pagesFetched.current = currentPage;

      setWinners((winners) => [
        ...winners.slice(0, currentPageSize * currentPage),
        ...result.data.items,
        ...winners.slice(currentPageSize * (currentPage + 1), winners.length),
      ]);
      setRowsCount(result.data.total);
    })();
  }, [currentPage, currentPageSize, filters]);

  useEffect(() => {
    pagesFetched.current = 0;
  }, [currentPageSize, filters]);
  return (
    <div style={{ height: `${currentPageSize * 30}px`, width: '100%' }}>
      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        paginationMode="server"
        rowCount={rowsCount}
        rows={winners.slice(
          currentPage * currentPageSize,
          (currentPage + 1) * currentPageSize,
        )}
        rowsPerPageOptions={[DEFAULT_PAGE_SIZE, 50, 100]}
        onPageSizeChange={(size) => setCurrentPageSize(size)}
        getRowId={(row) => `${row.player_id}-${row.score}`}
        page={currentPage}
        onPageChange={(p) => setCurrentPage(p)}
        onFilterModelChange={(model) => {
          if (!model.items[0].columnField) {
            setFilters({});
            return;
          }
          setFilters({ [model.items[0].columnField]: model.items[0].value });
        }}
        columns={[
          {
            field: 'player_id',
            headerName: 'Player Id',
            renderCell: (params: any) => (
              <a href={`/#/player/${params.row.player_id}/show`}>
                {params.row.player_id}
              </a>
            ),
            filterOperators: getGridStringOperators().filter(
              (operator) => operator.value === 'equals',
            ),
          },
          {
            field: 'nickname',
            headerName: 'BOY ID',
            renderCell: (params: any) => (
              <a href={`/#/player/${params.row.player_id}/show`}>
                {params.row.nickname}
              </a>
            ),
            filterOperators: getGridStringOperators().filter(
              (operator) => operator.value === 'equals',
            ),
          },
          {
            field: 'rank',
            headerName: 'Rank',
            filterable: false,
          },
          {
            field: 'score',
            headerName: 'Score',
            filterable: false,
          },
          {
            field: 'avg_score',
            headerName: 'Average Score',
            filterable: false,
          },
          {
            field: 'unit',
            headerName: 'UNIT',
            filterable: false,
          },
          {
            field: 'unit_image_url',
            headerName: 'token image',
            renderCell: (params: any) => (
              <img src={params.row.unit_image_url} width="20" height="20" />
            ),
            filterOperators: getGridStringOperators().filter(
              (operator) => operator.value === 'equals',
            ),
          },
          {
            field: 'unit_symbol',
            headerName: 'token symbol',
            filterable: false,
          },
          {
            field: 'reward',
            headerName: 'Reward',
            filterable: false,
          },
          {
            field: 'collected',
            headerName: 'Collected',
            filterable: false,
          },
          {
            field: 'collected_at',
            headerName: 'Collected at',
            filterable: false,
          },
        ]}
        rowHeight={30} 
        pageSize={currentPageSize}
      />
    </div>
  );
};

export default WinnersList;
