import NumberCard from 'components/data/NumberCard';

import { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import firestore from 'services/firestore';
import BoyAPI from 'services/boy-api';

const PendingReviewCard = () => {
  const [number, setNumber] = useState<number | false>(false);
  const [loading, setLoading] = useState(false);

  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    (async () => {
      const result = await firestore()
        .collection('review')
        .where('state', '==', 'PENDING')
        .get();
      setNumber(result.size);
    })();
  }, []);

  return (
    <NumberCard
      title={'Pending bonus reviews'}
      number={number}
      href="#/review"
      button={{
        children: <>Start Review</>,
        disabled: !number || loading,
        onClick: async () => {
          setLoading(true);
          const review = await BoyAPI.Review.getOldestReview();

          setLoading(false);
          if (!review?.id) {
            notify('No more review to moderate');
            setNumber(0);
            return;
          }
          redirect(`/review/${review.id}/show`);
        },
      }}
    />
  );
};

export default PendingReviewCard;
