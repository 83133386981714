import { Player } from 'core/types';
import { useEffect, useReducer } from 'react';
import firestore, { firebase } from 'services/firestore';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'fetching':
      return { loading: true, player: undefined };
    case 'fetched':
      return { loading: false, player: action.payload };
    default:
      return state;
  }
};

let cachedPlayer: Player | undefined = undefined;
const useCurrentPlayer = (): {
  loading: boolean;
  player: Player | undefined;
} => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    player: cachedPlayer,
  });

  useEffect(() => {
    if (cachedPlayer) {
      dispatch({ type: 'fetched', payload: cachedPlayer });
      return;
    }
    dispatch({ type: 'fetching' });
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user?.uid) {
        dispatch({ type: 'fetched' });
        return;
      }
      const player = await firestore()
        .collection('player')
        .doc(user?.uid)
        .get();
      const playerData = player.data() as Player;
      cachedPlayer = playerData;
      dispatch({ type: 'fetched', payload: playerData });
    });

    return unsubscribe;
  }, []);

  return state;
};

export default useCurrentPlayer;
